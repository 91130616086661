import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { Button } from "components-ts/controls";

type ResponseError = Pick<{ [K in keyof Response]?: unknown }, "status" | "url">;

const QueryErrorBoundary: React.FC<{
    children: React.ReactNode;
    height?: number;
    width?: number;
}> = ({ children, height = 400, width = 400 }) => {
    const { t } = useTranslation();
    const { reset } = useQueryErrorResetBoundary();
    return (
        <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary, error, ...rest }) => {
                const response = error as ResponseError;

                return (
                    <Grid
                        style={{ height, width: "100%" }}
                        container
                        direction="column"
                        justify-content="center"
                        alignItems="center"
                    >
                        <Typography variant="button">{t("somethingWentWrong").toString()}</Typography>

                        <div>
                            <Typography variant="button">{`${response}`}</Typography>
                            {response.url ? <Typography variant="button">{`${response.url}`}</Typography> : <></>}
                            {response.status ? <Typography variant="button">{`${response.status}`}</Typography> : <></>}
                        </div>

                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                label="reload"
                                onClick={() => {
                                    resetErrorBoundary();
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

export default QueryErrorBoundary;
