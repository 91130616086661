import { GetRequest, PutRequest, get, put, withMeta } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import fetchFile from "utils/rsaa/fetchFileAction";
import types from "./types";

const endpointSG = (subsidiaryGainId) => `api/external-users/subsidiary-gains${subsidiaryGainId ? `/${subsidiaryGainId}` : ""}`;
const endpointTurnovers = (vendorId, id) => `api/external-users/${vendorId}/turnovers${id ? `/${id}` : ""}`;
const endpointVendors = () => `api/external-users/autocomplete`;
const endpointAccountingPeriods = () => `api/external-users/autocomplete/accounting-periods`;

export const getSGs = (params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpointSG(), types.findSubsidiaryGains.all, {
                queryParams: params,
            })
        )
    );
};

export const getSG = (subsidiaryGainId) => async (dispatch) => {
    return dispatch(get(new GetRequest(endpointSG(subsidiaryGainId), types.getSubsidiaryGain.all)));
};

export const getSGConfirmation = (subsidiaryGainId, blobName) => async (dispatch) => {
    await dispatch(
        fetchFile(`${endpointSG(subsidiaryGainId)}/confirmation?blobName=${blobName}`, null, withMeta({ blobName })(types.getServiceConfirmation.all))
    );
};

export const makeDecision = (subsidiaryGainId, isPositive, comment) => async (dispatch) => {
    return dispatch(
        put(
            new PutRequest(endpointSG(subsidiaryGainId) + "/make-decision", types.makeDecision.all, {
                body: {
                    isPositive,
                    comment,
                },
            })
        )
    );
};

export const getSubsidiaryGainFile = (subsidiaryGainId, fileId, fileName) => async (dispatch) => {
    await dispatch(downloadPdfFile(endpointSG(subsidiaryGainId) + `/file-download/${fileId}`, types.downloadFile.all, fileName));

    return Promise.resolve();
};

export const getSubsidiaryGainsUsers = () => async (dispatch) => {
    await dispatch(get(new GetRequest(endpointSG() + "/users", types.getSubsidiaryGainsUsers.all)));
};

export const getSubsidiaryGainsStatuses = () => async (dispatch) => {
    await dispatch(get(new GetRequest(endpointSG() + "/statuses", types.getSubsidiaryGainsStatuses.all)));
};

export const getVendorRecipients = () => async (dispatch) => {
    await dispatch(get(new GetRequest(endpointVendors() + "/vendors-recipients", types.getVendorRecipients.all)));
};

export const getAccountingPeriods = () => async (dispatch) => {
    await dispatch(get(new GetRequest(endpointAccountingPeriods(), types.getAccountingPeriods.all)));
};

export const findTurnovers = (vendorId, params) => (dispatch) => {
    dispatch(get(new GetRequest(endpointTurnovers(vendorId), types.findTurnovers.all, { queryParams: params })));
};

export const changePagination = (pagedCriteria) => ({
    type: types.changePagination,
    payload: {
        ...pagedCriteria,
    },
});

export const setFilters = (filters) => ({
    type: types.setFilters,
    payload: {
        ...filters,
    },
});

export const getTurnover = (vendorId, id) => (dispatch) => {
    dispatch(get(new GetRequest(endpointTurnovers(vendorId, id), types.getTurnover.all)));
};

export const updateVendorSummaryTotal = (vendorId, id, values) => async (dispatch) => {
    await dispatch(
        put(
            new PutRequest(`${endpointTurnovers(vendorId, id)}/update-turnover-vendor-summary-total`, types.updateVendorSummaryTotal.all, {
                body: {
                    ...values,
                },
            })
        )
    );

    dispatch(getTurnover(vendorId, id));
};

export const updateVendorSummary = (vendorId, id, values) => async (dispatch) => {
    await dispatch(
        put(
            new PutRequest(`${endpointTurnovers(vendorId, id)}/update-turnover-detail`, types.updateVendorSummary.all, {
                body: {
                    ...values,
                },
            })
        )
    );

    dispatch(getTurnover(vendorId, id));
};

export const getTurnoverVendorSummaryFile = (vendorId, id, fileId, fileName) => async (dispatch) => {
    await dispatch(downloadPdfFile(`${endpointTurnovers(vendorId, id)}/get-file/${fileId}`, types.downloadTurnoverFile.all, fileName));

    return Promise.resolve();
};

export const getTurnoverFile = (vendorId, id, key, fileId, fileName) => async (dispatch) => {
    await dispatch(downloadPdfFile(`${endpointTurnovers(vendorId, id)}/get-file/${key}/${fileId}`, types.downloadTurnoverFile.all, fileName));

    return Promise.resolve();
};
