import { useGetQuery } from "api/hooks";
import { VendorCertificateDocument } from "Operations-VendorCertificates";
import { DateTimeOffset } from "Shared";

export const useGetCertificatesForAuditPIM = (params: {
    analyticGroupId: string;
    brand: string;
    dateFor: DateTimeOffset;
    vendorIds: Array<string>;
}) =>
    useGetQuery<Array<VendorCertificateDocument>, { analyticGroupId: string; brand: string; dateFor: DateTimeOffset; vendorIds: Array<string> }>({
        app: "operations",
        url: "api/auditors/certificates",
        queryParams: params,
        shouldMakeRequest: (params) => params?.analyticGroupId !== undefined && params.dateFor !== undefined && (params?.vendorIds || []).length > 0,
    });
