import { AccountantDepartment, DepartmentManagersRoles, Role } from "routing-ts/roles";

export const DocumentArchiveRoles: Role[] = [
    "Auditor",
    "OfficeManager",
    "ProjectManager",
    "Archiver",
    "Catman",
    "SalesAssistant",
    ...AccountantDepartment.GetRoles(),
    ...DepartmentManagersRoles,
];

export const DocumentArchiveAccessRoles: Role[] = [...DepartmentManagersRoles, "Auditor"];
