import buttons from "assets/jss/custom/buttons";
import { makeStyles } from "tss-react/mui";
import React, { RefAttributes, forwardRef } from "react";
import { CircularProgress, Icon, IconButton as MUIButton, Tooltip } from "@mui/material";

const useStyles = makeStyles<{ useWrapper: boolean; showInline: boolean }>()((theme, { useWrapper, showInline }) => ({
    ...buttons(theme),
    wrapper: {
        margin: useWrapper ? theme.spacing(1) : 0,
        position: "relative",
        display: showInline ? "inline-flex" : "flex",
    },
    grid: {
        marginBottom: theme.spacing(1),
    },
    item: {
        paddingLeft: theme.spacing(1),
    },
    buttonProgress: {
        color: theme.palette.success.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

type IconButtonProps = {
    onClick: () => void;
    icon:
        | "delete"
        | "clear"
        | "chevronLeft"
        | "chevronRight"
        | "drag_handle"
        | "expand_less"
        | "expand_more"
        | "cloud_download"
        | "menu"
        | "close"
        | "add"
        | "search"
        | "developer_board_icon"
        | "developer_board_off"
        | "download"
        | "check"
        | "copy"
        | "export"
        | "exportPart"
        | "history";
    useWrapper?: boolean;
    color?: string;
    iconClasses?: string;
    loading?: boolean;
    showInline?: boolean;
    size?: "small" | "medium" | "large";
    disabled?: boolean;
    additionalText?: string;
    edge?: "start" | "end";
    tooltip?: string;
} & RefAttributes<HTMLDivElement>;

const IconButton: React.FC<IconButtonProps> = forwardRef(
    (
        {
            onClick,
            useWrapper = true,
            color,
            icon,
            iconClasses,
            loading,
            showInline = false,
            disabled,
            size = "medium",
            additionalText,
            edge,
            tooltip,
        },
        ref
    ) => {
        const { classes } = useStyles({ useWrapper, showInline });
        const button = (
            <MUIButton
                size={size}
                style={{ marginLeft: "auto", color, cursor: icon === "drag_handle" ? "move" : undefined }}
                disabled={disabled || loading}
                onClick={(_) => onClick()}
                edge={edge}
            >
                <Icon className={iconClasses}>
                    {icon === "copy" ? "content_copy" : icon === "export" ? "get_app" : icon === "exportPart" ? "select_all" : icon}
                </Icon>
                {additionalText}
            </MUIButton>
        );

        return (
            <div
                className={classes.wrapper}
                ref={ref}
            >
                {tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button}

                {loading && (
                    <CircularProgress
                        size={28}
                        className={classes.buttonProgress}
                    />
                )}
            </div>
        );
    }
);

export default React.memo(IconButton);
