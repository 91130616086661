import { commerce } from "store/actionTypePrefixes";
import { AsyncTypes } from "utils/extensions";

const types = {
    getMembership: new AsyncTypes("GET_MEMBERSHIP", commerce),
    getMembershipPoints: new AsyncTypes("GET_MEMBERSHIP_POINTS", commerce),
    editMembershipPoints: new AsyncTypes("EDIT_MEMBERSHIP_POINTS", commerce),
    getCustomerRewards: new AsyncTypes("GET_REWARDS", commerce),
};

export default types;
