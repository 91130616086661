import { makeStyles } from "tss-react/mui";
import React from "react";
import { Grid } from "@mui/material";

const useStyles = makeStyles<{ widthMultiplier: number; addMargin: boolean }>()((theme, { widthMultiplier, addMargin }) => ({
    item: {
        width: widthMultiplier * 280,
        marginBottom: addMargin ? theme.spacing(1) : undefined,
        marginLeft: addMargin ? theme.spacing(1) : undefined,
        marginRight: addMargin ? theme.spacing(2) : undefined,
    },
    fullWidth: { width: "100%" },
    autoWidth: { width: "auto" },
}));

export type ControlRootProps = {
    children: React.ReactNode;
    useGridItem?: boolean;
    width?: "fullWidth" | "standard" | "double" | "triple" | "small" | "tiny" | "auto" | number;
    addMargin?: boolean;
};

const ControlRoot: React.FC<ControlRootProps> = ({ children, useGridItem = true, width = "standard", addMargin = false }) => {
    const isFullWidth = width === "fullWidth";
    const widthMultiplier =
        typeof width === "number"
            ? (width ?? 0) / 280
            : width === "standard"
            ? 1
            : width === "double"
            ? 2
            : width === "triple"
            ? 3
            : width === "small"
            ? 0.5
            : width === "tiny"
            ? 0.25
            : 0;
    const isAutoWidth = width === "auto";
    const { classes } = useStyles({ widthMultiplier, addMargin });

    if (useGridItem) {
        return (
            <Grid
                item
                xs={isFullWidth ? 12 : isAutoWidth ? "auto" : undefined}
            >
                <div className={isFullWidth ? classes.fullWidth : isAutoWidth ? classes.autoWidth : classes.item}>{children}</div>
            </Grid>
        );
    }

    return <div className={isFullWidth ? classes.fullWidth : isAutoWidth ? classes.autoWidth : classes.item}>{children}</div>;
};

export default ControlRoot;
