import { ProductSection } from "Content-ProductSection";
import { useGetQuery } from "api/hooks";

const queryKey = "api/v1/product-sections";

export const useGetProductSections = () => {
    return useGetQuery<Array<ProductSection>, {}>({
        app: "content",
        url: queryKey,
    });
};
