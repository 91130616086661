import { useDebouncedCallback } from "use-debounce";
import { useState } from "react";
import { ArrayPath, FieldValues, useFieldArray } from "react-hook-form";
import { Grid } from "@mui/material";
import { FormTableControlProps } from "./types";
import { common } from "translations";
import { useColumns } from "utils-ts/hooks";
import { NestedKeyOf } from "Shared";
import { Button } from "components-ts/controls/buttons";
import FormArrayHelperText from "components-ts/controls/inputs/FormArrayHelperText";
import { SuspenseContainer } from "components-ts/suspense";
import { Table } from "components-ts/tables";
import { ActionColumn, Column, ValueColumn, ValueTypeKeys } from "components-ts/tables/table";
import { Spacing, View } from "components-ts/view";
import { usePrefixContext } from "../contexts";
import { FormRow } from "../layout";

const FormTable = <TFieldValues extends FieldValues = FieldValues, TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    columnFactory,
    canAddItem = true,
    canRemoveItem = true,
    onAddClick,
    hidePagination = false,
}: FormTableControlProps<TFieldValues, TName>): JSX.Element => {
    const [filter, setFilter] = useState<Partial<TFieldValues>>({});
    const [pagination, setPagination] = useState<{
        pageIndex: number;
        pageSize: number;
    }>({
        pageIndex: 1,
        pageSize: localStorage.getItem("tablePagination") !== null ? JSON.parse(localStorage.getItem("tablePagination") as string) : 10,
    });

    const prefix = usePrefixContext();
    const key = prefix ? `${prefix}.${name}` : name;
    const { fields, append, remove } = useFieldArray<TFieldValues, TName>({
        name: key as TName,
    });
    const onFilterChange = useDebouncedCallback((property: NestedKeyOf<TFieldValues>, newValue: unknown) => {
        setFilter({ ...filter, [property.toString()]: newValue });
    }, 200);
    const tableColumns = useColumns(() =>
        columnFactory().map(
            (c) => ({ ...c, onFilterChange: (c as ValueColumn<TFieldValues>)?.filtrable ? undefined : onFilterChange }) as Column<TFieldValues>
        )
    );
    if (canRemoveItem && !tableColumns.some((c) => (c as ActionColumn<TFieldValues>)?.actionType === "delete")) {
        tableColumns.push({
            actionType: "delete",
            onClick: (_item, index) => {
                remove((pagination.pageIndex - 1) * pagination.pageSize + index);
            },
        });
    }

    const filterColumns = tableColumns.filter((c) => (c as ValueColumn<TFieldValues>)?.filtrable).map((c) => c as ValueColumn<TFieldValues>);

    const items = fields
        .map((item) => {
            return item as TFieldValues;
        })
        .filter((item) => {
            if (filterColumns.length > 0) {
                let included = true;
                filterColumns.forEach((col) => {
                    if (filter[col.property]) {
                        if ((col.as as ValueTypeKeys) || col.as === undefined || col.filterAs) {
                            switch (col.filterAs || col.as) {
                                case "date":
                                case "decimal":
                                case "integer":
                                case "select":
                                case "boolean": {
                                    included = included && item[col.property] === filter[col.property];
                                    break;
                                }
                                case undefined:
                                case "string": {
                                    if (item[col.property] === null || item[col.property] === undefined) {
                                        included = false;
                                    } else {
                                        const filterValue = (filter[col.property] as string).trim().toLocaleLowerCase();
                                        const itemValue = item[col.property].toString().trim().toLocaleLowerCase();
                                        included = included && (itemValue === filterValue || itemValue.includes(filterValue));
                                    }

                                    break;
                                }
                                default: {
                                    //TODO custom filter if needed
                                    break;
                                }
                            }
                        }
                    }
                });

                return included;
            } else {
                return true;
            }
        });

    const table = (
        <Spacing spacing={2}>
            <FormArrayHelperText name={key} />
            <Table
                columns={tableColumns}
                items={
                    !hidePagination ? items.slice((pagination.pageIndex - 1) * pagination.pageSize, pagination.pageIndex * pagination.pageSize) : items
                }
                pagination={
                    !hidePagination
                        ? {
                              ...pagination,
                              totalCount: items.length,
                              onPageChange: (pageIndex) =>
                                  setPagination({
                                      pageIndex,
                                      pageSize: pagination.pageSize,
                                  }),
                              onPageSizeChange: (pageSize) => setPagination({ pageIndex: 1, pageSize }),
                          }
                        : undefined
                }
                handlers={{
                    onFilterChange: filterColumns.length > 0 ? onFilterChange : undefined,
                    onFilterClear: filterColumns.length > 0 ? () => setFilter({}) : undefined,
                }}
            />
            {canAddItem && (
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    <Grid
                        item
                        style={{
                            marginLeft: "auto",
                            marginRight: "10px",
                            marginBottom: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Button
                            onClick={() => {
                                if (onAddClick) {
                                    onAddClick();
                                } else {
                                    append(defaultValue);
                                }
                            }}
                            label={common.add}
                        />
                    </Grid>
                </Grid>
            )}
        </Spacing>
    );

    if (label) {
        return (
            <Grid
                item
                xs={12}
                style={{ width: "100%" }}
            >
                <View headerText={label}>
                    <SuspenseContainer>{table}</SuspenseContainer>
                </View>
            </Grid>
        );
    } else {
        return <FormRow>{table}</FormRow>;
    }
};

export default FormTable;
