import { useGetCacheQuery } from "api/hooks";
import { BrandAutocompleteModel } from "Content-Brand";

const queryKey = "api/autocomplete/";

export const useFindBrands = () => {
    return useGetCacheQuery<Array<BrandAutocompleteModel>>({
        app: "content",
        url: queryKey + "brands",
    });
};
