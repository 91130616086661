import { RSAA } from "redux-api-middleware";
import { DeleteRequest, GetRequest, PostRequest, PutRequest, get, post, put, remove } from "utils/extensions";
import { downloadPdfFile } from "utils/rsaa/downloadFileAction";
import types from "./types";

const endpoint = (vendorId, refundationId) => `api/vendors/${vendorId}/refundations${refundationId ? `/${refundationId}` : ""}`;

export const getRefundations = (vendorId, params) => async (dispatch) => {
    return dispatch(
        get(
            new GetRequest(endpoint(vendorId), types.getRefundations.all, {
                queryParams: params,
            })
        )
    );
};

export const initForm = (vendorId, refundationId) => async (dispatch) => {
    await dispatch({ type: types.formInit });

    if (refundationId) {
        return dispatch(get(new GetRequest(endpoint(vendorId, refundationId), types.getRefundation.all)));
    }
};

export const createOrUpdate = (vendorId, refundationId, values, userInfo) => async (dispatch) => {
    if (!refundationId) {
        return await dispatch(
            post(
                new PostRequest(endpoint(vendorId), types.createOrUpdate.all, {
                    body: { refundation: values, userInfo },
                })
            )
        );
    } else {
        return await dispatch(
            put(
                new PutRequest(endpoint(vendorId, refundationId), types.createOrUpdate.all, {
                    body: values,
                })
            )
        );
    }
};

export const removeRefundation = (vendorId, refundationId) => async (dispatch) => {
    return dispatch(remove(new DeleteRequest(endpoint(vendorId, refundationId), types.deleteRefundation.all)));
};

export const findRefundationApproval = (vendorId) => async (dispatch) => {
    return dispatch(get(new GetRequest(`${endpoint(vendorId)}/for-approval`, types.findRefundationForApproval.all)));
};

export const makeDecision = (vendorId, refundationId, isPositive) => async (dispatch) => {
    return dispatch(
        put(
            new PutRequest(endpoint(vendorId, refundationId) + "/make-decision", types.makeDecision.all, {
                body: {
                    isPositive,
                },
            })
        )
    );
};

export const getRefundationFromFile = (vendorId, file) => async (dispatch) => {
    let data = new FormData();
    data.append("file0", file);

    return dispatch({
        [RSAA]: {
            endpoint: `${endpoint(vendorId)}/import`,
            method: "POST",
            types: types.importRefundation.all,
            body: data,
        },
    });
};

export const getRefundationFile = (vendorId, refundationId, file) => async (dispatch) => {
    dispatch(downloadPdfFile(endpoint(vendorId, refundationId) + `/file-download/${file.fileId}`, types.downloadFile.all, file.fileName));
};

export const validateRefundation = (vendorId, body) => async (dispatch) => {
    return await dispatch(
        post(
            new PostRequest(endpoint(vendorId) + "/validate", types.validateRefundation.all, {
                body,
            })
        )
    );
};

export const getRefundationSettlementFile = (vendorId, refundationId, file) => async (dispatch) => {
    dispatch(downloadPdfFile(endpoint(vendorId, refundationId) + "/settlement", types.downloadSettlementFile.all, file.fileName));
};

export const deleteRefundationSettlementFile = (vendorId, refundationId, fileId) => async (dispatch) => {
    return dispatch(
        remove(new DeleteRequest(endpoint(vendorId, refundationId) + `/settlement/${fileId}`, types.deleteRefundationSettlementFile.all))
    );
};

export const confirmZeroCorrection = (vendorId, refundationId) => async (dispatch) => {
    return await dispatch(post(new PostRequest(endpoint(vendorId, refundationId) + "/confirm-zero-correction", types.confirmZeroCorrection.all, {})));
};
