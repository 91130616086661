import { FormField } from "components/Form";
import Layout from "components/Grid/Layout";
import { FormSection } from "redux-form";
import { actions } from "store/cache/products/actions";
import { withFormName } from "utils/hoc";
import { useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { isInteger } from "utils/numberExtensions";
import Validator, { isEmpty, notEmpty } from "utils/validators/basic";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import _ from "lodash";
import { get } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";
import CartContextPredicate from "./CartContextPredicate";
import PromotionProductsFields from "./PromotionProductsFields";

const validatePurchasedOrdersAgoFrom = (value, form, _, path) => {
    const valueTo = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("From", "To"));

    return new Validator(value)
        .must(
            (value) => Number(value) <= Number(valueTo),
            tValidation(validation.lessOrEqualsThan, {
                number: valueTo,
            })
        )
        .when(notEmpty(valueTo))
        .must(
            (value) => Number(value) >= 0,
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .must(isInteger, tValidation(validation.beInteger))
        .allWhen(notEmpty(value))
        .validate();
};

const validatePurchasedOrdersAgoTo = (value, form, _, path) => {
    const valueFrom = get(form, path.substring(0, path.lastIndexOf(".")) + path.substring(path.lastIndexOf(".")).replace("To", "From"));

    return new Validator(value)
        .must(
            (value) => Number(value) >= Number(valueFrom),
            tValidation(validation.greaterOrEqualsThan, {
                number: valueFrom,
            })
        )
        .when(notEmpty(valueFrom))
        .must(
            (value) => Number(value) >= 0,
            tValidation(validation.greaterOrEqualsThan, {
                number: 0,
            })
        )
        .must(isInteger, tValidation(validation.beInteger))
        .allWhen(notEmpty(value))
        .validate();
};

const Products = ({ sectionName, headerText, validationText, isCollapsed, readOnly, tc, common, includeRequired }) => {
    return (
        <Grid
            item
            xs={12}
            md={6}
        >
            <Layout
                isEnlarged
                headerText={headerText}
                useCollapse
                isCollapsed={isCollapsed}
                validationText={validationText}
            >
                <FormSection name={sectionName}>
                    <PromotionProductsFields
                        required={includeRequired}
                        name="includeProducts"
                        title={tc(common.includeProducts)}
                        readOnly={readOnly}
                    />

                    <PromotionProductsFields
                        name="excludeProducts"
                        title={tc(common.excludeProducts)}
                        readOnly={readOnly}
                    />
                </FormSection>
            </Layout>
        </Grid>
    );
};

const CartPredicate = ({
    name = "",
    form,
    sectionPrefix,
    required,
    showOnlyCartContextPredicate = false,
    showCartProducts = false,
    readOnly = false,
    requiredSegment,
    isVoucherEmbedded = false,
}) => {
    const purchasedProducts = useFormValueSelector(form, [sectionPrefix, name, "purchasedProducts"].filter(Boolean).join("."), {});
    const cartProducts = useFormValueSelector(form, [sectionPrefix, name, "cartProducts"].filter(Boolean).join("."), {});
    const [init, setInit] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState({
        purchased: true,
        unpurchased: true,
        cartPurchased: true,
        cartUnpurchased: true,
    });
    const { t: tc, common } = useCommonTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!init) {
            if (!_.isEmpty(purchasedProducts) || !_.isEmpty(cartProducts)) {
                const all = [
                    ...(purchasedProducts?.purchased?.includeProducts?.products || []),
                    ...(purchasedProducts?.purchased?.excludeProducts?.products || []),
                    ...(purchasedProducts?.unpurchased?.includeProducts?.products || []),
                    ...(purchasedProducts?.unpurchased?.excludeProducts?.products || []),
                    ...(cartProducts?.purchased?.includeProducts?.products || []),
                    ...(cartProducts?.purchased?.excludeProducts?.products || []),
                    ...(cartProducts?.unpurchased?.includeProducts?.products || []),
                    ...(cartProducts?.unpurchased?.excludeProducts?.products || []),
                ];
                dispatch(actions.getProducts(all));
                setInit(true);

                const anyProduct = (obj) => {
                    return !isEmpty(obj?.includeProducts) || !isEmpty(obj?.excludeProducts);
                };

                setIsCollapsed({
                    purchased: !anyProduct(purchasedProducts?.purchased),
                    unpurchased: !anyProduct(purchasedProducts?.unpurchased),
                    cartPurchased: !anyProduct(cartProducts?.purchased),
                    cartUnpurchased: !anyProduct(cartProducts?.unpurchased),
                });
            }
        }
    }, [dispatch, init, purchasedProducts, cartProducts]);

    const isAny = (obj = {}) => {
        return (
            !_.isEmpty(obj?.brands) ||
            !_.isEmpty(obj?.categories) ||
            !_.isEmpty(obj?.merchants) ||
            !_.isEmpty(obj?.products) ||
            !_.isEmpty(obj?.suppliers) ||
            !_.isEmpty(obj?.tags)
        );
    };

    const purchaseProductsNotEmpty =
        !_.isEmpty(purchasedProducts) && (notEmpty(purchasedProducts.purchasedOrdersAgoFrom) || notEmpty(purchasedProducts.purchasedOrdersAgoTo));

    const purchasedAtLeastOneNotEmpty =
        isAny(purchasedProducts?.unpurchased?.includeProducts) || isAny(purchasedProducts?.purchased?.includeProducts);

    const cartProductsNotEmpty =
        isAny(cartProducts?.unpurchased?.excludeProducts) ||
        isAny(cartProducts?.unpurchased?.includeProducts) ||
        isAny(cartProducts?.purchased?.excludeProducts) ||
        isAny(cartProducts?.purchased?.includeProducts);

    const cartAtLeastOneNotEmpty = isAny(cartProducts?.unpurchased?.includeProducts) || isAny(cartProducts?.purchased?.includeProducts);

    return (
        <>
            <CartContextPredicate
                name={name}
                required={required && !purchaseProductsNotEmpty}
                requiredSegment={requiredSegment}
                readOnly={readOnly}
                isVoucherEmbedded={isVoucherEmbedded}
            />

            {showCartProducts && (
                <FormSection name={name}>
                    <FormSection name="cartProducts">
                        <Layout headerText={tc(common.cartProducts)}>
                            <Grid
                                container
                                spacing={1}
                            >
                                <Products
                                    headerText={tc(common.purchasedProducts)}
                                    sectionName="purchased"
                                    isCollapsed={isCollapsed.cartPurchased}
                                    readOnly={readOnly}
                                    validationText={cartProductsNotEmpty && !cartAtLeastOneNotEmpty ? tValidation(validation.atLeastOneField) : null}
                                    includeRequired={
                                        (cartProductsNotEmpty && !cartAtLeastOneNotEmpty) || isAny(cartProducts?.purchased?.excludeProducts)
                                    }
                                    common={common}
                                    tc={tc}
                                />

                                <Products
                                    headerText={tc(common.unpurchasedProducts)}
                                    sectionName="unpurchased"
                                    isCollapsed={isCollapsed.cartUnpurchased}
                                    readOnly={readOnly}
                                    validationText={cartProductsNotEmpty && !cartAtLeastOneNotEmpty ? tValidation(validation.atLeastOneField) : null}
                                    includeRequired={
                                        (cartProductsNotEmpty && !cartAtLeastOneNotEmpty) || isAny(cartProducts?.unpurchased?.excludeProducts)
                                    }
                                    common={common}
                                    tc={tc}
                                />
                            </Grid>
                        </Layout>
                    </FormSection>
                </FormSection>
            )}

            {!showOnlyCartContextPredicate && (
                <FormSection name={name}>
                    <FormSection name="purchasedProducts">
                        <Layout headerText={tc(common.purchasedProductsHeader)}>
                            <Grid
                                container
                                direction="row"
                            >
                                <FormField
                                    name={"purchasedOrdersAgoFrom"}
                                    label={tc(common.purchasedOrdersAgoFrom)}
                                    type={"number"}
                                    validate={validatePurchasedOrdersAgoFrom}
                                    readOnly={readOnly}
                                />
                                <FormField
                                    name={"purchasedOrdersAgoTo"}
                                    label={tc(common.purchasedOrdersAgoTo)}
                                    type={"number"}
                                    validate={validatePurchasedOrdersAgoTo}
                                    readOnly={readOnly}
                                />
                            </Grid>
                            <Grid
                                container
                                spacing={1}
                            >
                                <Products
                                    headerText={tc(common.purchasedProducts)}
                                    sectionName="purchased"
                                    isCollapsed={isCollapsed.purchased}
                                    readOnly={readOnly}
                                    validationText={
                                        purchaseProductsNotEmpty && !purchasedAtLeastOneNotEmpty ? tValidation(validation.atLeastOneField) : null
                                    }
                                    includeRequired={
                                        (purchaseProductsNotEmpty && !purchasedAtLeastOneNotEmpty) ||
                                        isAny(purchasedProducts?.purchased?.excludeProducts)
                                    }
                                    common={common}
                                    tc={tc}
                                />

                                <Products
                                    headerText={tc(common.unpurchasedProducts)}
                                    sectionName="unpurchased"
                                    isCollapsed={isCollapsed.unpurchased}
                                    readOnly={readOnly}
                                    validationText={
                                        purchaseProductsNotEmpty && !purchasedAtLeastOneNotEmpty ? tValidation(validation.atLeastOneField) : null
                                    }
                                    includeRequired={
                                        (purchaseProductsNotEmpty && !purchasedAtLeastOneNotEmpty) ||
                                        isAny(purchasedProducts?.unpurchased?.excludeProducts)
                                    }
                                    common={common}
                                    tc={tc}
                                />
                            </Grid>
                        </Layout>
                    </FormSection>
                </FormSection>
            )}
        </>
    );
};

export default withFormName(CartPredicate);
