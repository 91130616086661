import autocomplete from "./autocomplete";
import * as campaigns from "./campaigns";
import * as cms from "./cms";
import { default as offers } from "./offers";
import * as orders from "./orders";
import * as contact from "./support";
import * as users from "./users";

const commerce = {
    autocomplete,
    campaigns,
    cms,
    offers,
    orders,
    contact,
    users,
};

export default commerce;
