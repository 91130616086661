import * as autocomplete from "./autocomplete";
import * as categories from "./categories";
import * as categoryTrees from "./categoryTrees";
import * as productAttributes from "./productAttributes";
import * as productInits from "./productInits";
import * as productSections from "./productSections";
import * as products from "./products";

const content = {
    autocomplete,
    categories,
    categoryTrees,
    products,
    productAttributes,
    productInits,
    productSections,
};

export default content;
