import { useMemo, useState } from "react";
import { forwardRef } from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { TreeItem, TreePickerProps } from "control-types";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { TextWithLabel } from "components-ts/text";
import FormHelperText from "./FormHelperText";

const idJoinCharacter = ";";
const idRegex = new RegExp(/;/, "g");

const TreePicker: React.FC<TreePickerProps> = forwardRef(({ label, items, onChange, value, error, readOnly, required, warning }, ref) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState("");
    const [level, setLevel] = useState<number>((value || []).length);
    const [parents, setParents] = useState<string[]>((value || []).length > 1 ? value.slice(0, -1) : value);
    const [isLast, setIsLast] = useState<boolean>((value || []).length > 0);
    const [open, isOpen] = useState<boolean>(false);

    const optionsMap: Record<string, TreeItem & { isLast: boolean; parentPath: string }> = useMemo(() => {
        function addChildrens(childrens: TreeItem[], parentId: string[], parentPath: string) {
            childrens.forEach((c) => {
                arrayMap[[...parentId, c.value ?? c.name].join(idJoinCharacter)] = {
                    value: c.value,
                    level: c.level,
                    name: c.name,
                    isLast: (c.childrens || []).length === 0,
                    parentPath: parentPath,
                };

                if (c.childrens) {
                    addChildrens(c.childrens, [...parentId, c.value ?? c.name], `${parentPath} -> ${c.name}`);
                }
            });
        }

        const arrayMap: Record<string, TreeItem & { isLast: boolean; parentPath: string }> = {};
        items.forEach((i) => {
            const treeItemId = i.value ?? i.name;
            arrayMap[treeItemId] = {
                value: i.value,
                level: i.level,
                name: i.name,
                isLast: (i.childrens || []).length === 0,
                parentPath: "",
            };

            if (i.childrens) {
                addChildrens(i.childrens, [treeItemId], i.name);
            }
        });

        if (!readOnly) {
            setIsLast(arrayMap[value.join(idJoinCharacter)]?.isLast ?? false);
        }

        return arrayMap;
    }, [items.length]);

    const options = useMemo(
        () =>
            readOnly
                ? []
                : level > 0
                ? [
                      ["-1"],
                      ...Object.keys(optionsMap)
                          .filter((o) =>
                              isLast
                                  ? o.startsWith(value.slice(0, -1).join(idJoinCharacter)) && (o.match(idRegex) || []).length === level - 1
                                  : o.startsWith(value.join(idJoinCharacter)) && (o.match(idRegex) || []).length === level
                          )
                          .map((o) => o.split(idJoinCharacter)),
                  ]
                : Object.keys(optionsMap)
                      .filter((o) => !o.includes(idJoinCharacter))
                      .map((o) => [o]),
        [optionsMap, level]
    );
    if (readOnly) {
        return (
            <TextWithLabel
                value={Array.isArray(value) ? value[value.length - 1] : value}
                label={label}
                items={Object.values(optionsMap).map((option) => ({
                    value: option.value ?? option.name,
                    name: `${option.parentPath} -> ${option.name}`,
                    disabled: false,
                }))}
            />
        );
    }

    const extendedLabel = value.length > 1 ? `${label}: ${optionsMap[value.join(idJoinCharacter)]?.parentPath ?? ""}` : label;

    return (
        <Autocomplete<string[]>
            options={[...options]}
            fullWidth
            noOptionsText={t(common.noOptions)}
            clearText={t(common.clearText)}
            disableCloseOnSelect
            value={items && items.length >= 0 ? value : null}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue, reason) => {
                if (reason == "clear") {
                    setParents([]);
                    setLevel(0);
                    onChange([]);
                    setInputValue("");
                    setIsLast(false);
                } else {
                    setInputValue(newInputValue);
                }
            }}
            onChange={(_event, value) => {
                if (Array.isArray(value) && value.length > 0) {
                    if (value[0] === "-1") {
                        const itemsToBackCount = isLast ? -1 : parents.length > 1 ? parents.length - 1 : -1;
                        const updatedParents = parents.slice(0, itemsToBackCount);
                        setLevel(parents.length);
                        onChange(parents);
                        setParents(updatedParents);
                        setIsLast(false);
                    } else {
                        if (isLast) {
                            setParents(value.slice(0, -1));
                            setLevel(value.length);
                            onChange(value);
                        } else {
                            setParents(value.slice(0, -1));
                            setLevel(value.length);
                            onChange(value);
                        }

                        const val = optionsMap[value.join(idJoinCharacter)];
                        if (val.isLast) {
                            setIsLast(true);
                            isOpen(false);
                        } else {
                            setIsLast(false);
                        }
                    }
                } else {
                    setLevel(parents.length);
                    onChange(parents);
                    setIsLast(false);
                }
            }}
            open={open}
            onOpen={() => isOpen(true)}
            onClose={() => {
                setInputValue("");
                isOpen(false);
            }}
            filterOptions={(options, state) => {
                return options.filter((opt) => {
                    const isBackOptions = opt.length === 1 && opt[0] === "-1";
                    if (!opt.join(idJoinCharacter).startsWith((isLast ? parents : value).join(idJoinCharacter)) && !isBackOptions) {
                        return false;
                    }

                    if (
                        (state.inputValue ?? "").length === 0 ||
                        state.inputValue === state.getOptionLabel(parents) ||
                        isBackOptions ||
                        (isLast && state.inputValue === state.getOptionLabel(value))
                    ) {
                        return true;
                    }

                    const name = state.getOptionLabel(opt).toLocaleLowerCase();

                    return name?.includes(state.inputValue.toLocaleLowerCase()) ?? false;
                });
            }}
            renderInput={(params) => {
                return (
                    <>
                        <TextField
                            label={extendedLabel}
                            variant="standard"
                            fullWidth
                            placeholder={label}
                            error={error?.hasError}
                            inputProps={params.inputProps}
                            InputProps={params.InputProps}
                            inputRef={ref}
                            required={required}
                        />
                        <FormHelperText
                            error={error}
                            warning={warning}
                        />
                    </>
                );
            }}
            getOptionLabel={(option) => {
                if (option[0] === "-1") {
                    return `← Cofnij do ${optionsMap[parents.join(idJoinCharacter)]?.name ?? "początku"}`;
                }

                if (inputValue == "" && option.length < 1) {
                    return "";
                }

                const item = optionsMap[option.join(idJoinCharacter)];

                return item !== undefined ? item.name : option[option.length - 1] ?? "";
            }}
            isOptionEqualToValue={(option, value) => {
                return option.length === value.length && option[option.length - 1] === value[value.length - 1];
            }}
        />
    );
});

export default TreePicker;
