import { ConnectedComponent } from "react-redux";
import { RouteProps } from "react-router-dom";
import { routing } from "translations";
import { AuthenticationGuard } from "components-ts/auth/AuthenticationGuard";
import { PathParameters } from "./PathParameters";
import { Role } from "./roles";

const routingTranslations = Object.keys(routing);

export class PrivateRoute<T extends PathParameters<T> = {}> {
    constructor(
        path: string,
        roles: Role[],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component?: React.ComponentType<{}> | ConnectedComponent<any, any>,
        sidebarName?: string,
        icon?: string,
        subpaths?: PrivateRoute[]
    ) {
        this.path = path;
        this.roles = roles;

        if (subpaths) {
            this.subpaths = subpaths;
        } else {
            this.subpaths = [];
        }

        if (component) {
            this.component = component;
        }

        if (sidebarName) {
            if (routingTranslations.includes(sidebarName)) {
                this.sidebarName = sidebarName;
            } else {
                this.sidebarName = `Empty routing translation for ${sidebarName}`;
            }
        }

        if (icon) {
            this.icon = icon;
        }
    }

    public component?: React.ComponentType<{}> | null;
    public path: string;
    public roles: Role[];
    public sidebarName?: string;
    public icon?: string;
    public subpaths: PrivateRoute[];

    public GetReactRoutes = (): RouteProps[] | undefined => {
        if (this.component === null || this.component === undefined) {
            return undefined;
        }

        const element = AuthenticationGuard({ component: this.component, roles: this.roles });
        let routes: RouteProps[] = [
            {
                path: this.path,
                element: element,
            },
        ];

        if (this.subpaths.length > 0) {
            const childRoutes = this.subpaths.flatMap((sp) => {
                const routes = sp.GetReactRoutes();
                if (routes) {
                    return routes;
                } else {
                    return [];
                }
            });

            if (childRoutes.length > 0) {
                routes = routes.concat(childRoutes);
            }
        }

        return routes;
    };

    public GetLayoutItem = (): LayoutItem => {
        return {
            path: this.path,
            sidebarName: this.sidebarName,
            icon: this.icon,
            roles: this.roles,
            items: this.subpaths.length > 0 ? this.subpaths.map((sp) => sp.GetLayoutItem()) : undefined,
        };
    };
}

export class LayoutItem {
    constructor(
        public path: string,
        public roles: Role[],
        public items?: LayoutItem[],
        public sidebarName?: string,
        public icon?: string
    ) {}
}
