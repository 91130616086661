import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import tableActionTypes from "components/Table/SimpleTable/TableActions/tableActionTypes";
import TableWithToggleFilter from "components/Table/TableWithToggleFilter/TableWithToggleFilter";
import { UserContext } from "context/UserContext/UserContext";
import { actions } from "store/vendors/action";
import { vendorListSelector } from "store/vendors/selector";
import { useDebouncedCallback } from "use-debounce";
import { formatTypes, timePrecisonFormats } from "utils/formating";
import React, { useContext, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { common, vendor } from "translations";
import { usePush } from "utils-ts/hooks";
import { useTranslation } from "utils-ts/hooks";
import { bindActionCreators } from "redux";

const VendorList = ({ vendorPms, customActionColumns, hideHeader }) => {
    const dispatch = useDispatch();
    const resetFilter = {
        vendorId: undefined,
        vendorName: undefined,
    };
    const { push } = usePush();
    const { t } = useTranslation(["vendor", "common"]);
    const { isInAnyRole } = useContext(UserContext);
    const isVendor = isInAnyRole(["ExternalVendor", "ExternalSg", "ExternalTurnover"]);
    const [filter, setFilter] = React.useState(resetFilter);
    const { items } = useSelector(vendorListSelector);
    const handleGoToVendor = ({ vendorId }) => {
        push(`/vendors/${vendorId}`);
    };

    useEffect(() => {
        if (isVendor) {
            dispatch(actions.getVendorExtList());
        }
    }, [dispatch, isVendor]);

    //prettier-ignore
    let columns = [
        { key: "vendorId",              value: t(vendor.vendorId),               type: formatTypes.text,     filtrable: true,   },
        { key: "vendorName",            value: t(vendor.vendorName),             type: formatTypes.text,     filtrable: true, },
        { key: "cooperationType",       value: t(vendor.cooperationType),        type: formatTypes.translatable, options: { translationKey:"vendor" }     },
        { key: "dateOfStartCooperation",value: t(vendor.dateOfStartCooperation), type: formatTypes.time,         options: { timeFormat: timePrecisonFormats.days },    },
        { key: "dateOfEndCooperation",  value: t(vendor.dateOfEndCooperation),   type: formatTypes.time,         options: { timeFormat: timePrecisonFormats.days }, },
        { key: "isActive",              value: t(`common:${common.isActive}`), type: formatTypes.boolean },
    ];

    const extColumns = [
        { key: "vendorId", value: t(vendor.vendorId), type: formatTypes.text },
        { key: "name", value: t(vendor.name), type: formatTypes.text },
        { key: "vatin", value: t(vendor.vatin), type: formatTypes.text },
        {
            key: "action",
            value: "",
            type: formatTypes.action,
            action: handleGoToVendor,
            actionType: tableActionTypes.preview,
        },
    ];

    if (customActionColumns) {
        columns = columns.concat(customActionColumns);
    } else {
        columns = columns.concat({
            key: "action",
            value: "",
            type: formatTypes.action,
            action: handleGoToVendor,
            actionType: tableActionTypes.preview,
        });
    }

    const { isLoading } = vendorPms;
    const handleFilter = useDebouncedCallback((column, value) => {
        if (column === undefined && value === undefined) {
            setFilter(resetFilter);
            return;
        }

        if (column === "vendorId") {
            setFilter({ vendorId: value, vendorName: filter.vendorName });
        }

        if (column === "vendorName") {
            setFilter({ vendorId: filter.vendorId, vendorName: value });
        }
    }, 300);

    return (
        <Layout
            main
            headerText={t(vendor.Vendors)}
            hideHeader={hideHeader}
        >
            {isVendor ? (
                <SimpleTable
                    isLoading={isLoading}
                    columns={extColumns}
                    data={items}
                    onRowDoubleClick={(_, item) => {
                        handleGoToVendor(item);
                    }}
                    shoudlHandleDoubleClick
                    hover
                    stripped
                />
            ) : (
                <TableWithToggleFilter
                    isLoading={isLoading}
                    columns={columns}
                    dataSelector={vendorListSelector}
                    dataSourceAction={actions.getVendorList}
                    canAddNewItem={false}
                    filterFunction={handleFilter}
                    filterValue={filter}
                    initialFilterValue={resetFilter}
                    shoudlHandleDoubleClick
                />
            )}
        </Layout>
    );
};

const mapStateToProps = (state) => {
    let { vendorPms } = state;
    return { vendorPms };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VendorList);
