export type Role =
    | "FriscoLogged"
    | "Administrator"
    | "HelpDesk"
    | "Analyst"
    | "AXUser"
    | "BusinessSupport"
    | "BusinessSupportImporter"
    | "BusinessSupportManager"
    | "Support"
    | "SupportManager"
    | "Catman"
    | "SalesAssistant"
    | "SalesShopping"
    | "SalesManager"
    | "Ecommerce"
    | "EcommerceManager"
    | "Auditor"
    | "Accountant"
    | "AccountantManager"
    | "Logistician"
    | "LogisticianManager"
    | "Marketing"
    | "MarketingManager"
    | "TradeMarketing"
    | "TradeMarketingManager"
    | "Management"
    | "ProjectManager"
    | "OfficeManager"
    | "HumanResources"
    | "HumanResourcesManager"
    | "Archiver"
    | "MasterData"
    | "DebtCoordinator"
    | "AccountBalanceCoordinator"
    | "Manager"
    | "ExternalUser"
    | "ExternalPim"
    | "ExternalVendor"
    | "ExternalSg"
    | "ExternalTurnover";

export class Department {
    constructor(
        public Name: string,
        public Manager: Role,
        public Subordinates: Role[]
    ) {}

    GetRoles(): Role[] {
        return [this.Manager, ...this.Subordinates];
    }
}

export const FriscoUsersRoles: Role[] = [
    "Administrator",
    "HelpDesk",
    "Analyst",
    "AXUser",
    "BusinessSupport",
    "BusinessSupportImporter",
    "BusinessSupportManager",
    "Support",
    "SupportManager",
    "Catman",
    "SalesAssistant",
    "SalesShopping",
    "SalesManager",
    "Ecommerce",
    "EcommerceManager",
    "Auditor",
    "Accountant",
    "AccountantManager",
    "Logistician",
    "LogisticianManager",
    "Marketing",
    "MarketingManager",
    "TradeMarketing",
    "TradeMarketingManager",
    "Management",
    "ProjectManager",
    "OfficeManager",
    "HumanResources",
    "HumanResourcesManager",
    "Archiver",
    "MasterData",
    "DebtCoordinator",
    "AccountBalanceCoordinator",
];

export const ExternalUsersRoles: Role[] = ["ExternalUser", "ExternalPim", "ExternalVendor", "ExternalSg", "ExternalTurnover"];

export const Roles: Role[] = [...FriscoUsersRoles, ...ExternalUsersRoles];

export const B2BDepartment: Department = new Department("B2BDepartment", "BusinessSupportManager", ["BusinessSupport", "BusinessSupportImporter"]);

export const SupportDepartment: Department = new Department("SupportDepartment", "SupportManager", ["Support"]);

export const B2BAndSupportDepartment: Role[] = B2BDepartment.GetRoles().concat(SupportDepartment.GetRoles());

export const SalesDepartment: Department = new Department("SalesDepartment", "SalesManager", ["Catman", "SalesAssistant", "SalesShopping"]);

export const ECommerceDepartment: Department = new Department("ECommerceDepartment", "EcommerceManager", ["Ecommerce"]);

export const AccountantDepartment: Department = new Department("AccountantDepartment", "AccountantManager", ["Accountant"]);

export const LogisticianDepartment: Department = new Department("LogisticianDepartment", "Logistician", ["LogisticianManager"]);

export const MarketingDepartment: Department = new Department("MarketingDepartment", "MarketingManager", ["Marketing"]);
export const TradeMarketingDepartment: Department = new Department("TradeMarketingDepartment", "TradeMarketingManager", ["TradeMarketing"]);
export const HumanResourcesDepartment: Department = new Department("HumanResourcesDepartment", "HumanResourcesManager", ["HumanResources"]);
export const ITDepartment: Department = new Department("ITDepartment", "Administrator", ["HelpDesk"]);
export const AuditorAndMasterData: Role[] = ["Auditor", "MasterData"];
export const DebtCoordinator: Role[] = ["DebtCoordinator"];
export const AccountBalanceCoordinator: Role[] = ["AccountBalanceCoordinator"];

export const Departments: Department[] = [
    B2BDepartment,
    SupportDepartment,
    SalesDepartment,
    ECommerceDepartment,
    AccountantDepartment,
    LogisticianDepartment,
    MarketingDepartment,
    TradeMarketingDepartment,
    HumanResourcesDepartment,
    ITDepartment,
];

export const DepartmentManagersRoles: Role[] = [
    "Management",
    "BusinessSupportManager",
    "SupportManager",
    "SalesManager",
    "EcommerceManager",
    "AccountantManager",
    "LogisticianManager",
    "MarketingManager",
    "TradeMarketingManager",
    "HumanResourcesManager",
];

export const CommerceManagerRoles: Role[] = [
    "Administrator",
    "Analyst",
    "AXUser",
    "Management",
    "AccountBalanceCoordinator",
    ...B2BDepartment.GetRoles(),
    ...SupportDepartment.GetRoles(),
    ...SalesDepartment.GetRoles(),
    ...ECommerceDepartment.GetRoles(),
    ...AccountantDepartment.GetRoles(),
    ...LogisticianDepartment.GetRoles(),
    ...MarketingDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
    ...HumanResourcesDepartment.GetRoles(),
    ...AuditorAndMasterData,
    ...DebtCoordinator,
];

export function GetUserDepartmentSubordinate(userRoles: Role[]): Role[] {
    if ((userRoles || []).length === 0) {
        return [];
    }

    if (userRoles.includes("Administrator")) {
        return Roles.filter((r) => r !== "HelpDesk");
    } else if (userRoles.includes("Management") || userRoles.includes("Auditor")) {
        return Roles.filter((r) => r !== "HelpDesk" && r != "Administrator");
    }

    const managerUserRoles = DepartmentManagersRoles.filter((mr) => userRoles.includes(mr));
    if (managerUserRoles.length === 0) {
        return [];
    } else {
        let departmentRoles: Role[] = [];

        if (managerUserRoles.includes(B2BDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(B2BDepartment.GetRoles());
        }
        if (managerUserRoles.includes(SupportDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(SupportDepartment.GetRoles());
        }
        if (managerUserRoles.includes(SalesDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(SalesDepartment.GetRoles());
        }
        if (managerUserRoles.includes(ECommerceDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(ECommerceDepartment.GetRoles());
        }
        if (managerUserRoles.includes(AccountantDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(AccountantDepartment.GetRoles());
        }
        if (managerUserRoles.includes(LogisticianDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(LogisticianDepartment.GetRoles());
        }
        if (managerUserRoles.includes(MarketingDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(MarketingDepartment.GetRoles());
        }
        if (managerUserRoles.includes(TradeMarketingDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(TradeMarketingDepartment.GetRoles());
        }
        if (managerUserRoles.includes(HumanResourcesDepartment.Manager)) {
            departmentRoles = departmentRoles.concat(HumanResourcesDepartment.GetRoles());
        }
    }

    return [];
}
