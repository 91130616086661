import { JwtPayload, jwtDecode } from "jwt-decode";
import React, { ComponentType, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { signinRedirectKey } from "utils-ts/auth/userManager";
import { useUser } from "utils-ts/hooks";
import { Role } from "routing-ts/roles";

export const AuthenticationGuard = ({ component, roles }: { component: ComponentType<{}>; roles?: Role[] }) => {
    const Component = withAuth(component, roles);

    return <Component />;
};

const withAuth =
    <P extends object>(Component: React.ComponentType<P>, roles?: Role[]): React.FC<P> =>
    (props) => {
        const { isAuthenticated, isLoading, user, signinRedirect } = useAuth();
        const { isInAnyRoleOrAdmin } = useUser();
        let expired = false;
        if (user) {
            const tokenDecoded = jwtDecode<JwtPayload>(user.access_token);
            if (tokenDecoded.exp) {
                expired = tokenDecoded.exp * 1000 < Date.now();
            }
        }

        useEffect(() => {
            if (isLoading || (isAuthenticated && !expired)) {
                return;
            }

            (async (): Promise<void> => {
                if (window.location.pathname.includes("callback")) {
                    if (!localStorage.getItem(signinRedirectKey)) {
                        localStorage.setItem(signinRedirectKey, "/");
                    }
                } else {
                    localStorage.setItem(signinRedirectKey, window.location.pathname);
                }

                await signinRedirect();
            })();
        }, [isLoading, isAuthenticated]);

        return isAuthenticated ? (
            roles === undefined || isInAnyRoleOrAdmin(roles) ? (
                <Component {...props} />
            ) : (
                <div className="page-layout">Wygląda na to, że nie masz uprawnień, aby zobaczyć tą stronę</div>
            )
        ) : (
            <div className="page-layout">Loading...</div>
        );
    };
