import { makeStyles } from "tss-react/mui";
import React from "react";
import { Collapse } from "@mui/material";
import Grid from "@mui/material/Grid";
import { GridHeaderProps } from "control-types";
import GridHeader from "./GridHeader";

const useStyles = makeStyles()((theme) => ({
    grid: {
        margin: "0 -15px !important",
        width: "unset",
    },
    gridWithHeader: {
        marginBottom: theme.spacing(2),
    },
}));

const GridContainer: React.FC<
    {
        children: React.ReactNode;
        useCollapse?: boolean;
        isCollapsed?: boolean;
    } & Pick<
        GridHeaderProps,
        "badgeCount" | "badgeCountOf" | "hideBottomLine" | "subtitle" | "title" | "translateSubtitle" | "translateTitle" | "useBadge" | "iconName"
    >
> = ({
    children,
    title,
    subtitle,
    iconName,
    translateTitle = true,
    translateSubtitle = true,
    hideBottomLine = false,
    useCollapse = false,
    isCollapsed = true,
    useBadge,
    badgeCount,
    badgeCountOf,
}) => {
    const [collapsed, setCollapsed] = React.useState(isCollapsed);
    const { classes } = useStyles();
    const badgeProp = useBadge
        ? {
              useBadge,
              badgeCount: badgeCount ?? 0,
              badgeCountOf,
          }
        : {
              useBadge: undefined,
              badgeCount: undefined,
              badgeCountOf: undefined,
          };

    return (
        <>
            <GridHeader
                title={title}
                subtitle={subtitle}
                iconName={iconName}
                translateTitle={translateTitle}
                translateSubtitle={translateSubtitle}
                hideBottomLine={hideBottomLine}
                isCollapsed={useCollapse ? isCollapsed : undefined}
                handleCollapse={useCollapse ? (isCollapsed) => setCollapsed(isCollapsed) : undefined}
                {...badgeProp}
            />

            {useCollapse ? (
                <Collapse in={!collapsed}>
                    <Grid
                        container
                        className={title ? classes.gridWithHeader : classes.grid}
                    >
                        {children}
                    </Grid>
                </Collapse>
            ) : (
                <Grid
                    container
                    className={title ? classes.gridWithHeader : classes.grid}
                    rowSpacing={1}
                >
                    {children}
                </Grid>
            )}
        </>
    );
};

export default GridContainer;
