import React from "react";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";

const AcceptSubsidiaryGainForm = React.lazy(() =>
    import("views/externalUsers/subsidiaryGains").then((module) => ({
        default: module.AcceptSubsidiaryGainForm,
    }))
);
const SubsidiaryGainsList = React.lazy(() =>
    import("views/externalUsers/subsidiaryGains").then((module) => ({
        default: module.SubsidiaryGainsList,
    }))
);

const InitProductList = React.lazy(() =>
    import("views-ts/externalUsers").then((module) => ({
        default: module.InitProductList,
    }))
);

const InitProductDetailsForm = React.lazy(() =>
    import("views-ts/externalUsers").then((module) => ({
        default: module.InitProductDetailsForm,
    }))
);

export const ExternalUsers: PrivateRoute[] = [
    new PrivateRoute(Paths.ExternalUsers.SubsidiaryGainsList, ["ExternalSg"], SubsidiaryGainsList, "subsidiaryGains", "find_in_page"),
    new PrivateRoute(Paths.ExternalUsers.SubsidiaryGainForm, ["ExternalSg"], AcceptSubsidiaryGainForm),

    new PrivateRoute(Paths.ExternalUsers.ProductInitsList, ["ExternalPim"], InitProductList, "initProducts", "create"),
    new PrivateRoute(Paths.ExternalUsers.ProductInitForm, ["ExternalPim"], InitProductDetailsForm),
];
