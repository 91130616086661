import { Paper } from "@mui/material";
import { Translation } from "translations/Translation";
import { Spacing } from "components-ts/view";
import { GridContainer } from "../grid";

const FormSection: React.FC<{
    title: Translation;
    children: React.ReactNode | React.ReactNode[];
}> = ({ title, children }) => {
    return (
        <Spacing spacing={1}>
            <Paper elevation={2}>
                <Spacing spacing={1}>
                    <GridContainer
                        title={title}
                        hideBottomLine
                    >
                        {children}
                    </GridContainer>
                </Spacing>
            </Paper>
        </Spacing>
    );
};

export default FormSection;
