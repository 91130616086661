import { useDebouncedCallback } from "use-debounce";
import React, { forwardRef, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Avatar, Grid, IconButton, InputLabel } from "@mui/material";
import { ProductIdFieldProps } from "control-types";
import { api } from "api";
import { useVirtualizedParentResize } from "../virtualizations";
import TextField from "./TextField";


const ProductIdField: React.FC<ProductIdFieldProps> = forwardRef(({ label, error, value, onChange: inputOnChange, readOnly, warning }, ref) => {
    const resize = useVirtualizedParentResize();
    const { data, setQueryParams, status } = api.commerce.offers.products.useGetSimplifiedProduct({
        productIds: value ? [value] : [],
    });
    const [inputValue, setInputValue] = useState(value);
    const [cleared, setCleared] = useState(false);
    const setFieldValue = useDebouncedCallback((val) => {
        if (val) {
            setQueryParams({ productIds: [val] });
            setCleared(false);
            if (inputOnChange) {
                inputOnChange(val);
            }
        }
    }, 750);

    useEffect(() => {
        if (value && (!inputValue || value !== inputValue)) {
            setInputValue(value);
            setQueryParams({ productIds: [value] });
        } else if (!value && inputValue) {
            setInputValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (resize !== undefined && status === "success") {
            resize();
        }
    }, [resize, status, value]);

    const product = data && data.length > 0 && data[0].productId === value ? data[0] : null;
    if (!value || (value && error?.hasError) || product === null || cleared) {
        return (
            <TextField
                label={label}
                error={error}
                value={inputValue}
                numberType="numeric"
                onChange={(newValue) => {
                    setInputValue(newValue);
                    setFieldValue(newValue);
                }}
                readOnly={readOnly}
                ref={ref}
                warning={warning}
            />
        );
    } else {
        return (
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item>
                        <InputLabel variant="standard">{label}</InputLabel>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid
                        item
                        style={{ display: "flex" }}
                        ref={ref}
                    >
                        <Avatar
                            variant="square"
                            src={product.imageUrl}
                            sx={{ display: "inline-flex" }}
                        />
                        {`(${product.productId}) ${product.brand || ""} - ${product.name || ""}`}
                        {!readOnly && (
                            <IconButton
                                aria-label="clear"
                                onClick={() => {
                                    setInputValue("");
                                    setCleared(true);
                                    if (inputOnChange) {
                                        inputOnChange("");
                                    }
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </>
        );
    }
});

export default ProductIdField;