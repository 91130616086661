import { createSelector } from "reselect";

const root = (state) => ({
    vendorUsers: state.vendorUsers,
});

export const sgDecisionSelector = createSelector(root, ({ vendorUsers = {} }) => {
    return vendorUsers.sgDecision;
});

export const sgListSelector = createSelector(root, ({ vendorUsers }) => {
    return {
        ...vendorUsers.sgList,
        items: vendorUsers.sgList.items.map((x) => ({
            ...x,
            ...x.subsidiaryGain,
            responsibleUser: x.responsibleUserEmail,
            vendorDecision: x.uhStatusHistory && x.uhStatusHistory.length > 0 ? x.uhStatusHistory.at(-1) : undefined,
        })),
    };
});

export const turnoverFormSelector = createSelector(root, ({ vendorUsers }) => {
    const form = vendorUsers.turnoverForm;
    return {
        ...form,
        invoiceSummaryTotal: form.invoiceSummaryTotal
            ? asFloat(form.invoiceSummaryTotal, {
                  useThousandsSeparator: true,
                  decimalSeparator: ".",
              }) + " zł"
            : "Brak danych",
        transactionSummaryTotal: form.transactionSummaryTotal
            ? asFloat(form.transactionSummaryTotal, {
                  useThousandsSeparator: true,
                  decimalSeparator: ".",
              }) + " zł"
            : "Brak danych",
        vendorSummaryTotal: form.vendorSummaryTotal
            ? asFloat(form.vendorSummaryTotal, {
                  useThousandsSeparator: true,
                  decimalSeparator: ".",
              })
            : form.vendorSummaryTotalFile
            ? 0
            : "Brak danych",
        vendorSummaryTotalFile: form.vendorSummaryTotalFile && {
            ...form.vendorSummaryTotalFile,
            name: form.vendorSummaryTotalFile?.fileName,
        },
        details: _(form.details)
            .map((value, key) => {
                return {
                    key,
                    ...value,
                    vendorSummary: value.vendorSummary || 0,
                    transactionSummary: value.transactionSummary
                        ? asFloat(value.transactionSummary, {
                              useThousandsSeparator: true,
                              decimalSeparator: ".",
                          })
                        : "Brak danych",
                    invoiceSummary: value.invoiceSummary
                        ? asFloat(value.invoiceSummary, {
                              useThousandsSeparator: true,
                              decimalSeparator: ".",
                          })
                        : "Brak danych",
                    file: value.file && {
                        ...value.file,
                        name: value.file.fileName,
                    },
                    corrections: value.corrections.map((c) => ({
                        ...c,
                        vendorSummaryDifference: c.newVendorSummary - c.oldVendorSummary,
                    })),
                };
            })
            .value(),
    };
});

export const turnoverMonthsSelector = createSelector(turnoverFormSelector, ({ turnoverForm }) => turnoverForm.turnoverForm.months);

export const turnoverListSelector = createSelector(root, ({ vendorUsers }) => {
    const turnovers = vendorUsers.turnoverList;
    return {
        ...turnovers.list,
        items: turnovers.list.items.map((i) => ({
            ...i,
            month: moment(i.month, "MM").format("MMMM"),
        })),
    };
});
