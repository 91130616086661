import { makeStyles } from "tss-react/mui";
import React, { forwardRef } from "react";
import { FormControlLabel, Switch as MuiSwitch } from "@mui/material";
import { SwitchProps } from "control-types";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";


const useStyles = makeStyles()(() => ({
    label: {
        padding: 0,
        fontSize: "0.8rem",
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: "0.00938em",
    },
}));

const Switch: React.FC<SwitchProps> = forwardRef(
    ({ value, label, error, required, onBlur, onChange, readOnly, name, size, adornment, warning }, ref) => {
        const startAdornment = adornment != undefined && adornment.position === "start" ? adornment.value : undefined;
        const endAdornment = adornment != undefined && adornment.position === "end" ? adornment.value : undefined;
        const { classes } = useStyles();

        return (
            <>
                {startAdornment}
                <FormControlLabel
                    name={name}
                    style={{
                        width: endAdornment !== undefined || startAdornment !== undefined ? "80%" : undefined,
                        minHeight: "48px", //height of textField
                    }}
                    classes={{
                        label: classes.label,
                    }}
                    labelPlacement="end"
                    label={label}
                    required={required}
                    control={
                        <>
                            <MuiSwitch
                                size={size}
                                onChange={(event, checked) => onChange(checked, event)}
                                onBlur={onBlur}
                                disabled={readOnly}
                                checked={value}
                                inputRef={ref}
                            />
                        </>
                    }
                />
                {endAdornment}
                <FormHelperText error={error} warning={warning} />
            </>
        );
    }
);

export default Switch;