import { generatePath } from "react-router-dom";
import { useUser } from "utils-ts/hooks";
import { useGetQuery, useUpdateMutation } from "api/hooks";
import type { FillProductInit, ProductInit, UpdateProductInitImages } from "Content-ProductInit";

const url = (role: "Sales" | "Auditor" | "Vendor", id: string) =>
    generatePath(`api/v1/${role === "Vendor" ? "vendors/init-products" : role === "Auditor" ? "auditors/init-products" : "products/init"}/:id?`, {
        id,
    });

export const useGetInitProduct = (id: string) => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useGetQuery<ProductInit>({
        app: "content",
        url: url(isVendor ? "Vendor" : isAuditor ? "Auditor" : "Sales", id),
        shouldMakeRequest: Boolean(id),
        silentError: true,
    });
};

export const useUpdateInitProduct = (id: string) => {
    const { isInAnyRole } = useUser();
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useUpdateMutation<FillProductInit, FillProductInit>({
        app: "content",
        url: url(isVendor ? "Vendor" : "Sales", id),
    });
};

export const useUpdateInitProductImages = (id: string) => {
    const { isInAnyRole } = useUser();
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useUpdateMutation<UpdateProductInitImages, UpdateProductInitImages>({
        app: "content",
        url: `${url(isVendor ? "Vendor" : "Sales", id)}/update-images`,
        contentType: "formData",
    });
};
