import { Dropzone } from "components/Files";
import React from "react";

const SingleFileField = ({ handleOnDrop, accept, file, disabled }) => {
    const onFileDrop = (files) => {
        handleOnDrop(files[0]);
    };

    const hasFile = !!file && Object.keys(file).length !== 0;

    return hasFile ? (
        file.fileName
    ) : (
        <Dropzone
            maxFiles={1}
            accept={accept}
            onDrop={onFileDrop}
            disabled={disabled}
        />
    );
};

export default SingleFileField;
