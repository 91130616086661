import { UseMutationResult, useMutation as useReactMutation } from "@tanstack/react-query";
import { Fetcher } from "api-types";
import { jsonFetcher } from "./fetchers";

const useMutation = <TBody extends Fetcher.Body, TResponse>(
    commandConfig: Fetcher.Command<TResponse, TBody>
): UseMutationResult<TResponse, Response, TBody, unknown> => {
    const mutation = useReactMutation<TResponse, Response, TBody, unknown>({
        mutationKey: [commandConfig.url, {}, {}],
        mutationFn: (body) => jsonFetcher<TResponse, {}, TBody>(commandConfig, undefined, body),
        ...commandConfig.options,
    });

    return mutation as UseMutationResult<TResponse, Response, TBody, unknown>;
};

export const useCreateOrUpdateMutation = <TBody extends Fetcher.Body, TResponse>({
    app,
    url,
    id,
    silentError = false,
}: Pick<Fetcher.Command<TResponse, TBody>, "app" | "url"> & {
    id?: string;
    silentError?: boolean;
}) => {
    return useMutation<TBody, TResponse>({
        app: app,
        url: url,
        method: !id ? "POST" : "PUT",
        options: {
            mutationKey: [app, url],
        },
        silentError,
        contentType: "json",
    });
};

export const useUpdateMutation = <TBody extends Fetcher.Body, TResponse>({
    app,
    url,
    silentError = false,
    contentType = "json",
}: Pick<Fetcher.Command<TResponse, TBody>, "app" | "url" | "contentType"> & {
    silentError?: boolean;
}) => {
    return useMutation<TBody, TResponse>({
        app: app,
        url: url,
        method: "PUT",
        options: {
            mutationKey: [app, url],
        },
        silentError,
        contentType,
    });
};

export const useDeleteMutation = <TBody extends Fetcher.Body = {}, TResponse = void>({
    app,
    url,
    silentError = false,
}: Pick<Fetcher.Command<TResponse, TBody>, "app" | "url"> & {
    silentError?: boolean;
}) => {
    return useMutation<TBody, TResponse>({
        app: app,
        url: url,
        method: "DELETE",
        silentError,
        options: {
            mutationKey: [app, url],
        },
        contentType: "json",
    });
};

export const useFileMutation = ({
    app,
    url,
    silentError = false,
}: Pick<Fetcher.Command<undefined, FormData>, "app" | "url"> & {
    silentError?: boolean;
}) => {
    return useMutation<FormData, undefined>({
        app: app,
        url: url,
        method: "POST",
        silentError,
        options: {
            mutationKey: [app, url],
        },
    });
};
