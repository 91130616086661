import {
    BaseValueValidators,
    ExtendedRuleValidators,
    ExtendedRuleValidatorsAndConditionExtensions,
    ExtendedRuleValidatorsAndExtensions,
    ExtendedValidators,
} from "extended-validator";
import { AsyncRuleValidators, AsyncValidator, RuleValidators, ValidationErrors, Validator } from "fluentvalidation-ts";
import moment from "moment";
import { deepPathObject } from "utils-ts/functions";
import { dateToString, isDate } from "utils-ts/functions/dateUtils";
import { tValidation, validation } from "./translation";
import { isEmpty, property } from "lodash";
import _ from "lodash";

class ProxyValidator<TModel> extends Validator<TModel> {
    constructor() {
        super();
    }

    public ruleForEach2<
        TPropertyName extends keyof TModel,
        TValue extends TModel[TPropertyName] extends (infer TEachValue)[] | readonly (infer TEachValue)[] | null | undefined
            ? TModel[TPropertyName] & (TEachValue[] | readonly TEachValue[] | null | undefined)
            : never,
    >(propertyName: TModel[TPropertyName] extends unknown[] | readonly unknown[] | null | undefined ? TPropertyName : never) {
        return this.ruleForEach<TPropertyName, TValue>(propertyName);
    }

    public ruleFor2<TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(propertyName: TPropertyName) {
        return this.ruleFor<TPropertyName, TValue>(propertyName);
    }

    public validate2(value: TModel) {
        return this.validate(value);
    }
}
class ProxyAsyncValidator<TModel> extends AsyncValidator<TModel> {
    constructor() {
        super();
    }

    public ruleForEach2<
        TPropertyName extends keyof TModel,
        TValue extends TModel[TPropertyName] extends (infer TEachValue)[] | readonly (infer TEachValue)[] | null | undefined
            ? TModel[TPropertyName] & (TEachValue[] | readonly TEachValue[] | null | undefined)
            : never,
    >(propertyName: TModel[TPropertyName] extends unknown[] | readonly unknown[] | null | undefined ? TPropertyName : never) {
        return this.ruleForEach<TPropertyName, TValue>(propertyName);
    }

    public ruleFor2<TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(propertyName: TPropertyName) {
        return this.ruleFor<TPropertyName, TValue>(propertyName);
    }

    public validateAsync2(value: TModel) {
        return this.validateAsync(value);
    }
}

type ArrayPropertyNames<T> = {
    [K in keyof T]: T[K] extends object | null | undefined ? (T[K] extends unknown[] | readonly unknown[] | null | undefined ? K : never) : never;
}[keyof T];

export class ExtendedValidator<TModel, TValidatorType extends "AsyncValidator" | "SyncValidator" = "SyncValidator"> {
    private baseValidator = new ProxyValidator<TModel>();
    private baseAsyncValidator = new ProxyAsyncValidator<TModel>();
    private conditionalValidators = new Array<{
        condition: (model: TModel) => boolean;
        validators: (model?: TModel) => Array<ExtendedRuleValidatorsAndConditionExtensions<TModel, TModel[keyof TModel]>>;
    }>();

    private notEmptyMessage = tValidation(validation.notEmpty);
    private regexMessage = tValidation(validation.formatRegex);

    constructor(validatorType?: TValidatorType) {
        this.validatorType = validatorType ?? ("SyncValidator" as TValidatorType);
    }
    public validatorType: TValidatorType;

    private isValueEmpty = (value: unknown): boolean => {
        return (
            value === null ||
            value === undefined ||
            typeof value === "undefined" ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === "string" && value.replace(/\s/g, "").length === 0)
        );
    };

    validate = (model: TModel): ValidationErrors<TModel> => {
        if (this.validatorType === "AsyncValidator") {
            throw new Error("trying to use async validator as sync");
        }

        if (this.conditionalValidators.length > 0) {
            this.conditionalValidators.forEach((o) => {
                if (o.condition(model)) {
                    o.validators().forEach((r) => r.when(o.condition, "AppliesToAllValidators"));
                }
            });
        }

        return this.baseValidator.validate2(model);
    };

    validateAsync = (model: TModel): Promise<ValidationErrors<TModel>> => {
        if (this.validatorType === "AsyncValidator") {
            if (this.conditionalValidators.length > 0) {
                this.conditionalValidators.forEach((o) => {
                    if (o.condition(model)) {
                        o.validators().forEach((r) => r.when(o.condition, "AppliesToAllValidators"));
                    }
                });
            }

            return this.baseAsyncValidator.validateAsync2(model);
        } else {
            return Promise.resolve(this.validate(model));
        }
    };

    atLeastOneOfFieldNotEmpty = <TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(propertyNames: TPropertyName[]): void => {
        if (propertyNames === null || propertyNames === undefined || propertyNames.length === 0) {
            return;
        }

        propertyNames.forEach((property) => {
            const base =
                this.validatorType === "AsyncValidator"
                    ? this.baseAsyncValidator.ruleFor2<TPropertyName, TValue>(property)
                    : this.baseValidator.ruleFor2<TPropertyName, TValue>(property);

            base.must({ predicate: (val) => !this.isValueEmpty(val), message: () => tValidation(validation.atLeastOneField) }).when((model) => {
                return propertyNames.filter((p) => p !== property).every((p) => this.isValueEmpty(model[p]));
            });
        });
    };

    when = (
        condition: (model: TModel) => boolean,
        validators: (model?: TModel) => Array<ExtendedRuleValidatorsAndConditionExtensions<TModel, TModel[keyof TModel]>>
    ) => {
        this.conditionalValidators.push({ condition, validators });
    };

    private extendRuleValidators = <TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName], TAsync extends true | false>(
        propertyName: TPropertyName,
        base: RuleValidators<TModel, TValue> | AsyncRuleValidators<TModel, TValue>,
        isAsync: TAsync
    ): ExtendedRuleValidators<TModel, TValue, TAsync> => {
        //Extend BaseValueValidators
        let baseResult = Object.assign({
            notNull: () => {
                return this.extendValidationResult(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    base.must({
                        predicate: (val) => !this.isValueEmpty(val),
                        message: () => tValidation(validation.notEmpty),
                    }),
                    isAsync
                );
            },
            null: () => {
                return this.extendValidationResult(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    base.must({
                        predicate: (val) => this.isValueEmpty(val),
                        message: () => tValidation(validation.notEmpty),
                    }),
                    isAsync
                );
            },
            notEqual: (forbiddenValue: TValue) => {
                const message = tValidation(validation.mustBeNotEqaul, { value: forbiddenValue?.toString() });
                return this.extendValidationResult(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    base.notEqual(forbiddenValue).withMessage(message),
                    isAsync
                );
            },
            equal: (requiredValue: TValue) => {
                const message =
                    typeof requiredValue === "boolean"
                        ? requiredValue === true
                            ? tValidation(validation.mustBeChecked)
                            : tValidation(validation.mustBeNotChecked)
                        : tValidation(validation.mustBeEqual, { value: requiredValue?.toString() });
                return this.extendValidationResult(
                    propertyName,
                    baseResult,
                    extendedValidators,
                    base.equal(requiredValue).withMessage(message),
                    isAsync
                );
            },
            must: (
                definition:
                    | ((value: TModel[TPropertyName], model: TModel) => boolean)
                    | {
                          predicate: (value: TModel[TPropertyName], model: TModel) => boolean;
                          message: string | ((value: TModel[TPropertyName], model: TModel) => string);
                      }
                    | (
                          | ((value: TModel[TPropertyName], model: TModel) => boolean)
                          | {
                                predicate: (value: TModel[TPropertyName], model: TModel) => boolean;
                                message: string | ((value: TModel[TPropertyName], model: TModel) => string);
                            }
                      )[]
            ) => {
                const baseResult = base.must(definition);
                const { when, unless, withMessage } = baseResult;
                return this.getConditionalsWithMessage(
                    propertyName,
                    this.extendRuleValidators(propertyName, baseResult, isAsync),
                    extendedValidators,
                    when,
                    unless,
                    withMessage,
                    isAsync
                );
            },
        }) as BaseValueValidators<TModel, TValue>;

        //Extend StringValueValidators
        if ("notEmpty" in base) {
            baseResult = {
                ...baseResult,
                notEmpty: () =>
                    this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.notEmpty().withMessage(this.notEmptyMessage).notNull().withMessage(this.notEmptyMessage),
                        isAsync
                    ),
                length: (minLength: number, maxLength: number) => {
                    const message = tValidation(validation.inRange, { min: minLength, max: maxLength });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.length(minLength, maxLength).withMessage(message),
                        isAsync
                    );
                },
                maxLength: (maxLength: number) => {
                    const message = tValidation(validation.maxLength, { max: maxLength });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.maxLength(maxLength).withMessage(message),
                        isAsync
                    );
                },
                minLength: (minLength: number) => {
                    const message = tValidation(validation.minLength, { min: minLength });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.minLength(minLength).withMessage(message),
                        isAsync
                    );
                },
                matches: (pattern: RegExp) =>
                    this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.matches(pattern).withMessage(this.regexMessage),
                        isAsync
                    ),
                emailAddress: () => {
                    const message = tValidation(validation.email);
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.emailAddress().withMessage(message),
                        isAsync
                    );
                },
                identity: () => {
                    const message = tValidation(validation.inRange, { min: 1, max: 100 });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.length(1, 100).withMessage(message),
                        isAsync
                    );
                },
                exactLength: (exactLength: number) => {
                    const message = tValidation(validation.length, { number: exactLength });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.length(exactLength, exactLength).withMessage(message),
                        isAsync
                    );
                },
                postcode: () => {
                    const message = tValidation(validation.length, { number: 6 });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base
                            .notEmpty()
                            .withMessage(this.notEmptyMessage)
                            .notNull()
                            .withMessage(this.notEmptyMessage)
                            .length(6, 6)
                            .withMessage(message)
                            .matches(/^[0-9]{2}-[0-9]{3}$/)
                            .withMessage(this.regexMessage),
                        isAsync
                    );
                },
                postcodeMask: () => {
                    const message = tValidation(validation.length, { number: 6 });
                    return this.extendValidationResult(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base
                            .notEmpty()
                            .withMessage(this.notEmptyMessage)
                            .notNull()
                            .withMessage(this.notEmptyMessage)
                            .length(6, 6)
                            .withMessage(message)
                            .matches(
                                /^(([0-9]{2}-[0-9]{3})|([0-9]{2}-[0-9]{2}[\*]{1})|([0-9]{2}-[0-9]{1}[\*]{2})|([0-9]{2}-[\*]{3})|([0-9]{1}[\*]{1}-[\*]{3})|([\*]{2}-[\*]{3}))$/
                            )
                            .withMessage(this.regexMessage),
                        isAsync
                    );
                },
            };
        }

        //Extend NumberValueValidators
        if ("lessThan" in base) {
            baseResult = {
                ...baseResult,
                lessThan: (threshold: number) => {
                    const message = tValidation(validation.lessThan, { value: threshold });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.lessThan(threshold).withMessage(message),
                        isAsync
                    );
                },
                lessThanOrEqualTo: (threshold: number) => {
                    const message = tValidation(validation.lessOrEqualsThan, { value: threshold });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.lessThanOrEqualTo(threshold).withMessage(message),
                        isAsync
                    );
                },
                greaterThan: (threshold: number) => {
                    const message = tValidation(validation.greaterThan, { number: threshold });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.greaterThan(threshold).withMessage(message),
                        isAsync
                    );
                },
                greaterThanOrEqualTo: (threshold: number) => {
                    const message = tValidation(validation.greaterOrEqualsThan, { number: threshold });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.greaterThanOrEqualTo(threshold).withMessage(message),
                        isAsync
                    );
                },
                exclusiveBetween: (lowerBound: number, upperBound: number) => {
                    const message = tValidation(validation.between, { min: lowerBound, max: upperBound });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.exclusiveBetween(lowerBound, upperBound).withMessage(message),
                        isAsync
                    );
                },
                inclusiveBetween: (lowerBound: number, upperBound: number) => {
                    const message = tValidation(validation.between, { min: lowerBound, max: upperBound });
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.inclusiveBetween(lowerBound, upperBound).withMessage(message),
                        isAsync
                    );
                },
                scalePrecision: (precision: number, scale: number) => {
                    const message = tValidation(validation.mustHaveLessDecimalCount);
                    return this.extendValidationResult<TPropertyName, TValue, TAsync>(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.scalePrecision(precision, scale).withMessage(message),
                        isAsync
                    );
                },
            };
        }

        //Extend ObjectValueValidators
        if ("setValidator" in base) {
            baseResult = {
                ...baseResult,
                setValidator: (
                    validator:
                        | ((model: TModel) => {
                              validateAsync: (
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  model: TValue extends null | undefined ? any : TValue
                              ) => Promise<ValidationErrors<TValue extends null | undefined ? unknown : TValue>>;
                          })
                        | {
                              validateAsync: (
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  model: TValue extends null | undefined ? any : TValue
                              ) => Promise<ValidationErrors<TValue extends null | undefined ? unknown : TValue>>;
                          }
                ) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const validate = async (model: any) => {
                        const val = typeof validator === "function" ? validator(model) : validator;
                        return await val.validateAsync(model);
                    };

                    return this.extendValidationResult(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.setValidator((model) => {
                            const val = typeof validator === "function" ? validator(model) : validator;
                            return val as unknown as {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                validate: (model: any) => ValidationErrors<any>;
                            };
                        }),
                        isAsync
                    );
                },
            };
        }

        const extendMustValidatorResult = (
            predicate: (value: TValue, model: TModel) => boolean,
            message: string | ((value: TValue, model: TModel) => string)
        ) =>
            this.extendValidationResult<TPropertyName, TValue, TAsync>(
                propertyName,
                baseResult,
                extendedValidators,
                base.must({ predicate, message }),
                isAsync
            );

        const greaterThanOrEqualTo = (compareToProperty: keyof TModel | number) => {
            return extendMustValidatorResult(
                (val, model) => {
                    if (typeof compareToProperty === "number") {
                        return val >= compareToProperty;
                    }

                    const compareTo = model[compareToProperty];
                    if (val === undefined || val === null || val === "" || compareTo === undefined || compareTo === null || compareTo === "") {
                        return true;
                    }

                    if (typeof val !== typeof model[compareToProperty]) {
                        throw Error("Comparing different types");
                    }

                    if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
                        return val >= compareTo;
                    } else if (typeof val === "string") {
                        return val >= compareTo;
                    } else if (moment.isMoment(val) && moment.isMoment(compareTo)) {
                        return val.isSameOrAfter(compareTo);
                    } else if (isDate(val) && isDate(compareTo)) {
                        return moment(val).isSameOrAfter(moment(compareTo));
                    }

                    return false;
                },
                (_val, model) => {
                    if (typeof compareToProperty === "number") {
                        return tValidation(validation.greaterOrEqualsThan, { number: compareToProperty?.toString() });
                    }

                    const compareTo = model[compareToProperty];
                    if (moment.isMoment(compareTo)) {
                        return tValidation(validation.greaterOrEqualsDateThan, { date: compareTo.format("YYYY-MM-DD HH:mm") });
                    } else if (isDate(compareTo)) {
                        return tValidation(validation.greaterOrEqualsDateThan, { date: dateToString(compareTo) });
                    }

                    return tValidation(validation.greaterOrEqualsThan, { number: compareTo?.toString() });
                }
            );
        };
        const greaterThan = (compareToProperty: keyof TModel | number) => {
            return extendMustValidatorResult(
                (val, model) => {
                    if (typeof compareToProperty === "number") {
                        return val > compareToProperty;
                    }

                    const compareTo = model[compareToProperty];
                    if (this.isValueEmpty(val) || this.isValueEmpty(compareTo)) {
                        return true;
                    }

                    if (typeof val !== typeof model[compareToProperty]) {
                        throw Error("Comparing different types");
                    }

                    if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
                        return val > compareTo;
                    } else if (typeof val === "string") {
                        return val > compareTo;
                    } else if (moment.isMoment(val) && moment.isMoment(compareTo)) {
                        return val.isAfter(compareTo);
                    } else if (isDate(val) && isDate(compareTo)) {
                        return moment(val).isAfter(moment(compareTo));
                    }

                    return false;
                },
                (_val, model) => {
                    if (typeof compareToProperty === "number") {
                        return tValidation(validation.greaterThan, { number: compareToProperty?.toString() });
                    }

                    const compareTo = model[compareToProperty];
                    if (moment.isMoment(compareTo)) {
                        return tValidation(validation.greaterDateThan, { date: compareTo.format("YYYY-MM-DD HH:mm") });
                    } else if (isDate(compareTo)) {
                        return tValidation(validation.greaterDateThan, { date: dateToString(compareTo) });
                    }

                    return tValidation(validation.greaterThan, { number: compareTo?.toString() });
                }
            );
        };
        const lessThanOrEqualTo = (compareToProperty: keyof TModel | number) => {
            return extendMustValidatorResult(
                (val, model) => {
                    if (typeof compareToProperty === "number") {
                        return val <= compareToProperty;
                    }

                    const compareTo = model[compareToProperty];
                    if (this.isValueEmpty(val) || this.isValueEmpty(compareTo)) {
                        return true;
                    }

                    if (typeof val !== typeof model[compareToProperty]) {
                        throw Error("Comparing different types");
                    }

                    if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
                        return val <= compareTo;
                    } else if (typeof val === "string") {
                        return val <= compareTo;
                    } else if (moment.isMoment(val) && moment.isMoment(compareTo)) {
                        return val.isSameOrBefore(compareTo);
                    } else if (isDate(val) && isDate(compareTo)) {
                        return moment(val).isSameOrBefore(moment(compareTo));
                    }

                    return false;
                },
                (_val, model) => {
                    if (typeof compareToProperty === "number") {
                        return tValidation(validation.lessOrEqualsThan, { number: compareToProperty?.toString() });
                    }

                    const compareTo = model[compareToProperty];
                    if (moment.isMoment(compareTo)) {
                        return tValidation(validation.lessOrEqualsDateThan, { date: compareTo.format("YYYY-MM-DD HH:mm") });
                    } else if (isDate(compareTo)) {
                        return tValidation(validation.lessOrEqualsDateThan, { date: dateToString(compareTo) });
                    }

                    return tValidation(validation.lessOrEqualsThan, { number: compareTo?.toString() });
                }
            );
        };
        const lessThan = (compareToProperty: keyof TModel | number) => {
            return extendMustValidatorResult(
                (val, model) => {
                    if (typeof compareToProperty === "number") {
                        return val < compareToProperty;
                    }

                    const compareTo = model[compareToProperty];
                    if (this.isValueEmpty(val) || this.isValueEmpty(compareTo)) {
                        return true;
                    }

                    if (typeof val !== typeof model[compareToProperty]) {
                        throw Error("Comparing different types");
                    }

                    if (typeof val === "number" || typeof val === "bigint" || typeof val === "boolean") {
                        return val < compareTo;
                    } else if (typeof val === "string") {
                        return val < compareTo;
                    } else if (moment.isMoment(val) && moment.isMoment(compareTo)) {
                        return val.isBefore(compareTo);
                    } else if (isDate(val) && isDate(compareTo)) {
                        return moment(val).isBefore(moment(compareTo));
                    }

                    return false;
                },
                (_val, model) => {
                    if (typeof compareToProperty === "number") {
                        return tValidation(validation.lessThan, { value: compareToProperty?.toString() });
                    }

                    const compareTo = model[compareToProperty];
                    if (moment.isMoment(compareTo)) {
                        return tValidation(validation.lessDateThan, { date: compareTo.format("YYYY-MM-DD HH:mm") });
                    } else if (isDate(compareTo)) {
                        return tValidation(validation.lessDateThan, { date: dateToString(compareTo) });
                    }

                    return tValidation(validation.lessThan, { value: compareTo?.toString() });
                }
            );
        };

        const extendedValidators = {
            greaterThanOrEqualTo,
            greaterThan,
            lessThanOrEqualTo,
            lessThan,
        } as unknown as ExtendedValidators<TModel, TValue, TAsync>;

        if (isAsync && "mustAsync" in base) {
            return {
                ...baseResult,
                ...extendedValidators,
                mustAsync: (definition: {
                    predicate: (value: TValue, model: TModel) => Promise<boolean>;
                    message: string | ((value: TValue, model: TModel) => string);
                }) => this.extendValidationResult(propertyName, baseResult, extendedValidators, base.mustAsync(definition), isAsync),
                setValidatorAsync: (
                    validator: ((model: TModel) => ExtendedValidator<TValue, "AsyncValidator">) | ExtendedValidator<TValue, "AsyncValidator">
                ) => {
                    this.extendValidationResult(
                        propertyName,
                        baseResult,
                        extendedValidators,
                        base.setAsyncValidator((model) => {
                            const val = typeof validator === "function" ? validator(model) : validator;
                            return val as unknown as {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                validateAsync: (model: any) => Promise<ValidationErrors<any>>;
                            };
                        }),
                        isAsync
                    );
                },
            } as unknown as ExtendedRuleValidators<TModel, TValue, true>;
        } else {
            return { ...baseResult, ...extendedValidators } as ExtendedRuleValidators<TModel, TValue, TAsync>;
        }
    };

    ruleFor = <TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName]>(
        propertyName: TPropertyName
    ): ExtendedRuleValidators<TModel, TValue, TValidatorType extends "AsyncValidator" ? true : false> => {
        if (this.validatorType === "AsyncValidator") {
            const base = this.baseAsyncValidator.ruleFor2<TPropertyName, TValue>(propertyName);

            return this.extendRuleValidators<TPropertyName, TValue, true>(propertyName, base, true);
        } else {
            const base = this.baseValidator.ruleFor2<TPropertyName, TValue>(propertyName);

            return this.extendRuleValidators<TPropertyName, TValue, false>(propertyName, base, false) as ExtendedRuleValidators<
                TModel,
                TValue,
                TValidatorType extends "AsyncValidator" ? true : false
            >;
        }
    };

    ruleForEach = <
        TArrayPropertyName extends ArrayPropertyNames<TModel>,
        TValue extends TModel[TArrayPropertyName] extends Array<infer V> ? V : TModel[TArrayPropertyName],
        TValueBase extends TModel[TArrayPropertyName] extends (infer TEachValue)[] | readonly (infer TEachValue)[] | null | undefined
            ? TModel[TArrayPropertyName] & (TEachValue[] | readonly TEachValue[] | null | undefined)
            : never,
    >(
        propertyName: TArrayPropertyName
    ): ExtendedRuleValidators<TModel, TValue, TValidatorType extends "AsyncValidator" ? true : false> => {
        const prop = propertyName as unknown as TModel[TArrayPropertyName] extends unknown[] | readonly unknown[] | null | undefined
            ? TArrayPropertyName
            : never;

        if (this.validatorType === "AsyncValidator") {
            const base = this.baseAsyncValidator.ruleForEach2<TArrayPropertyName, TValueBase>(prop);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return this.extendRuleValidators(propertyName, base as unknown as any, true) as unknown as ExtendedRuleValidators<TModel, TValue, true>;
        } else {
            const base = this.baseValidator.ruleForEach2<TArrayPropertyName, TValueBase>(prop);
            return this.extendRuleValidators(
                propertyName,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                base as unknown as any,
                this.validatorType === "AsyncValidator"
            ) as unknown as ExtendedRuleValidators<TModel, TValue, TValidatorType extends "AsyncValidator" ? true : false>;
        }
    };

    getRequiredFields = async (defaultValue?: TModel): Promise<Array<string>> => {
        const errors = await this.validateAsync(defaultValue ?? ({} as TModel));
        const deep = deepPathObject(errors);
        function getKeyPaths(obj: {}, key: string, currentPath: string = "", result: string[] = []) {
            if (typeof obj !== "object" || obj === null) {
                return result;
            }

            for (const property in obj) {
                if (obj.hasOwnProperty(property)) {
                    const newPath = currentPath === "" ? property : `${currentPath}.${property}`;
                    const val = _.get(obj, property);

                    if (Array.isArray(val)) {
                        val.forEach((item, index) => {
                            getKeyPaths(item, key, `${newPath}[${index}]`, result);
                        });
                    } else {
                        getKeyPaths(val, key, newPath, result);
                    }

                    if (property === key) {
                        result.push(newPath);
                    }
                }
            }

            return result;
        }

        return getKeyPaths(deep, "message").map((p) => p.split(".").slice(0, -1).join("."));
    };

    //Extend RuleValidatorsAndExtensions (ExtendedRuleValidatorsAndConditionExtensions)
    private extendValidationResult<TPropertyName extends keyof TModel, TValue extends TModel[TPropertyName], TAsync extends true | false>(
        propertyName: TPropertyName,
        baseResult: BaseValueValidators<TModel, TValue>,
        extendedValidators: ExtendedValidators<TModel, TValue, TAsync>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validatorRule: RuleValidators<TModel, any> & {
            when: (
                condition: (model: TModel) => boolean,
                appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) => RuleValidators<TModel, any>;
            unless: (
                condition: (model: TModel) => boolean,
                appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) => RuleValidators<TModel, any>;
        },
        isAsync: TAsync
    ): ExtendedRuleValidatorsAndConditionExtensions<TPropertyName, TValue, TAsync> {
        const { when, unless } = validatorRule;

        const conditionals = {
            when: (condition: (model: TModel) => boolean, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(propertyName, when(condition, appliesTo), isAsync),
            unless: (condition: (model: TModel) => boolean, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(propertyName, unless(condition, appliesTo), isAsync),
            whenEmpty: (property?: (keyof TModel)[] | keyof TModel, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(
                    propertyName,
                    when(
                        (model: TModel) =>
                            Array.isArray(property)
                                ? property.every((p) => this.isValueEmpty(model[p]))
                                : property !== undefined
                                ? this.isValueEmpty(model[property])
                                : this.isValueEmpty(model[propertyName]),
                        appliesTo
                    ),
                    isAsync
                ),
            whenNotEmpty: (property?: (keyof TModel)[] | keyof TModel, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(
                    propertyName,
                    when(
                        (model: TModel) =>
                            Array.isArray(property)
                                ? property.every((p) => !this.isValueEmpty(model[p]))
                                : property !== undefined
                                ? !this.isValueEmpty(model[property])
                                : !this.isValueEmpty(model[propertyName]),
                        appliesTo
                    ),
                    isAsync
                ),
        };

        return {
            ...baseResult,
            ...conditionals,
            ...extendedValidators,
        } as unknown as ExtendedRuleValidatorsAndConditionExtensions<TPropertyName, TValue, TAsync>;
    }

    //Extend RuleValidatorsAndConditionExtensions (ExtendedRuleValidatorsAndExtensions)
    private getConditionalsWithMessage<
        TPropertyName extends keyof TModel,
        TValue extends
            | TModel[TPropertyName]
            | (TModel[TPropertyName] & (string | null | undefined))
            | (TModel[TPropertyName] & (number | null | undefined))
            | (TModel[TPropertyName] & (object | null | undefined)),
    >(
        propertyName: TPropertyName,
        baseResult: BaseValueValidators<TModel, TValue>,
        extendedValidators: ExtendedValidators<TModel, TValue>,
        when: (
            condition: (model: TModel) => boolean,
            appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator" | undefined
        ) => RuleValidators<TModel, TValue>,
        unless: (
            condition: (model: TModel) => boolean,
            appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator" | undefined
        ) => RuleValidators<TModel, TValue>,
        withMessage: (message: string) => RuleValidators<TModel, TValue> & {
            when: (
                condition: (model: TModel) => boolean,
                appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator" | undefined
            ) => RuleValidators<TModel, TValue>;
            unless: (
                condition: (model: TModel) => boolean,
                appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator" | undefined
            ) => RuleValidators<TModel, TValue>;
        },
        isAsync: boolean
    ): ExtendedRuleValidatorsAndExtensions<TModel, TValue> {
        const conditionals = {
            when: (condition: (model: TModel) => boolean, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(propertyName, when(condition, appliesTo), isAsync),
            unless: (condition: (model: TModel) => boolean, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(propertyName, unless(condition, appliesTo), isAsync),
            whenEmpty: (property?: (keyof TModel)[] | keyof TModel, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(
                    propertyName,
                    when(
                        (model: TModel) =>
                            Array.isArray(property)
                                ? property.every((p) => isEmpty(model[p]))
                                : property !== undefined
                                ? isEmpty(model[property])
                                : isEmpty(model[propertyName]),
                        appliesTo
                    ),
                    isAsync
                ),
            whenNotEmpty: (property?: (keyof TModel)[] | keyof TModel, appliesTo?: "AppliesToAllValidators" | "AppliesToCurrentValidator") =>
                this.extendRuleValidators(
                    propertyName,
                    when(
                        (model: TModel) =>
                            Array.isArray(property)
                                ? property.every((p) => !isEmpty(model[p]))
                                : property !== undefined
                                ? !isEmpty(model[property])
                                : !isEmpty(model[propertyName]),
                        appliesTo
                    ),
                    isAsync
                ),
        };

        return {
            ...baseResult,
            ...extendedValidators,
            ...conditionals,
            withMessage: (message: string) =>
                this.extendValidationResult(propertyName, baseResult, extendedValidators, withMessage(message), isAsync),
        } as unknown as ExtendedRuleValidatorsAndExtensions<TModel, TValue>;
    }
}
