import buttons from "assets/jss/custom/buttons";
import { makeStyles } from "tss-react/mui";
import { CircularProgress, Button as MUIButton, ButtonProps as MuiButtonProps, Theme, Tooltip, TooltipProps } from "@mui/material";
import { Translation, isTranslationObject } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";
import { Color, default as useButtonStyles } from "components-ts/controls/buttons/useButtonStyles";

const useStyles = makeStyles()((theme: Theme) => ({
    ...buttons,
    fullWidth: {
        width: "100%",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    grid: {
        marginBottom: theme.spacing(1),
    },
    item: {
        paddingLeft: theme.spacing(1),
    },
    buttonProgress: {
        color: theme.palette.success.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

type ButtonProps = Pick<MuiButtonProps, "variant" | "disabled" | "onClick" | "type" | "className" | "fullWidth" | "style" | "startIcon"> & {
    color?: Color;
    isLoading?: boolean;
    actionType?: "accept" | "decline" | "danger" | "warning" | "primary" | "secondary" | "disabled";
} & (
        | {
              tooltipMessage: string;
              tooltipPosition?:
                  | "bottom-end"
                  | "bottom-start"
                  | "bottom"
                  | "left-end"
                  | "left-start"
                  | "left"
                  | "right-end"
                  | "right-start"
                  | "right"
                  | "top-end"
                  | "top-start"
                  | "top";
          }
        | {
              tooltipMessage?: never;
              tooltipPosition?: never;
          }
    ) &
    (
        | {
              label: Translation;
              literalLabel?: boolean;
          }
        | {
              label?: never;
              literalLabel?: never;
          }
    );

const Button = ({
    isLoading = false,
    color = "primary",
    disabled,
    label,
    actionType,
    variant,
    onClick,
    type,
    className,
    fullWidth,
    style,
    startIcon,
    tooltipMessage,
    tooltipPosition = "left-start",
    literalLabel,
}: ButtonProps): JSX.Element => {
    const { classes } = useStyles();
    const { classes: buttonClasses, cx } = useButtonStyles({ color });
    const { t } = useTranslation();

    const button = (
        <div style={{ height: "100%", position: "relative" }}>
            <MUIButton
                variant={variant || "contained"}
                disabled={disabled || isLoading}
                classes={buttonClasses}
                className={cx(className)}
                onClick={onClick}
                type={type}
                fullWidth={fullWidth}
                style={style}
                startIcon={startIcon}
            >
                {label ? (literalLabel ? label.toString() : t(label)) : null}
            </MUIButton>
            {isLoading && (
                <CircularProgress
                    size={28}
                    className={classes.buttonProgress}
                />
            )}
        </div>
    );

    if (tooltipMessage) {
        return (
            <Tooltip
                title={tooltipMessage}
                aria-label={tooltipMessage}
                placement={tooltipPosition}
                arrow
            >
                {button}
            </Tooltip>
        );
    } else {
        return button;
    }
};

export default Button;
