import React, { useEffect } from "react";
import { useFormState } from "react-hook-form";
import { Grid } from "@mui/material";
import { commerceErrorCode, common } from "translations";
import { useCopyParams, useTranslation } from "utils-ts/hooks";
import { useForm, useValidator } from "utils-ts/hooks";
import { api } from "api";
import { DiscountDocument, DiscountProduct } from "Commerce-Offer";
import { Paths } from "routing-ts/ManagerPaths";
import { Select } from "components-ts/controls";
import {
    CampaignAutocomplete,
    Form,
    FormDateTime,
    FormDecimal,
    FormDivisionSelect,
    FormInteger,
    FormList,
    FormProductIdField,
    FormRow,
    FormSwitch,
    FormTextField,
    FormUserAutocomplete,
} from "components-ts/forms";
import { GridContainer } from "components-ts/forms/grid";
import { TextWithLabel } from "components-ts/text";
import { HelpTooltip } from "components-ts/tooltips";
import { Spacing, View } from "components-ts/view";
import { DiscountDocumentValidator } from "./validator";

const DiscountValidator: React.FC<{ id?: string }> = ({ id }) => {
    const { t } = useTranslation();
    const { data, refetch } = api.commerce.offers.discounts.useGetDiscountValidation(id);
    const formState = useFormState();

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            refetch();
        }
    }, [formState.submitCount]);

    if (id === undefined || data === undefined || data.warehouses.length === 0) {
        return <></>;
    } else {
        const warehouseProblems = data.warehouses
            .filter((x) => Boolean(x.productProblems))
            .map((w) => ({
                warehouse: w.warehouse,
                productProblems: Object.keys(w.productProblems)
                    .map((productId) => {
                        const product = w.products.find((p) => p.productId === productId);
                        if (product === undefined) {
                            return undefined;
                        }

                        const productPrices =
                            product.lowPrice !== undefined && product.newPrice !== undefined
                                ? " " + t("newPriceVsLowPrice", { lowPrice: product.lowPrice, newPrice: product.newPrice })
                                : "";
                        return `${productId}: ${product.problems.map((pp) => t(pp)).join(", ") + productPrices}`;
                    })
                    .filter((o) => o !== undefined)
                    .join("\n"),
            }));

        return (
            <View headerText={common.discountValidation}>
                <FormRow>
                    {warehouseProblems.map((w) => (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            key={w.warehouse}
                        >
                            <Spacing spacing={2}>
                                <GridContainer
                                    iconName="location_city"
                                    title={w.warehouse}
                                    translateTitle={false}
                                >
                                    <TextWithLabel
                                        value={w.productProblems}
                                        label={t(common.productProblems)}
                                    />
                                </GridContainer>
                            </Spacing>
                        </Grid>
                    ))}
                </FormRow>
            </View>
        );
    }
};

const DiscountForm: React.FC = () => {
    const { id, isCopy } = useCopyParams();
    const { t } = useTranslation(["common", "commerceErrorCode"]);
    const { data, status } = api.commerce.offers.discounts.useGetDiscount(id);
    const validatorResolver = useValidator(new DiscountDocumentValidator());
    const form = useForm<DiscountDocument>({
        resolver: validatorResolver,
        values:
            {
                ...data,
                isPositioned: !isCopy && !id ? true : data?.isPositioned,
                id: isCopy ? "" : data?.id,
            } || {},
    });
    const name = form.getValues("name") ?? data?.name ?? "";

    return (
        <Form
            form={form}
            initQueryStatus={status}
            submitMutation={api.commerce.offers.discounts.useCreateOrUpdateDiscount(isCopy ? undefined : id)}
            formPath={Paths.Offers.DiscountForm}
            listPath={Paths.Offers.DiscountsList}
            id={isCopy ? "" : id}
            headerText={{ resource: common.discounts, params: { id: !isCopy && id ? id : "" } }}
            customTitle={id && name && `${t(common.discounts)} - ${name}`}
        >
            <FormRow>
                <FormTextField
                    name="name"
                    label={t(common.name)}
                />

                <CampaignAutocomplete
                    label={t(common.campaignName)}
                    name="campaignName"
                />

                <FormDivisionSelect
                    label={t(common.division)}
                    multiple
                    disableEmpty
                />
            </FormRow>

            <FormRow>
                <FormDateTime
                    name="validFrom"
                    label={t(common.validFrom)}
                />

                <FormDateTime
                    name="validTo"
                    label={t(common.validTo)}
                />

                <FormUserAutocomplete
                    name="categoryManagers"
                    label={t(common.catman)}
                    roles={["Catman"]}
                    multiple
                    disableEmpty
                />
            </FormRow>

            <FormRow>
                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                />

                <FormSwitch
                    name="isPositioned"
                    label={t(common.isPositioned)}
                />

                <FormSwitch
                    name="isPriceReplaced"
                    label={t(common.isPriceReplaced)}
                />

                <FormSwitch
                    name="isPriceLocked"
                    label={t(common.isPriceLocked)}
                    adornment={{
                        position: "end",
                        value: <HelpTooltip text="Ustawia cenę na konkretną i całkowicie wyłącza zniżki i promocje na produkcie" />,
                    }}
                />
            </FormRow>

            <FormRow>
                <Grid
                    item
                    xs={12}
                >
                    <DiscountValidator id={id} />
                </Grid>
            </FormRow>

            <FormList<DiscountProduct>
                name="products"
                label={common.products}
                defaultValue={{
                    modifier: {
                        discountPercent: 0,
                    },
                }}
                type="virtualize"
                searchBy="productId"
                searchLabel="Szuka po ID produktu"
                canExportAllItems
                exportAllItemsButtonText={common.exportProducts}
                exportAllItemsAs="csv"
                exportAllItemsFileName="Export"
                transformItems={(items) => {
                    let csv = "Id Produktu;Modyfikacja;Wartość";
                    items.forEach((item) => {
                        if (item.modifier.discountPercent !== undefined) {
                            csv += `\n${item.productId};Rabat procentowy;${item.modifier.discountPercent.toString().replace(".", ",")}`;
                        } else if (item.modifier.discountValue !== undefined) {
                            csv += `\n${item.productId};Rabat wartościowy;${item.modifier.discountValue.toString().replace(".", ",")}`;
                        } else if (item.modifier.newPrice !== undefined) {
                            csv += `\n${item.productId};Nowa cena;${item.modifier.newPrice.toString().replace(".", ",")}`;
                        }
                    });

                    return csv;
                }}
                handleOnPaste={(paste) => {
                    const rows = new Array<DiscountProduct>();
                    let error = "";
                    if (paste.length > 0) {
                        const pasteRows = paste.split("\n").filter((o) => Boolean(o));
                        const canIgnoreFirstItem = pasteRows.length > 1;
                        pasteRows.forEach((pasteRow, index) => {
                            const [productIdCell, discountValueCell, discountPercentCell, newPriceCell] = pasteRow.split("\t");
                            if (productIdCell.length > 0 && Number.isInteger(Number.parseInt(productIdCell))) {
                                const discountValue = Number.parseFloat(discountValueCell?.replace(",", "."));
                                const discountPercent = Number.parseFloat(discountPercentCell?.replace(",", "."));
                                const newPrice = Number.parseFloat(newPriceCell?.replace(",", "."));

                                const modifierCount =
                                    (!Number.isNaN(discountValue) && discountValue > 0 ? 1 : 0) +
                                    (!Number.isNaN(discountPercent) && discountPercent > 0 ? 1 : 0) +
                                    (!Number.isNaN(newPrice) && newPrice > 0 ? 1 : 0);

                                if (modifierCount > 1) {
                                    if (canIgnoreFirstItem && index === 0) {
                                        return;
                                    }

                                    error = error.concat(`wiersz ${index + 1} zawiera więcej niż 1 modyfikator\n`);
                                } else if (modifierCount === 0) {
                                    if (canIgnoreFirstItem && index === 0) {
                                        return;
                                    }
                                    error = error.concat(`wiersz ${index + 1} ma puste lub błędne modyfikatory\n`);
                                } else {
                                    rows.push({
                                        productId: productIdCell,
                                        modifier: {
                                            discountPercent: discountPercent > 0 ? discountPercent : undefined,
                                            discountValue: discountValue > 0 ? discountValue : undefined,
                                            newPrice: newPrice > 0 ? newPrice : undefined,
                                        },
                                    });
                                }
                            } else if (canIgnoreFirstItem && index === 0) {
                                return;
                            } else {
                                error = error.concat(`wiersz ${index + 1} zawiera nieprawidłowe id produktu: '${productIdCell}'\n`);
                            }
                        });
                    }

                    return { rows, error };
                }}
            >
                {({ item, index }) => {
                    const row = form.watch(`products.${index}`);
                    const isPriceReplaced = form.watch("isPriceReplaced");
                    const modifier = row.modifier;
                    const modifierType =
                        modifier.discountPercent !== undefined
                            ? "discountPercent"
                            : modifier.discountValue !== undefined
                            ? "discountValue"
                            : modifier.newPrice !== undefined
                            ? "newPrice"
                            : "unknown";

                    return (
                        <FormRow>
                            <FormProductIdField
                                name="productId"
                                label={t(common.productId)}
                                width="double"
                            />

                            <Grid item>
                                <Select
                                    label={t(common.modifier)}
                                    value={modifierType}
                                    items={["discountValue", "discountPercent", "newPrice"].map((o) => ({ name: t(o), value: o }))}
                                    onChange={(newValue) => {
                                        const currentValue = modifier.discountPercent || modifier.discountValue || modifier.newPrice || 0;
                                        if (newValue === "discountPercent") {
                                            form.setValue(`products.${index}.modifier`, {
                                                discountPercent: currentValue,
                                                discountValue: undefined,
                                                newPrice: undefined,
                                            });
                                        } else if (newValue === "discountValue") {
                                            form.setValue(`products.${index}.modifier`, {
                                                discountPercent: undefined,
                                                discountValue: currentValue,
                                                newPrice: undefined,
                                            });
                                        } else if (newValue === "newPrice") {
                                            form.setValue(`products.${index}.modifier`, {
                                                discountPercent: undefined,
                                                discountValue: undefined,
                                                newPrice: currentValue,
                                            });
                                        }
                                    }}
                                    disableEmpty
                                    error={
                                        isPriceReplaced && modifierType !== "newPrice"
                                            ? {
                                                  hasError: true,
                                                  message: t(`commerceErrorCode:${commerceErrorCode.DiscountPriceReplacementNewPriceRequired}`),
                                              }
                                            : undefined
                                    }
                                />
                            </Grid>

                            {modifierType === "discountPercent" ? (
                                <FormInteger
                                    name="modifier.discountPercent"
                                    label={t(common.discountPercent)}
                                />
                            ) : (
                                <FormDecimal
                                    name={modifierType === "newPrice" ? "modifier.newPrice" : "modifier.discountValue"}
                                    label={modifierType === "newPrice" ? t(common.newPrice) : t(common.discountValue)}
                                />
                            )}
                        </FormRow>
                    );
                }}
            </FormList>
        </Form>
    );
};

export default DiscountForm;
