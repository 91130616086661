import { Button } from "components/Button";
import { FormField } from "components/Form";
import { actions } from "store/cache/products/actions";
import { useMappedDispatch } from "utils/hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { common } from "translations";
import * as XLSX from "xlsx";
import ProductItem from "./PromotionPredicateFieldsComponents/ProductItem";
import { productsSelector } from "./PromotionPredicateFieldsComponents/selectors";

const isValidProductId = (v) => Boolean(Number(v)) && v?.length <= 6;

const ProductListField = ({ exportProducts = false, selectedProducts = [], ...props }) => {
    const { t } = useTranslation("common");
    const { getProducts } = useMappedDispatch(actions);
    const products = useSelector(productsSelector) || [];

    const createExcelFile = () => {
        const workSheet = XLSX.utils.book_new();
        const data = selectedProducts.map((o) => {
            return { id: o };
        });

        XLSX.utils.sheet_add_json(workSheet, data, {
            origin: "A1",
            skipHeader: true,
        });
        const workBook = {
            Sheets: { produkty: workSheet },
            SheetNames: ["produkty"],
        };
        const excelBuffer = XLSX.write(workBook, {
            type: "array",
            cellStyles: true,
        });
        const finalData = new Blob([excelBuffer]);
        saveAs(finalData, "produkty.xlsx");
    };

    return (
        <>
            {exportProducts && (
                <Button
                    color="primary"
                    onClick={() => {
                        createExcelFile();
                    }}
                    disabled={selectedProducts.length < 1}
                >
                    {t(common.exportProducts)}
                </Button>
            )}
            <FormField
                stretch
                {...props}
                name="products"
                label={t(common.products)}
                type="list"
                useList={false}
                itemRenderer={(v, handleRemove, innerProps) => {
                    return (
                        <ProductItem
                            key={v}
                            v={v}
                            handleRemove={handleRemove}
                            products={products}
                            readOnly={innerProps.readOnly}
                        />
                    );
                }}
                onAdd={(productIds) => {
                    getProducts(productIds);
                }}
                additionalValueCheck={isValidProductId}
            />
        </>
    );
};

export default ProductListField;
