import { generatePath } from "react-router-dom";
import { useCreateOrUpdateMutation, useGetQuery } from "api/hooks";
import { ProductAttribute, ProductAttributeDocument } from "Content-ProductAttribute";

const queryKey = "api/v1/product-attributes/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useGetActiveProductAttributes = () => {
    return useGetQuery<Array<ProductAttribute>, {}>({
        app: "content",
        url: queryKey + "active",
    });
};

export const useFindProductAttributes = () => {
    return useGetQuery<Array<ProductAttribute>>({
        app: "content",
        url: queryKey,
    });
};

export const useGetProductAttribute = (id?: string) => {
    return useGetQuery<ProductAttributeDocument>({
        app: "content",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdateProductAttribute = (id?: string) => {
    return useCreateOrUpdateMutation<ProductAttributeDocument, ProductAttributeDocument>({
        app: "content",
        url: url(id),
        id,
    });
};
