import { api } from "api";
import FormAutocomplete, { FormAutocompleteProps } from "../FormAutocomplete";

type SuppliersAutocompleteProps = {
    label: string;
    /**
     * default 'supplierId'
     */
    name?: string;
} & Pick<FormAutocompleteProps, "readOnly" | "width">;

const FormSuppliersAutocomplete: React.FC<SuppliersAutocompleteProps> = ({ label, name = "supplierId", readOnly, width }) => {
    const { data, status } = api.commerce.autocomplete.useFindProductSupplierNames();

    return (
        <FormAutocomplete
            name={name}
            label={label}
            items={Object.entries(data || {}).map(([id, name]) => ({ value: id, name: `${name} (${id})` }))}
            isLoadingItems={status === "pending"}
            readOnly={readOnly}
            disableEmpty
            width={width}
        />
    );
};

export default FormSuppliersAutocomplete;
