import { makeStyles } from "tss-react/mui";
import React from "react";
import { FormHelperText as MuiFormHelperText, Theme } from "@mui/material";
import { ValidationError } from "control-types";

const useStyles = makeStyles()((theme: Theme) => ({
    error: {
        color: theme.palette.error.main,
        whiteSpace: "pre-wrap",
    },
    warning: {
        color: theme.palette.warning.main,
        whiteSpace: "pre-wrap",
    },
}));

const FormHelperText: React.FC<{ error?: ValidationError; warning?: string }> = ({ error, warning }) => {
    const { classes } = useStyles();

    return (
        <>
            {warning && (
                <MuiFormHelperText
                    className={classes.warning}
                    key="warning"
                >
                    {warning}
                </MuiFormHelperText>
            )}

            {error && error.hasError && (
                <MuiFormHelperText
                    className={classes.error}
                    key="error"
                >
                    {error.message}
                </MuiFormHelperText>
            )}
        </>
    );
};

export default FormHelperText;
