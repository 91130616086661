import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { RoleInfo, UserInfo } from "Identity";
import { Item } from "control-types";
import { useGetCacheQuery } from "api/hooks";
import { Role } from "routing-ts/roles";

const queryKey = "api/identity/user-infos/";

const url = (role?: string) => generatePath(`${queryKey}:role?`, { role: role ? role : null });

export const useGetUsersWithRoleForSelect = (roles: Array<Role>, showDisabled: boolean, value?: string | Array<string>) => {
    const [index, setIndex] = useState(0);
    const { data, status } = useGetUsersWithRole(roles[index]);
    const [roleUsers, setRoleUsers] = useState<Record<string, UserInfo[]>>({});

    useEffect(() => {
        if (status === "success") {
            setIndex(index + 1);
            setRoleUsers({ ...roleUsers, [data.role]: data.users });
        }
    }, [status]);

    return {
        roleUsers: Object.values(roleUsers)
            .flatMap((u) => u)
            .reduce<UserInfo[]>((p, n) => (p.some((pu) => pu.userId === n.userId) ? p : [...p, n]), [])
            .filter((u) => u.isActive || showDisabled || value === u.userId || (Array.isArray(u) && u.includes(u.userId)))
            .sort((a, b) => {
                if (a.isActive > b.isActive) {
                    return 1;
                }

                if (a.fullName.lastName.toLowerCase() > b.fullName.lastName.toLowerCase()) {
                    return 1;
                }

                return -1;
            })
            .map(
                (u) =>
                    ({
                        value: u.userId,
                        name: `${u.fullName.firstName} ${u.fullName.lastName}${u.isActive ? "" : " (nieaktywny)"}`,
                        disabled: !u.isActive,
                    }) as Exclude<Item, string | number>
            ),
        isLoading: index !== roles.length,
    };
};

export const useGetUsersWithRole = (role?: Role) => {
    return useGetCacheQuery<RoleInfo>({
        app: "identity",
        url: url(role),
        shouldMakeRequest: Boolean(role),
        cacheTime: 60,
    });
};

export const useGetAllUsers = () => {
    return useGetCacheQuery<UserInfo[]>({
        app: "identity",
        url: url(),
    });
};
