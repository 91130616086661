import { ChargeDescriptionDocument } from "Operations-NotesAndBenefits";
import React from "react";
import { useParams } from "react-router-dom";
import { api } from "api";
import { common } from "translations";
import { useForm, useTranslation, useValidator } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { Form, FormRow, FormTextField } from "components-ts/forms";
import ChargeDescriptionDocumentValidator from "./validator";

const ChargeDescriptionForm: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, status } = api.operations.notesAndBenefits.useGetChargeDescription(id);
    const validatorResolver = useValidator(new ChargeDescriptionDocumentValidator());

    const form = useForm<ChargeDescriptionDocument>({
        resolver: validatorResolver,
        values: data,
    });

    return (
        <Form
            form={form}
            headerText={{
                resource: "chargeDescription",
            }}
            formPath={Paths.Dictionaries.ChargeDescriptionForm}
            listPath={Paths.Dictionaries.ChargeDescriptionList}
            initQueryStatus={status}
            submitMutation={api.operations.notesAndBenefits.useCreateOrUpdateChargeDescription(id)}
            id={id}
        >
            <FormRow>
                <FormTextField
                    name="value"
                    label={t(common.description)}
                    width={"fullWidth"}
                />
            </FormRow>
        </Form>
    );
};

export default ChargeDescriptionForm;
