import { ChargeDescriptionDocument, ChargeDescriptionRequest, ChargeDescriptionResponse } from "Operations-NotesAndBenefits";
import { generatePath } from "react-router-dom";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";

const url = (id?: string) => generatePath(`api/charge-descriptions/:id?`, { id: id ? id : null });

export const useGetChargeDescriptions = (initialParams: ChargeDescriptionRequest) => {
    return useGetPagedQuery<ChargeDescriptionResponse, ChargeDescriptionRequest>({
        app: "operations",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetChargeDescription = (id?: string) => {
    return useGetQuery<ChargeDescriptionDocument>({
        app: "operations",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useCreateOrUpdateChargeDescription = (id?: string) => {
    return useCreateOrUpdateMutation<ChargeDescriptionDocument, ChargeDescriptionDocument>({ app: "operations", url: url(id), id: id });
};
