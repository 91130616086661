import { FormField } from "components/Form";
import { actions } from "store/cache/commerceUsers/actions";
import { makeStyles } from "tss-react/mui";
import { firstOrEmpty } from "utils/extensions/arrayExtensions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@mui/material";
import { createSelector } from "reselect";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";

const useStyles = makeStyles()({
    customerInput: {
        width: "100%",
    },
});

const cacheSelector = (state) => state.cache;
const usersSelector = createSelector(cacheSelector, (cache) => cache.commerceUsers);

const CustomerItem = ({ user, handleRemove }) => {
    const { user: userProfile = {} } = user;
    const { id, email = "" } = userProfile;

    return (
        <Chip
            style={{ marginRight: 10, marginTop: 10 }}
            size="medium"
            label={`(${id}) ${email}`}
            color="default"
            onDelete={handleRemove}
        />
    );
};

const CustomerListInput = ({ users = [], validate, name = "users", readOnly, useDiff }) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const cachedUsers = useSelector(usersSelector);
    const { classes } = useStyles();
    useEffect(() => {
        if (users && users.length > 0) {
            dispatch(actions.getCommerceUsers(users.filter(Boolean)));
        }
    }, [dispatch, users]);

    return (
        <FormField
            className={classes.customerInput}
            validate={validate}
            name={name}
            label={t(common.customers)}
            type="list"
            useList={false}
            itemRenderer={(v, handleRemove) => (
                <CustomerItem
                    key={v}
                    asChip
                    handleRemove={readOnly ? undefined : () => handleRemove(v)}
                    user={firstOrEmpty(cachedUsers, (user) => user.userId === v)}
                />
            )}
            onAdd={(productIds) => dispatch(actions.getCommerceUsers(productIds))}
            additionalValueCheck={(v) => {
                return Boolean(Number(v)) && (String(v).length === 6 || String(v).length === 7);
            }}
            readOnly={readOnly}
            useDiff={useDiff}
        />
    );
};

export default CustomerListInput;
