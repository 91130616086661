import TextWithLabel from "components/Text/TextWithLabel";
import { getUserInfo, userInfoSelector } from "store/autocomplete";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import RejectMessage from "views/vendors/components/RejectMessage";
import ResponsibleUser from "views/vendors/components/subsidiaryGains/ResponsibleUser";

const SubsidiaryGainHeader = ({
    subsidiaryGainId,
    decisionStatus,
    status,
    invoiceError,
    removalProposal,
    decision = {},
    vendorDecision = {},
    readOnly,
}) => {
    const { t } = useTranslation("vendor");

    const dispatch = useDispatch();
    const userInfo = useSelector(userInfoSelector);

    const vendorUserFullname = vendorDecision?.changedBy && userInfo.find((u) => u.userId === vendorDecision.changedBy)?.fullName;

    useEffect(() => {
        dispatch(getUserInfo());
    }, [dispatch]);

    const statusToShow =
        decisionStatus === "AcceptedByVendor"
            ? status === "Created"
                ? decisionStatus
                : status
            : status === "InvoiceFailed" || status === "InvoiceRequested" || status === "Invoiced"
            ? status
            : decisionStatus;

    return subsidiaryGainId && decisionStatus ? (
        <>
            <RejectMessage
                isRejected={decisionStatus === "ServiceRejected" || decisionStatus === "ChangeRejected"}
                message={t(vendor.rejectedSubsidiaryGainMessage, {
                    comment: decision.comment,
                    decisionBy: decision.decisionBy,
                })}
            />

            <RejectMessage
                isRejected={decisionStatus === "RejectedByVendor"}
                message={t(vendor.rejectedSubsidiaryGainMessage, {
                    comment: vendorDecision.comment,
                    decisionBy: vendorUserFullname && vendorUserFullname.firstName + " " + vendorUserFullname.lastName,
                })}
            />

            <RejectMessage
                isRejected={status === "InvoiceFailed"}
                message={t(vendor.invoiceErrorSubsidiaryGainMessage, {
                    error: invoiceError,
                })}
            />

            <Grid
                container
                direction="row"
            >
                <Grid item>
                    <TextWithLabel
                        value={t(removalProposal ? vendor.removalProposal : statusToShow)}
                        translatedLabel={t(vendor.changeStatus)}
                    />
                </Grid>
                <ResponsibleUser readOnly={readOnly} />
            </Grid>
        </>
    ) : (
        <></>
    );
};

export default SubsidiaryGainHeader;
