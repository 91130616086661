const validation = {
    required: "required",
    mustBeNumber: "mustBeNumber",
    mustBePossitive: "mustBePossitive",
    mustHaveLessDecimalCount: "mustHaveLessDecimalCount",
    mustBeInteger: "mustBeInteger",
    beInteger: "beInteger",
    mustBeLessOrEqaulsThanOriginalQuantity: "mustBeLessOrEqaulsThanOriginalQuantity",
    shouldBeLessThanEnd: "shouldBeLessThanEnd",
    shouldBeGreaterThanStart: "shouldBeGreaterThanStart",
    phoneNumberRegex: "phoneNumberRegex",
    phoneNumberMustHaveDigitsCount: "phoneNumberMustHaveDigitsCount",
    postCodeLenght: "postCodeLenght",
    postCodeRegex: "postCodeRegex",
    charCount: "charCount",
    decimalPlaces: "decimalPlaces",
    greaterThan: "greaterThan",
    lessOrEqualsThan: "lessOrEqualsThan",
    greaterOrEqualsThan: "greaterOrEqualsThan",
    inRange: "inRange",
    length: "length",
    paymentRefund: "paymentRefund",
    stringLength: "stringLength",
    email: "email",
    phone: "phone",
    vatin: "vatin",
    notEmpty: "notEmpty",
    notEmptyWhen: "notEmptyWhen",
    passwordMustMatch: "passwordMustMatch",
    userIdRegex: "userIdRegex",
    dateNotLessThan: "dateNotLessThan",
    lessThan: "lessThan",
    validCustomerId: "validCustomerId",
    notEmptyArray: "notEmptyArray",
    atLeastOneField: "atLeastOneField",
    greaterDateThan: "greaterDateThan",
    lessDateThan: "lessDateThan",
    greaterOrEqualsDateThan: "greaterOrEqualsDateThan",
    lessOrEqualsDateThan: "lessOrEqualsDateThan",
    unique: "unique",
    mappedProductIdMustBeDiffrent: "mappedProductIdMustBeDiffrent",
    segmentMustBeInExcludedConstraint: "segmentMustBeInExcludedConstraint",
    oneIsRequiredText: "oneIsRequiredText",
    mustBeChecked: "mustBeChecked",
    mustBeNotChecked: "mustBeNotChecked",
    waterLimitsRanges: "waterLimitsRanges",
    fileNameMaxLength: "fileNameMaxLength",
    arrayMustHaveAtLeastItems: "arrayMustHaveAtLeastItems",
    notMoreThanElement: "notMoreThanElement",
    notValidGuidId: "notValidGuidId",
    mustBeEmpty: "mustBeEmpty",
    empty: "empty",
    choseOneUserActivationLimitExpiration: "choseOneUserActivationLimitExpiration",
    emptyIfIncludeProductsEmpty: "emptyIfIncludeProductsEmpty",
    containerNameAlreadyInUse: "containerNameAlreadyInUse",
    oneMustBeEmptyLimitExpiration: "oneMustBeEmptyLimitExpiration",
    atLestOneRequirement: "atLestOneRequirement",
    businessRegistryNumber: "businessRegistryNumber",
    invalidDate: "invalidDate",
    equalToF24: "equalToF24",
    equalToFRS: "equalToFRS",
    termOfBusinessProductConflict: "termOfBusinessProductConflict",
    termOfBusinessAnalyticGroupConflict: "termOfBusinessAnalyticGroupConflict",
    termOfBusinessBrandConflict: "termOfBusinessBrandConflict",
    passwordLowerChar: "passwordLowerChar",
    passwordUpperChar: "passwordUpperChar",
    passwordDigit: "passwordDigit",
    appliesToAllProductsConflict: "appliesToAllProductsConflict",
    segmentSuffixPattern: "segmentSuffixPattern",
    refundationValuesProductDuplication: "refundationValuesProductDuplication",
    arrayMustContainsUniqueValues: "arrayMustContainsUniqueValues",
    analyticGroupMerchantConflict: "analyticGroupMerchantConflict",
    storageUnitConflict: "storageUnitConflict",
    positionGroupConflict: "positionGroupConflict",
    atLeastOneManufacturedWarehouse: "atLeastOneManufacturedWarehouse",
    notEmptyFields: "notEmptyFields",
    dateOrDay: "dateOrDay",
    onlyOneChosen: "onlyOneChosen",
    someFilesExceedMaxFileSize: "someFilesExceedMaxFileSize",
    startsWithSmallLetter: "startsWithSmallLetter",
    whNotEmptyFileArray: "whNotEmptyFileArray",
    onlyLettersAndNumbers: "onlyLettersAndNumbers",
    mustContains: "mustContains",
    minLength: "minLength",
    maxLength: "maxLength",
    diffrentLength: "diffrentLength",
    between: "between",
    creditCardRegex: "creditCardRegex",
    formatRegex: "formatRegex",
    segmentMustStartsWithPrefix: "segmentMustStartsWithPrefix",
    toolkitArray: "toolkitArray",
    incorrectChars: "incorrectChars",
    mustBeEqual: "mustBeEqual",
    mustBeNotEqaul: "mustBeNotEqual",
    friscoIdIncorrect: "friscoIdIncorrect",
    emptyWhen: "emptyWhen",
    toolsArray: "toolsArray",
    priceListDates: "priceListDates",
    lastLevel: "lastLevel",
    segmentRegex: "segmentRegex",
    password: "password",
    atLeastOneSellWarehouse: "atLeastOneSellWarehouse",
    buildingNumberFormat: "buildingNumberFormat",
    incorrectPolishLicensePlate: "incorrectPolishLicensePlate",
    reipientHasOpenRegister: "reipientHasOpenRegister",
    replenishmentStoreMustBeSelling: "replenishmentStoreMustBeSelling",
} as const;

export default validation;
