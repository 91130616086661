import { PurchasePredicate, PurchaseRule, PurchaseScheduleDocument } from "Commerce-Offer";
import React from "react";
import { useParams } from "react-router-dom";
import { api } from "api";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { useForm, useValidator } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import {
    Form,
    FormDate,
    FormList,
    FormPrefixProvider,
    FormRow,
    FormSelect,
    FormSuppliersAutocomplete,
    FormSwitch,
    FormTextField,
    FormTimePicker,
    FormToggleButton,
    FormWarehouseSelect,
} from "components-ts/forms";
import PurchaseScheduleValidator from "./validator";

const PurchaseScheduleForm: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, status } = api.commerce.offers.purchaseSchedules.useGetPurchaseSchedule(id);
    const validatorResolver = useValidator(new PurchaseScheduleValidator());
    const form = useForm<PurchaseScheduleDocument>({
        resolver: validatorResolver,
        values: data,
    });

    return (
        <Form
            form={form}
            initQueryStatus={status}
            submitMutation={api.commerce.offers.purchaseSchedules.useCreateOrUpdatePurchaseSchedule(id)}
            formPath={Paths.Offers.PurchaseScheduleForm}
            listPath={Paths.Offers.PurchaseSchedulesList}
            id={id}
            headerText={{ resource: common.purchaseSchedule, params: { id } }}
        >
            <FormRow>
                <FormSuppliersAutocomplete
                    label={t(common.supplier)}
                    readOnly={Boolean(id)}
                    width="double"
                />

                <FormWarehouseSelect
                    label={t(common.warehouse)}
                    readOnly={Boolean(id)}
                    disableEmpty
                />

                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                    defaultValue={true}
                />
            </FormRow>

            <FormList<PurchaseRule>
                name="rules"
                defaultValue={{ action: { actionType: "Skip" }, isActive: true }}
                viewType="GridContainer"
                iconName="article"
                label="purchaseRules"
                type="draggable"
            >
                {({ index }) => {
                    const row = form.watch(`rules.${index}`);

                    return (
                        <>
                            <FormRow>
                                <FormTextField
                                    name="name"
                                    label={t(common.name)}
                                />

                                <FormSwitch
                                    name="isActive"
                                    label={t(common.isActive)}
                                />
                            </FormRow>

                            <FormPrefixProvider name="action">
                                <FormRow>
                                    <FormToggleButton<string>
                                        name="actionType"
                                        label={t(common.actionType)}
                                        items={[
                                            { value: "Preorder", name: "Preorder" },
                                            { value: "Order", name: "W momencie przełamania" },
                                            { value: "Skip", name: "Pomiń" },
                                        ]}
                                        onAfterChange={(newValue) => {
                                            if (newValue === "Skip") {
                                                form.setValue(`rules.${index}.action.order` as const, undefined);
                                                form.setValue(`rules.${index}.action.preorder`, undefined);
                                            } else if (newValue === "Order") {
                                                form.setValue(`rules.${index}.action.preorder`, undefined);
                                            }
                                        }}
                                        width="triple"
                                    />
                                </FormRow>

                                <FormRow>
                                    {(row.action.actionType === "Order" || row.action.actionType === "Preorder") && (
                                        <FormPrefixProvider name="order">
                                            <FormToggleButton<number>
                                                name="deliveryDayOffset"
                                                label={t(common.deliveryDayOffset)}
                                                items={[
                                                    { value: 0, name: "W dniu realizacji" },
                                                    { value: -1, name: "Poprzedniego dnia" },
                                                    { value: -2, name: "Dwa dni wcześniej" },
                                                ]}
                                                width="triple"
                                            />

                                            <FormSwitch
                                                name="isProgressiveOrdersEnabled"
                                                label={t(common.isProgressiveOrdersEnabled)}
                                            />
                                        </FormPrefixProvider>
                                    )}
                                </FormRow>

                                <FormRow>
                                    {row.action.actionType === "Preorder" && (
                                        <FormPrefixProvider name="preorder">
                                            <FormToggleButton<number>
                                                name="dayOffset"
                                                label={t(common.dayOffset)}
                                                items={[
                                                    { value: 0, name: "W dniu realizacji" },
                                                    { value: -1, name: "Poprzedniego dnia" },
                                                    { value: -2, name: "Dwa dni wcześniej" },
                                                ]}
                                                width="triple"
                                            />

                                            <FormTimePicker
                                                name="timeOffset"
                                                label={t(common.timeOffset)}
                                            />
                                        </FormPrefixProvider>
                                    )}
                                </FormRow>
                            </FormPrefixProvider>

                            <FormList<PurchasePredicate>
                                name="predicates"
                                viewType="GridContainer"
                                label="purchasePredicate"
                                iconName="agriculture"
                                defaultValue={{}}
                                isSecondaryGrid
                            >
                                {() => {
                                    return (
                                        <>
                                            <FormRow>
                                                <FormDate
                                                    name="deliveryDateFrom"
                                                    label={t(common.deliveryDateFrom)}
                                                />

                                                <FormDate
                                                    name="deliveryDateTo"
                                                    label={t(common.deliveryDateTo)}
                                                />

                                                <FormSelect
                                                    name="weekdays"
                                                    label={t(common.weekdays)}
                                                    items={[
                                                        { value: "Monday", name: t(common.Monday) },
                                                        { value: "Tuesday", name: t(common.Tuesday) },
                                                        { value: "Wednesday", name: t(common.Wednesday) },
                                                        { value: "Thursday", name: t(common.Thursday) },
                                                        { value: "Friday", name: t(common.Friday) },
                                                        { value: "Saturday", name: t(common.Saturday) },
                                                        { value: "Sunday", name: t(common.Sunday) },
                                                    ]}
                                                    multiple
                                                    disableEmpty
                                                />

                                                <FormSelect
                                                    name="shifts"
                                                    label={t(common.shifts)}
                                                    items={[
                                                        { value: "EarlyMorning", name: t(common.earlyMorning) },
                                                        { value: "Morning", name: t(common.morning) },
                                                        { value: "LateMorning", name: t(common.lateMorning) },
                                                        { value: "Afternoon", name: t(common.afternoon) },
                                                        { value: "SameDay", name: t(common.sameDay) },
                                                    ]}
                                                    multiple
                                                    disableEmpty
                                                />
                                            </FormRow>

                                            <FormRow>
                                                <FormToggleButton<boolean>
                                                    label={t(common.isNonTradeSunday)}
                                                    name="isNonTradeSunday"
                                                    items={[
                                                        { value: true, name: "Tak" },
                                                        { value: false, name: "Nie" },
                                                        { value: undefined, name: "Nie dotyczy" },
                                                    ]}
                                                />

                                                <FormToggleButton<boolean>
                                                    label={t(common.isMondayAfterNonTradeSunday)}
                                                    name="isMondayAfterNonTradeSunday"
                                                    items={[
                                                        { value: true, name: "Tak" },
                                                        { value: false, name: "Nie" },
                                                        { value: undefined, name: "Nie dotyczy" },
                                                    ]}
                                                />

                                                <FormToggleButton<boolean>
                                                    label={t(common.isMorningAfterHoliday)}
                                                    name="isMorningAfterHoliday"
                                                    items={[
                                                        { value: true, name: "Tak" },
                                                        { value: false, name: "Nie" },
                                                        { value: undefined, name: "Nie dotyczy" },
                                                    ]}
                                                />
                                            </FormRow>
                                        </>
                                    );
                                }}
                            </FormList>
                        </>
                    );
                }}
            </FormList>
        </Form>
    );
};

export default PurchaseScheduleForm;
