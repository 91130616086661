import { makeStyles } from "tss-react/mui";
import { Ref, forwardRef } from "react";
import { Icon, Theme } from "@mui/material";
import { Grid, ToggleButtonGroup as MuiToggleButtonGroup, ToggleButton, Typography } from "@mui/material";
import { ToggleButtonGroupProps } from "control-types";
import { useTranslation } from "utils-ts/hooks";
import { Color } from "components-ts/controls/buttons/useButtonStyles";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";

const useButtonStyles = makeStyles<{ color: Color }>()((theme: Theme, { color }) => ({
    button: {
        "background": theme.palette[color].light,
        "color": theme.palette[color].contrastText,
        "&:hover": {
            background: theme.palette[color].main,
        },
        "&.Mui-selected": {
            "background": theme.palette[color].dark,
            "color": theme.palette[color].contrastText,
            "&:hover": {
                background: theme.palette[color].dark,
                color: theme.palette[color].contrastText,
            },
        },
        "&.Mui-disabled": {
            color: theme.palette[color].contrastText,
        },
        "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            borderRight: `1px ${theme.palette[color].contrastText} solid`,
        },
        "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            borderLeft: `1px ${theme.palette[color].contrastText} solid`,
        },
    },
}));

const ToggleButtonGroup = <T extends string | number | boolean>(
    { value, label, items, onChange, color = "primary", readOnly, error, hideSelectedIcon = false, warning }: ToggleButtonGroupProps<T>,
    ref: Ref<HTMLDivElement>
): JSX.Element => {
    const { classes } = useButtonStyles({ color });
    const { t } = useTranslation();

    return (
        <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            {Boolean(label) && (
                <Grid
                    item
                    xs={4}
                >
                    <Typography style={{ width: "100%", overflowWrap: "break-word" }}>{label}</Typography>
                </Grid>
            )}

            <Grid
                item
                xs={Boolean(label) ? 7 : undefined}
            >
                <MuiToggleButtonGroup
                    value={value === undefined || value === "" ? "undefined" : value}
                    size="small"
                    exclusive
                    onChange={(_event, value) => {
                        if (value === null) {
                            return;
                        }

                        if (value === "undefined") {
                            onChange(undefined);
                        } else {
                            onChange(value);
                        }
                    }}
                    disabled={readOnly}
                    ref={ref}
                >
                    {items.map((i, index) => {
                        const label = typeof i.name !== "string" ? t(i.name) : i.name;

                        return (
                            <ToggleButton
                                key={index}
                                className={classes.button}
                                value={i.value === undefined ? "undefined" : i.value}
                            >
                                {!hideSelectedIcon && (i.value === value || (value === "" && i.value === undefined)) && <Icon>check</Icon>}
                                {label}
                            </ToggleButton>
                        );
                    })}
                </MuiToggleButtonGroup>
                <FormHelperText
                    error={error}
                    warning={warning}
                />
            </Grid>
        </Grid>
    );
};

export default forwardRef(ToggleButtonGroup) as <T extends string | number | boolean>(
    props: ToggleButtonGroupProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof ToggleButtonGroup>;
