import { generatePath } from "react-router-dom";
import { useUser } from "utils-ts/hooks";
import {
    useCreateOrUpdateMutation,
    useDeleteMutation,
    useFileQuery,
    useGetCacheQuery,
    useGetPagedQuery,
    useGetQuery,
    useUpdateMutation,
} from "api/hooks";
import { FillInitProductModel, InitProduct, ProductFile, ProductInit, ProductInitSearchParameters, UploadFileModel } from "Operations-ProductInit";
import { PagedResult } from "Shared";

const url = (role: "Sales" | "Auditor" | "Vendor", id?: string) =>
    generatePath(`api/${role === "Auditor" ? "auditors/init-products" : role === "Sales" ? "products/init" : "external-users/init-products"}/:id?`, {
        id: id ? id : null,
    });

export const useGetEans = () => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useGetCacheQuery<Array<string>>({
        app: "operations",
        url: url(isVendor ? "Vendor" : isAuditor ? "Auditor" : "Sales", "eans"),
    });
};

export const useGetProductsIds = () => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useGetCacheQuery<Array<string>>({
        app: "operations",
        url: url(isVendor ? "Vendor" : isAuditor ? "Auditor" : "Sales", "product-ids"),
    });
};

export const useGetInitProducts = (params: ProductInitSearchParameters) => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useGetPagedQuery<PagedResult<ProductInit>, ProductInitSearchParameters>({
        app: "operations",
        url: url(isVendor ? "Vendor" : isAuditor ? "Auditor" : "Sales"),
        queryParams: params,
    });
};

export const useGetInitProduct = (id: string) => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useGetQuery<ProductInit>({
        app: "operations",
        url: url(isVendor ? "Vendor" : isAuditor ? "Auditor" : "Sales", id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useDeleteInitProduct = (id?: string) => {
    return useDeleteMutation({
        app: "operations",
        url: url("Sales", id) + "/delete",
    });
};

export const useGetInitBrandsFile = (shouldMakeRequest: boolean, params?: ProductInitSearchParameters & { ids: string[]; allSelected: boolean }) => {
    return useFileQuery({
        app: "operations",
        url: url("Sales", "brands-file"),
        fileName: "1. Importuj kategorie",
        shouldMakeRequest,
        queryParams: params,
    });
};

export const useGetInitProductsFile = (
    shouldMakeRequest: boolean,
    params?: ProductInitSearchParameters & { ids: string[]; allSelected: boolean },
    onlyManufacturedProducts?: boolean
) => {
    let fileName = "2.1 Importuj kartoteki towarowe";
    if (onlyManufacturedProducts) {
        fileName = "2.2 Importuj kartoteki towarowe produkowane";
    }

    return useFileQuery({
        app: "operations",
        url: url("Sales", "products-file"),
        fileName: fileName,
        shouldMakeRequest,
        queryParams: params,
    });
};

export const useGetInitMeasureFile = (shouldMakeRequest: boolean, params?: ProductInitSearchParameters & { ids: string[]; allSelected: boolean }) => {
    return useFileQuery({
        app: "operations",
        url: url("Sales", "measure-file"),
        fileName: "3. Importuj wymiary FriscoID",
        shouldMakeRequest,
        queryParams: params,
    });
};

export const useInitProduct = () => {
    return useCreateOrUpdateMutation<InitProduct, InitProduct>({
        app: "operations",
        url: url("Sales"),
    });
};

export const useUpdateInitProduct = (id: string) => {
    const { isInAnyRole } = useUser();
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useUpdateMutation<FillInitProductModel, FillInitProductModel>({
        app: "operations",
        url: url(isVendor ? "Vendor" : "Sales", id),
    });
};

export const useGetInitProductFile = (shouldMakeRequest: boolean, id: string, fileId: string, fileName: string) => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useFileQuery({
        app: "operations",
        url: url(isVendor ? "Vendor" : isAuditor ? "Auditor" : "Sales", id) + `/${fileId}`,
        fileName: fileName,
        shouldMakeRequest,
    });
};

export const useUploadInitProductFile = (id: string) => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useUpdateMutation<UploadFileModel, ProductFile>({
        app: "operations",
        url: url(isVendor ? "Vendor" : isAuditor ? "Auditor" : "Sales", id) + `/upload-file`,
        contentType: "formData",
    });
};

export const useDeleteInitProductFile = (id: string, fileId: string) => {
    const { isInAnyRole } = useUser();
    const isAuditor = isInAnyRole(["Auditor", "MasterData"]);
    const isVendor = isInAnyRole(["ExternalPim"]);

    return useDeleteMutation({
        app: "operations",
        url: url(isVendor ? "Vendor" : isAuditor ? "Auditor" : "Sales", id) + `/file/${fileId}`,
    });
};

export const useSendToAuditInitProduct = (id: string) => {
    return useUpdateMutation<{}, {}>({
        app: "operations",
        url: `${url("Sales", id)}/request-audit`,
    });
};

export const useSendToTradeInitProduct = (id: string) => {
    return useUpdateMutation<{}, {}>({
        app: "operations",
        url: `${url("Vendor", id)}/request-review`,
    });
};

export const useTradeAuditInitProduct = (id: string) => {
    return useUpdateMutation<{ isRejected: boolean; comment: string }, void>({
        app: "operations",
        url: `${url("Sales", id)}/review`,
    });
};

export const useAuditInitProduct = (id: string) => {
    return useUpdateMutation<{ isRejected: boolean; comment: string; vatStakeSell?: number }, void>({
        app: "operations",
        url: url("Auditor", id),
    });
};
