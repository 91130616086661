import { PricePoint, ProductClassification } from "Operations-Products";
import { generatePath } from "react-router-dom";
import { ProductInternalData } from "Operations";
import { useCreateOrUpdateMutation, useGetCacheQuery } from "api/hooks";

const url = (productId?: string) => generatePath(`api/products/:id?`, { id: productId ? productId : null });

export const useGetProductInternalData = (productId: string) => {
    return useGetCacheQuery<ProductInternalData>({
        app: "operations",
        url: url(productId) + "/internal-data",
        silentError: true,
        shouldMakeRequest: Boolean(productId),
        cacheTime: 60,
    });
};

export const useUpdateProductPricePoint = (productId: string) => {
    return useCreateOrUpdateMutation<{ pricePoint: PricePoint }, {}>({
        app: "operations",
        url: url(productId) + "/price-point",
        id: productId,
    });
};

export const useUpdateProductClassification = (productId: string) => {
    return useCreateOrUpdateMutation<{ productClassification: ProductClassification }, {}>({
        app: "operations",
        url: url(productId) + "/product-classification",
        id: productId,
    });
};
