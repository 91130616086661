import React, { useCallback, useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";

interface UnsavedChangeAlertProps {
    isDirty: boolean;
}

const UnsavedChangeAlert: React.FC<UnsavedChangeAlertProps> = ({ isDirty }) => {
    const { t } = useTranslation();
    const [isBlocked, setIsBlocked] = useState(false);
    const message = t(common.areYouSure, {
        what: t(common.exitUnsavedForm),
    });

    const blocker = useBlocker((tx) => {
        if (isDirty && tx.currentLocation.pathname !== tx.nextLocation.pathname) {
            setIsBlocked(true);
            return true;
        }
        return false;
    });

    const handleBeforeUnload = useCallback(
        (e: BeforeUnloadEvent) => {
            if (isDirty) {
                e.preventDefault();
                e.returnValue = message;
                return message;
            }
            return undefined;
        },
        [isDirty]
    );

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [handleBeforeUnload]);

    useEffect(() => {
        if (isBlocked) {
            if (isDirty) {
                const result = confirm(message);

                if (result) {
                    blocker.proceed && blocker.proceed();
                } else {
                    blocker.reset && blocker.reset();
                }
                setIsBlocked(false);
            } else {
                blocker.proceed && blocker.proceed();
            }
        }
    }, [isBlocked, blocker, t]);

    return null;
};

export default UnsavedChangeAlert;
