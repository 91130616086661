import { AsyncValidator, ValidationErrors, Validator } from "fluentvalidation-ts";
import { useCallback } from "react";
import { deepPathObject } from "utils-ts/functions";
import { removeEmptyProps } from "utils-ts/functions/removeEmptyProps";
import { ExtendedValidator } from "utils-ts/validations/extendedValidator";
import _ from "lodash";

export type ValidationFunction<T extends {}> = (data: T) => Promise<{
    values: T;
    errors: {};
}>;

const useValidator = <T extends {}>(
    validator: Validator<T> | ExtendedValidator<T> | ExtendedValidator<T, "AsyncValidator"> | AsyncValidator<T>,
    cleanValues?: "cleanValuesBeforeValidation"
): ValidationFunction<T> => {
    return useCallback(
        async (data: T) => {
            let result: ValidationErrors<T> = {};
            if ("getRequiredFields" in validator) {
                if (validator.validatorType === "AsyncValidator") {
                    result = await validator.validateAsync(cleanValues === "cleanValuesBeforeValidation" ? (removeEmptyProps(data) as T) : data);
                } else {
                    result = validator.validate(cleanValues === "cleanValuesBeforeValidation" ? (removeEmptyProps(data) as T) : data);
                }
            } else if ("validate" in validator) {
                result = validator.validate(cleanValues === "cleanValuesBeforeValidation" ? (removeEmptyProps(data) as T) : data);
            } else {
                result = await validator.validateAsync(data);
            }

            return {
                values: _.isEmpty(deepPathObject(result)) ? data : ({} as T),
                errors: deepPathObject(result),
            };
        },
        [validator]
    );
};

export default useValidator;
