const administration = {
    confirmationPassword: "confirmationPassword",
    update: "update",
    userType: "userType",
    newUser: "newUser",
    basicData: "basicData",
    roles: "roles",
    createUser: "createUser",
    users: "users",
    userAdministration: "userAdministration",
    B2BDepartment: "B2BDepartment",
    SupportDepartment: "SupportDepartment",
    SalesDepartment: "SalesDepartment",
    ECommerceDepartment: "ECommerceDepartment",
    AccountantDepartment: "AccountantDepartment",
    LogisticianDepartment: "LogisticianDepartment",
    MarketingDepartment: "MarketingDepartment",
    TradeMarketingDepartment: "TradeMarketingDepartment",
    HumanResourcesDepartment: "HumanResourcesDepartment",
    ITDepartment: "ITDepartment",
} as const;

export default administration;
