import {
    MerchantsSelect,
    ProductsBrandsAutocomplete,
    ProductsCategoriesAutocomplete,
    ProductsProducersAutocomplete,
    ProductsSuppliersAutocomplete,
    ProductsTagsAutocomplete,
} from "components/Controls/Autocompletes";
import { FormSection } from "redux-form";
import { makeStyles } from "tss-react/mui";
import Validator from "utils/validators/basic";
import { Divider, Grid, Typography } from "@mui/material";
import { get, isEmpty } from "lodash";
import { tValidation, validation } from "utils-ts/validations/translation";
import ProductListField from "./ProductListField";

const useStyles = makeStyles()((theme) => ({
    divider: {
        margin: 0,
    },
    dividerItem: {
        margin: "20px 0px 0px 0px",
    },
    item: {
        padding: theme.spacing(1),
    },
}));

const validators = {
    atLeastOne: (_, form, __, path) => {
        const { products, categories, brands, producers, suppliers, tags, merchants } = get(form, path.substring(0, path.lastIndexOf("."))) || {};
        return new Validator()
            .must(() => {
                return (
                    !isEmpty(products) ||
                    !isEmpty(categories) ||
                    !isEmpty(brands) ||
                    !isEmpty(producers) ||
                    !isEmpty(suppliers) ||
                    !isEmpty(tags) ||
                    !isEmpty(merchants)
                );
            }, tValidation(validation.atLeastOneField))
            .validate();
    },
};

const PromotionProductsFields = ({ required, title, name, readOnly = false, exportProducts, selectedProducts }) => {
    const { classes } = useStyles();

    return (
        <FormSection name={name}>
            <Grid
                item
                className={classes.dividerItem}
            >
                <Typography
                    gutterBottom
                    variant="h5"
                >
                    {title}
                </Typography>
                <Divider
                    variant="middle"
                    className={classes.divider}
                />
            </Grid>
            <Grid
                item
                className={classes.item}
            >
                <ProductListField
                    validate={required ? validators.atLeastOne : null}
                    readOnly={readOnly}
                    exportProducts={exportProducts}
                    selectedProducts={selectedProducts}
                />
            </Grid>
            <Grid
                item
                className={classes.item}
            >
                <ProductsCategoriesAutocomplete
                    validate={required ? validators.atLeastOne : null}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid
                item
                className={classes.item}
            >
                <ProductsBrandsAutocomplete
                    validate={required ? validators.atLeastOne : undefined}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid
                item
                className={classes.item}
            >
                <ProductsSuppliersAutocomplete
                    validate={required ? validators.atLeastOne : undefined}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid
                item
                className={classes.item}
            >
                <ProductsProducersAutocomplete
                    validate={required ? validators.atLeastOne : undefined}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid
                item
                className={classes.item}
            >
                <ProductsTagsAutocomplete
                    validate={required ? validators.atLeastOne : undefined}
                    readOnly={readOnly}
                />
            </Grid>
            <Grid
                item
                className={classes.item}
            >
                <MerchantsSelect
                    validate={required ? validators.atLeastOne : undefined}
                    readOnly={readOnly}
                />
            </Grid>
        </FormSection>
    );
};

export default PromotionProductsFields;
