import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { ProductIdField } from "components-ts/controls";
import { default as Controller } from "./Controller";

const FormProductIdField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    defaultValue,
    emptyValue,
    width,
    readOnly,
    warning,
    manualUnregister
}: FormControlProps<string, TFieldValues, TName>): JSX.Element => {
    return (
        <Controller
            name={name}
            render={({ field: { ref, onChange, disabled: _, ...fieldProps }, fieldState: { error } }) => {
                return (
                    <ProductIdField
                        ref={ref}
                        {...fieldProps}
                        onChange={(value) => onChange(value)}
                        label={label}
                        error={{
                            hasError: Boolean(error),
                            message: error?.message,
                        }}
                        readOnly={readOnly}
                        warning={warning}
                    />
                );
            }}
            defaultValue={defaultValue}
            transform={(val) => (val ? val : emptyValue)}
            width={width}
            manualUnregister={manualUnregister}
        />
    );
};

export default FormProductIdField;
