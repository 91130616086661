import { AccountantDepartment, AuditorAndMasterData, ECommerceDepartment, Role, TradeMarketingDepartment } from "routing-ts/roles";

export const contentWriteRoles: Role[] = [
    "Management",
    "Analyst",
    "Catman",
    "SalesAssistant",
    "SalesManager",
    ...TradeMarketingDepartment.GetRoles(),
    ...ECommerceDepartment.GetRoles(),
];
export const contentProductReadRoles: Role[] = [...contentWriteRoles, "MasterData"];

export const initProductRoles: Role[] = [...contentWriteRoles, ...AccountantDepartment.GetRoles(), ...AuditorAndMasterData];

export const AlertsRoles: Role[] = ["Analyst", "Management", "Catman", "SalesManager", "SalesAssistant", ...TradeMarketingDepartment.GetRoles()];
