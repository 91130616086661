import React, { useEffect, useState } from "react";
import { FieldValues, FormProvider, Path } from "react-hook-form";
import { UseFormReturn } from "react-hook-form";
import { Grid } from "@mui/material";
import { QueryStatus, UseMutationResult } from "@tanstack/react-query";
import { common } from "translations";
import { Translation } from "translations/Translation";
import { removeEmptyProps } from "utils-ts/functions/removeEmptyProps";
import { useMessages, usePush, useTranslation } from "utils-ts/hooks";
import { resetOptions } from "utils-ts/hooks/useForm";
import { GetErrorText } from "utils-ts/validations/apiValidation";
import { tValidation } from "utils-ts/validations/translation";
import { Document, OmitAuditFields, ValidationFailure } from "Shared";
import { Button } from "components-ts/controls";
import UnsavedChangeAlert from "components-ts/dialogs/UnsavedChangeAlert";
import { SpinningPreloader } from "components-ts/preloaders";
import { SuspenseContainer } from "components-ts/suspense";
import { Spacing, View } from "components-ts/view";

type FormProps<T extends FieldValues, TData extends T extends Document ? T | OmitAuditFields<T> : T = T extends Document ? T : T> = {
    children: React.ReactNode;
    submitMutation?:
        | UseMutationResult<T, Response, TData, unknown>
        | UseMutationResult<T, Response, Partial<TData>, unknown>
        | ((isDirty: boolean) => UseMutationResult<T, Response, TData, unknown>)
        | ((isDirty: boolean) => UseMutationResult<T, Response, Partial<TData>, unknown>);
    form: UseFormReturn<T>;
    headerText?: Translation;
    subheaderText?: Translation;
    initQueryStatus: QueryStatus;
    onSuccessSubmit?: (result: T, wasDirty?: boolean) => void;
    onFailedSubmit?: (error: unknown) => void;
    showMessages?: boolean;
    hideSubmit?: boolean;
    useView?: boolean;
    listPath?: string;
    formPath?: string;
    customTitle?: string;
    idPath?: string;
    id?: string;
    cleanValuesBeforeSubmit?: boolean;
    useNavigation?: boolean;
    isLoading?: boolean;
    submitLabel?: ((isDirty: boolean) => string | undefined) | string;
    canSubmitWhenPristine?: boolean;
    resetAfterSubmit?: boolean;
    formatDataBeforeSubmit?: (values: T) => T;
    applyBackendDataOnSuccess?: boolean;
    showUnsavedChangeAlert?: boolean;
};

const Submit: React.FC<{ isSubmitting: boolean; isDirty: boolean; canSubmitWhenPristine: boolean; submitLabel?: string }> = ({
    isSubmitting,
    isDirty,
    canSubmitWhenPristine,
    submitLabel,
}) => {
    return (
        <Button
            fullWidth
            type="submit"
            label={submitLabel ? submitLabel : "save"}
            literalLabel={submitLabel !== undefined}
            color={submitLabel ? "primary" : "success"}
            isLoading={isSubmitting}
            disabled={Boolean(isSubmitting || (!isDirty && !canSubmitWhenPristine))}
            style={{ marginTop: "10px" }}
        />
    );
};

const Form = <T extends FieldValues, TData extends T extends Document ? T | OmitAuditFields<T> : T = T extends Document ? T : T>({
    children,
    submitMutation,
    form,
    headerText,
    subheaderText,
    listPath,
    formPath,
    initQueryStatus,
    hideSubmit = false,
    useView = true,
    showMessages = true,
    customTitle,
    onSuccessSubmit,
    onFailedSubmit,
    idPath = "id",
    id,
    cleanValuesBeforeSubmit = true,
    useNavigation = true,
    isLoading = false,
    submitLabel,
    canSubmitWhenPristine = false,
    resetAfterSubmit = false,
    formatDataBeforeSubmit,
    applyBackendDataOnSuccess,
    showUnsavedChangeAlert = false,
}: FormProps<T, TData>): JSX.Element => {
    const { handleSubmit, formState } = form;
    const { showSuccessMessage, showErrorMessage, showInfoMessage } = useMessages();
    const { replace } = usePush();
    const { t } = useTranslation();
    const [submittedData, setSubmittedData] = useState<T>({} as T);
    const [isNew, setIsNew] = useState<boolean>(!Boolean(id));
    const [isDirty, setIsDirty] = useState<boolean>(formState.isDirty);
    const mutation = typeof submitMutation === "function" ? submitMutation(isDirty) : submitMutation;

    const onSubmit = async (values: T) => {
        const cleanValues = cleanValuesBeforeSubmit
            ? formatDataBeforeSubmit
                ? (removeEmptyProps(formatDataBeforeSubmit(values)) as T)
                : (removeEmptyProps(values) as T)
            : values;
        setSubmittedData(cleanValues as T);

        try {
            await mutation?.mutateAsync(cleanValues as unknown as TData, {
                onSuccess: async (result: T) => {
                    if (showMessages) {
                        showSuccessMessage(t(common.saveSuccess));
                    }

                    if (onSuccessSubmit) {
                        onSuccessSubmit(Object.keys(result).length === 0 ? cleanValues : result, isDirty);
                    } else if (formPath) {
                        setIsDirty(false);
                        if (result[idPath]) {
                            replace(formPath, { id: result[idPath] });
                        } else if (idPath.includes(".")) {
                            const part = result[idPath.split(".")[0]];
                            if (part && part[idPath.split(".")[1]]) {
                                replace(formPath, {
                                    id: part[idPath.split(".")[1]],
                                });
                            }
                        }
                    }

                    if (applyBackendDataOnSuccess) {
                        setSubmittedData(result);
                    }
                },
            });
        } catch (error) {
            console.error(error);
            const errors = error as Array<ValidationFailure>;
            if (errors != null && errors.length > 0) {
                const errorsText = ["Wykryto następujące błędy walidacji:"];
                const formErrors: { [key: string]: { type: string; message: string }[] } = {};
                errors.forEach((error) => {
                    const errorMessage = GetErrorText(error);
                    let property = error.propertyName
                        .split(".")
                        .map((p) => p[0].toLowerCase() + p.slice(1))
                        .join(".");
                    let testPropertyValue = form.getValues(property as Path<T>);
                    if (testPropertyValue === undefined) {
                        if (property.endsWith(".value")) {
                            //SingleValueObject in validationResult is return as {"value": "error"}
                            const testProperty = form.getValues(property.slice(0, property.length - 6) as Path<T>);
                            //also check if omit first and last path value exists (ex. shopConfiguration.postCode return validationResult with path "document.postCode.value")
                            testPropertyValue = form.getValues(property.split(".").slice(1).slice(0, -1).join(".") as Path<T>);
                            //if path with .value doesnt exist and path without .value exists in form that means this is SingleValueObject(ex. Email)
                            if (testProperty !== undefined) {
                                property = property.slice(0, property.length - 6);
                            } else if (testPropertyValue !== undefined) {
                                property = property.split(".").slice(1).slice(0, -1).join(".");
                            }
                        } else {
                            //check if omit first part path value exists (ex. shopConfiguration.url return validationResult with path "document.url")
                            const testPropertyPath = property.split(".").slice(1).join(".");
                            testPropertyValue = form.getValues(testPropertyPath as Path<T>);
                            if (testPropertyValue !== undefined) {
                                property = testPropertyPath;
                            }
                        }
                    }

                    errorsText.push(`- ${tValidation(property.split(".").slice(-1)[0], {}, "common")}: ${errorMessage}`);
                    console.error({ property, error });
                    if (formErrors[property] !== undefined) {
                        formErrors[property].push({ type: error.errorCode, message: errorMessage });
                    } else {
                        formErrors[property] = [{ type: error.errorCode, message: errorMessage }];
                    }
                });

                for (const [key, value] of Object.entries(formErrors)) {
                    form.setError(key as Path<T>, { type: "apiValidation", message: value.map((v) => v.message).join("\n") });
                }

                if (showMessages) {
                    showErrorMessage(errorsText.join("\n"));
                }
            } else if (showMessages) {
                showErrorMessage(t(common.saveFail));
            }

            if (onFailedSubmit) {
                onFailedSubmit(error);
            }
        }
    };

    useEffect(() => {
        if (formState.isSubmitSuccessful && !isNew) {
            form.reset({ ...submittedData }, resetOptions);
        }
    }, [formState.isSubmitSuccessful]);

    useEffect(() => {
        // workaround: https://github.com/react-hook-form/react-hook-form/issues/4740#issuecomment-902545903
        setIsDirty(Object.keys(formState.dirtyFields).length !== 0);
    }, [Object.keys(formState.dirtyFields).length]);

    useEffect(() => {
        if (initQueryStatus === "success" && (formState.submitCount === 0 || isNew || resetAfterSubmit)) {
            setIsNew(false);
        }
    }, [initQueryStatus]);

    if ((Boolean(id) && (initQueryStatus === "pending" || isLoading)) || (!Boolean(id) && isLoading)) {
        return <SpinningPreloader />;
    }

    const formElement = (
        <>
            {showUnsavedChangeAlert && <UnsavedChangeAlert isDirty={isDirty} />}
            <FormProvider {...form}>
                <form
                    onSubmit={handleSubmit(async (values) => {
                        if (showMessages) {
                            showInfoMessage(t(common.Saving));
                        }

                        await onSubmit(values);
                    })}
                    noValidate
                >
                    <Spacing spacing={2}>
                        <Grid
                            container
                            direction="column"
                            justify-content="flex-start"
                            alignItems="stretch"
                            spacing={2}
                        >
                            {children}
                        </Grid>

                        {!hideSubmit && (
                            <Submit
                                isDirty={isDirty}
                                isSubmitting={formState.isSubmitting}
                                submitLabel={typeof submitLabel === "function" ? submitLabel(isDirty) : submitLabel}
                                canSubmitWhenPristine={canSubmitWhenPristine}
                            />
                        )}
                    </Spacing>
                </form>
            </FormProvider>
        </>
    );

    return useView ? (
        <View
            headerText={headerText}
            subheaderText={subheaderText}
            useNavigation={useNavigation}
            listPath={listPath}
            isMainView
            customTitle={customTitle}
        >
            <SuspenseContainer>{formElement}</SuspenseContainer>
        </View>
    ) : (
        formElement
    );
};

export default Form;
