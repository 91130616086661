import { useTranslation } from "react-i18next";
import { Grid, Icon } from "@mui/material";
import { common } from "translations";
import Button from "./Button";

const DecisionButtons: React.FC<{
    submitted: boolean;
    onAccept: () => void;
    onReject: () => void;
    acceptText?: string;
    rejectText?: string;
    canReject?: boolean;
    rejectTooltip?: string;
    canAccept?: boolean;
    acceptTooltip?: string;
}> = ({ submitted, onAccept, onReject, acceptText, rejectText, canReject = true, canAccept = true, rejectTooltip, acceptTooltip }) => {
    return (
        <Grid
            container
            item
            direction="row"
            justifyContent="space-around"
            alignItems="stretch"
        >
            <Grid
                item
                xs={5}
            >
                <Button
                    actionType="decline"
                    color="error"
                    type="button"
                    fullWidth
                    onClick={onReject}
                    isLoading={submitted}
                    disabled={!canReject}
                    label={rejectText || common.reject}
                    startIcon={<Icon>thumb_down</Icon>}
                    {...(rejectTooltip !== undefined ? { tooltipMessage: rejectTooltip, tooltipPosition: "bottom" } : { tooltipMessage: undefined })}
                />
            </Grid>

            <Grid
                item
                xs={5}
            >
                <Button
                    actionType="accept"
                    color="success"
                    type="button"
                    fullWidth
                    onClick={onAccept}
                    disabled={!canAccept}
                    isLoading={submitted}
                    label={acceptText || common.accept}
                    startIcon={<Icon>thumb_up</Icon>}
                    {...(acceptTooltip !== undefined ? { tooltipMessage: acceptTooltip, tooltipPosition: "bottom" } : { tooltipMessage: undefined })}
                />
            </Grid>
        </Grid>
    );
};

export default DecisionButtons;
