import { AccountantDepartment, ECommerceDepartment, LogisticianDepartment, Role, SalesDepartment, TradeMarketingDepartment } from "routing-ts/roles";

const strictRoles: Role[] = ["Management", "Analyst", "Auditor", "MasterData", ...AccountantDepartment.GetRoles(), ...SalesDepartment.GetRoles()];
const baseRoles: Role[] = [...strictRoles, "AXUser", ...TradeMarketingDepartment.GetRoles(), ...LogisticianDepartment.GetRoles()];

export const VendorDataRoles: Role[] = [...baseRoles, ...ECommerceDepartment.GetRoles()];
export const VendorContactRoles: Role[] = [
    ...SalesDepartment.GetRoles(),
    ...AccountantDepartment.GetRoles(),
    ...TradeMarketingDepartment.GetRoles(),
    "Auditor",
    "MasterData",
    "Management",
    ...ECommerceDepartment.GetRoles(),
];
export const VendorContractRoles: Role[] = baseRoles;
export const VendorCertificateRoles: Role[] = ["Management", ...SalesDepartment.GetRoles(), ...LogisticianDepartment.GetRoles()];
export const VendorCorrectionRequestRoles: Role[] = ["Auditor", ...SalesDepartment.GetRoles()];
export const VendorDocumentAndTurnoverRoles: Role[] = strictRoles;
export const VendorSubsidiaryGainRoles: Role[] = baseRoles;
export const VendorRefundationRoles: Role[] = baseRoles;
export const VendorCreditLimitRoles: Role[] = baseRoles;
export const VendorMarketingBudgetRoles: Role[] = [...SalesDepartment.GetRoles(), ...TradeMarketingDepartment.GetRoles()];
