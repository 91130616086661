import { CartPredicate, PromotionTypeForm, PromotionTypeSelect, UserActivationLimit } from "components/Controls";
import { FormField } from "components/Form";
import { FormSection } from "redux-form";
import { FieldArray } from "redux-form";
import { withFormName } from "utils/hoc";
import { useCommonTranslation, useFormValueSelector } from "utils/hooks";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { FormControlLabel, Switch } from "@mui/material";
import { validators } from "../../validators";
import PromotionDivisionUseLimits from "./PromotionDivisionUseLimits";

const PromotionDefinition = ({
    form,
    sectionPrefix,
    name = "definition",
    readOnly = false,
    isVoucherEmbedded = false,
    promotionDivisions,
    showExportPoolProducts,
}) => {
    const { t, common } = useCommonTranslation();
    const section = `${sectionPrefix ? `${sectionPrefix}.${name}` : name}`;
    const definition = useFormValueSelector(form, section);
    const [checked, setChecked] = useState(!!definition.divisionActivationLimits);
    const { effects = [], promotionType } = definition;
    const isMembershipPromotion = promotionType == "Membership" || effects.some((x) => x.effectType === "membershipPoints");

    useEffect(() => {
        setChecked(!!definition.divisionActivationLimits);
    }, [definition.divisionActivationLimits]);

    return (
        <>
            <FormSection name="definition">
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid
                            item
                            sx={{ m: 1 }}
                        >
                            <FormControlLabel
                                label={t(common.differentLimitsPerDivision)}
                                control={
                                    <Switch
                                        checked={checked}
                                        onChange={(_, value) => {
                                            setChecked(value);
                                        }}
                                    />
                                }
                            />
                        </Grid>

                        {!checked && (
                            <Grid
                                item
                                sx={{
                                    paddingLeft: "80px",
                                }}
                            >
                                <FormField
                                    name={"promotionActivationLimit"}
                                    type={"number"}
                                    label={t(common.promotionActivationLimit)}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {checked && (
                        <Grid
                            item
                            xs={6}
                        >
                            <FieldArray
                                name="divisionActivationLimits"
                                component={PromotionDivisionUseLimits}
                                promotionDivisions={promotionDivisions}
                            />
                        </Grid>
                    )}

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name={"cartActivationLimit"}
                            type={"number"}
                            label={t(common.cartActivationLimit)}
                            validate={validators.greaterThan0WhenNotEmpty}
                        />
                        <UserActivationLimit readOnly={readOnly} />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <FormField
                            name="onlyIdentityVerifiedFirstOrder"
                            label={t(common.onlyIdentityVerifiedFirstOrder)}
                            type="boolean"
                        />
                        <FormField
                            name={"includeAlcohol"}
                            type={"boolean"}
                            label={t(common.includeAlcohol)}
                        />

                        <FormField
                            name={"includeTobacco"}
                            type={"boolean"}
                            label={t(common.includeTobacco)}
                        />

                        <FormField
                            name={"isCumulative"}
                            type={"boolean"}
                            label={t(common.isCumulative)}
                        />

                        <FormField
                            name={"requiresConsent"}
                            type={"boolean"}
                            label={t(common.requiresConsent)}
                        />
                    </Grid>

                    <PromotionTypeSelect />
                </Grid>

                <CartPredicate
                    name="requirements"
                    readOnly={readOnly}
                    requiredSegment={isMembershipPromotion}
                    showCartProducts
                    isVoucherEmbedded={isVoucherEmbedded}
                />
            </FormSection>

            <PromotionTypeForm
                effectValidation={validators.effectValidation}
                showExportPoolProducts={showExportPoolProducts}
            />
        </>
    );
};

export default withFormName(PromotionDefinition);
