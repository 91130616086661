import { manager, operations } from "store/actionTypePrefixes";
import { AsyncTypes, asAsyncFormTypes, asSilientError, createAction } from "utils/extensions";

const types = {
    formInit: createAction("FORM_INIT_REFUNDATION", manager),
    getRefundation: new AsyncTypes("GET_REFUNDATION", operations),
    createOrUpdate: new AsyncTypes("CREATE_OR_UPDATE_REFUNDATION", operations, [asAsyncFormTypes]),
    getRefundations: new AsyncTypes("GET_REFUNDATIONS", operations),
    deleteRefundation: new AsyncTypes("DELETE_REFUNDATION", operations, [asAsyncFormTypes]),
    importRefundation: new AsyncTypes("IMPORT_REFUNDATION", operations, [asSilientError]),
    makeDecision: new AsyncTypes("REFUNDATIONN_MAKE_DECISION", operations, [asAsyncFormTypes]),
    findRefundationForApproval: new AsyncTypes("FIND_REFUNDATION_FOR_APPROVAL", operations),
    downloadFile: new AsyncTypes("DOWNLOAD_REFUNDATION_FILE", operations),
    validateRefundation: new AsyncTypes("VALIDATE_REFUNDATION_ASYNC", operations),
    downloadSettlementFile: new AsyncTypes("DOWNLOAD_REFUNDATION_SETTLEMENT", operations),
    confirmZeroCorrection: new AsyncTypes("CONFIRM_ZERO_CORRECTION", operations),
    deleteRefundationSettlementFile: new AsyncTypes("DELETE_REFUNDATION_SETTLEMENT", operations, [asAsyncFormTypes]),
};

export default types;
