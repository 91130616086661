import moment from "moment";
import { isFile } from "./fileUtils";

export function removeEmptyProps(obj: {}): {} {
    const needRemoveEmpty = (v: unknown): boolean => v === Object(v) && !moment.isMoment(v) && !moment.isDate(v) && !isFile(v);

    const isNotEmpty = (v: unknown): boolean => {
        if (v === null || typeof v === "undefined" || v === undefined) {
            return false;
        } else if (typeof v === "string") {
            return v.length > 0;
        } else if (moment.isMoment(v)) {
            return v.isValid();
        } else if (moment.isDate(v)) {
            return v.getFullYear() > 2000;
        } else if (isFile(v)) {
            return v.size > 0;
        } else if (Array.isArray(v)) {
            if (v.length === 0) {
                return false;
            }

            if (v.filter((v) => isNotEmpty(v)).length === 0) {
                return false;
            }
        } else if (v instanceof Object) {
            if (Object.keys(v).length === 0) {
                return false;
            } else if (Object.entries(v).filter(([k, v]) => isNotEmpty(v)).length === 0) {
                return false;
            }
        }

        return true;
    };

    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, v]) => isNotEmpty(v))
            .map(([k, v]) => {
                if (Array.isArray(v)) {
                    if (v.length === 0 || v.filter((v) => isNotEmpty(v)).length === 0) {
                        return [k, undefined];
                    }

                    return [k, v.map((v) => (needRemoveEmpty(v) ? removeEmptyProps(v as {}) : v))];
                }

                return [k, needRemoveEmpty(v) ? removeEmptyProps(v as {}) : v];
            })
            .filter(([_, v]) => isNotEmpty(v))
    );
}
