import { ChargeDescriptionDocument } from "Operations-NotesAndBenefits";
import { api } from "api";
import { useColumns } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const ChargeDescriptionList: React.FC = () => {
    const columns = useColumns<ChargeDescriptionDocument>(() => [
        {
            property: "value",
            label: "description",
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "date",
        },
        {
            property: "updatedAt",
            label: "updatedAt",
            as: "date",
        },
        {
            actionType: "edit",
            link: (item) =>
                Paths.GeneratePath(Paths.Dictionaries.ChargeDescriptionForm, {
                    id: item.id,
                }),
        },
    ]);

    return (
        <View
            headerText={"chargeKinds"}
            isMainView
        >
            <QueryTable
                queryHook={api.operations.notesAndBenefits.useGetChargeDescriptions}
                columns={columns}
                formPath={Paths.Dictionaries.ChargeDescriptionForm}
                hideToggle
            />
        </View>
    );
};

export default ChargeDescriptionList;
