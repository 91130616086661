import Tabs from "components/Tabs/Tabs";
import { findAllAccountingPeriods } from "store/accountingPeriods";
import {
    getPromotionPeriodsFromDate,
    getToolkitNames,
    getVendorRecipients,
    vendorRecipientsSelector,
} from "store/autocomplete";
import { getUserInfoWithRole, userInfoWithRoleSelector } from "store/autocomplete";
import { sgFormSelector } from "store/vendors/subsidiaryGains";
import { getMarketingBudgetForSG, getReservationForSG, getReservationKitForSG, initForm } from "store/vendors/subsidiaryGains/action";
import { useCommonTranslation, useParams, useUserAccountingPeriods } from "utils/hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { usePush } from "utils-ts/hooks";
import VendorLayout from "views/vendors/components/VendorLayout";
import SubsidiaryGainInvoices from "views/vendors/components/subsidiaryGains/SubsidiaryGainInvoices/SubsidiaryGainInvoices";
import SubsidiaryGainsForm from "views/vendors/components/subsidiaryGains/SubsidiaryGainsForm";

export const sgStatuses = {
    created: "Created",
    waitingForVendorDecision: "WaitingForVendorDecision",
    waitingForVendorConfirmation: "WaitingForVendorConfirmation",
    acceptedByVendor: "AcceptedByVendor",
    rejectedByVendor: "RejectedByVendor",
    invoiceRequested: "InvoiceRequested",
    invoiced: "Invoiced",
};

const SubsidiaryGain = () => {
    const { push } = usePush();
    const { vendorId, subsidiaryGainId } = useParams();
    const { t, common } = useCommonTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const reservationId = searchParams.get("reservationId");
    const reservationKitId = searchParams.get("reservationKitId");
    const marketingBudgetId = searchParams.get("marketingBudgetId");
    const { isAnyAccountingPeriodOpen, accountingPeriods } = useUserAccountingPeriods(vendorId);
    const dispatch = useDispatch();
    const { isLoading, removalProposal, dateFrom, dateTo, isDecisionRequired } = useSelector(sgFormSelector);
    const users = useSelector(userInfoWithRoleSelector);
    let vendorUsers = [];

    if (users && users["ExternalSg"]) {
        vendorUsers = users["ExternalSg"];
    }

    useEffect(() => {
        dispatch(getVendorRecipients(vendorId));
        dispatch(findAllAccountingPeriods());
        dispatch(getUserInfoWithRole("ExternalSg"));
    }, [dispatch, vendorId]);

    useEffect(() => {
        let promotionPeriodFrom = moment().add(-3, "month").startOf("quarter");
        if (dateFrom) {
            if (isDecisionRequired) {
                promotionPeriodFrom = moment().startOf("month").isSameOrBefore(moment(dateFrom.current), "days")
                    ? moment().startOf("month").isSameOrBefore(moment(dateFrom.proposal), "days")
                        ? moment().startOf("month")
                        : moment(dateFrom.proposal)
                    : moment(dateFrom.current);
            } else {
                promotionPeriodFrom = promotionPeriodFrom.isSameOrBefore(moment(dateFrom), "days") ? promotionPeriodFrom : moment(dateFrom);
            }
        }

        if (isAnyAccountingPeriodOpen) {
            const open = accountingPeriods.find((a) => Array.isArray(a.overrides) && a.isClosed);
            if (open) {
                const accountingPeriodFrom = moment(`${open.year}-${open.month}-01`, "yyyy-MM-DD");
                if (accountingPeriodFrom.isBefore(promotionPeriodFrom)) {
                    promotionPeriodFrom = accountingPeriodFrom.startOf("quarter");
                }
            }
        }

        dispatch(
            getPromotionPeriodsFromDate({
                dateFrom: promotionPeriodFrom.format("YYYY-MM-DD"),
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isDecisionRequired ? dateFrom.current : dateFrom]);

    useEffect(() => {
        async function fetchData() {
            await dispatch(getToolkitNames());
            await dispatch(initForm(vendorId, subsidiaryGainId));

            if (!!reservationId && !!reservationKitId) {
                await dispatch(getReservationKitForSG(reservationKitId));

                if (!!marketingBudgetId) {
                    await dispatch(getMarketingBudgetForSG(vendorId, marketingBudgetId));
                }

                await dispatch(getReservationForSG(reservationId));
            }
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, subsidiaryGainId, vendorId, reservationId, reservationKitId]);

    const vendorRecipients = useSelector(vendorRecipientsSelector);

    const labels = ["subsidiaryGainForm", "Invoices"];

    return (
        <VendorLayout
            headerText={t(common.subsidiaryGainForm, { id: subsidiaryGainId })}
            vendorId={vendorId}
            isLoading={isLoading}
            tabIndex={3}
            customTitle={`${vendorId} ${t(common.subsidiaryGain)} ${
                dateFrom && dateTo ? `${moment(dateFrom).format("DD.MM.YYYY")}-${moment(dateTo).format("DD.MM.YYYY")}` : ""
            }`}
        >
            <Tabs tableLabels={labels}>
                <SubsidiaryGainsForm
                    vendorUsers={vendorUsers}
                    vendorRecipients={vendorRecipients}
                    handleDecision={() => {
                        if (removalProposal) {
                            push(`/vendors/${vendorId}`, undefined, {
                                tabIndex: 3,
                            });
                        } else {
                            dispatch(initForm(vendorId, subsidiaryGainId));
                        }
                    }}
                    searchParams={searchParams}
                />
                {subsidiaryGainId ? <SubsidiaryGainInvoices /> : "brak"}
            </Tabs>
        </VendorLayout>
    );
};

export default SubsidiaryGain;
