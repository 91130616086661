import { Cx, keyframes } from "tss-react";
import { makeStyles } from "tss-react/mui";
import { useDebouncedCallback } from "use-debounce";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import { Grid, Theme } from "@mui/material";
import { useWindowSize } from "utils-ts/hooks";
import { SpinningPreloader } from "components-ts/preloaders";
import TextField from "../inputs/TextField";
import { VirtualizedParentResizeProvider } from "./VirtualizedParentResizeProvider";

const useStyles = makeStyles()((theme: Theme) => {
    const myAnimation = keyframes({
        "100%": {
            background: "inherit",
            border: "inherit",
            borderRadius: "inherit",
        },
        "0%": {
            background: theme.palette.primary.light,
            border: `1px ${theme.palette.primary.main} solid`,
            borderRadius: theme.shape.borderRadius,
        },
    });

    return {
        evenRow: {
            background: theme.palette.grey[100],
        },
        founded: {
            animation: `${myAnimation} 3000ms ${theme.transitions.easing.easeInOut}`,
        },
    };
});

type VirtualizedRowData = {
    children: ReactNode[];
    setHeight: (index: number, height: number) => void;
    windowWidth: number;
    foundedIndex: number;
    evenRowClass: string;
    foundedRowClass: string;
    cx: Cx;
    getItemHeight: (index: number) => number;
    getItemButtons?: (index: number) => ReactNode;
    getItemDivider?: (index: number) => ReactNode;
};

const VirtualizedRow = ({ index, style, data }: { index: number; style: React.CSSProperties; data: VirtualizedRowData }) => {
    const { children, evenRowClass, setHeight, windowWidth, getItemButtons, getItemDivider, getItemHeight, foundedIndex, foundedRowClass, cx } = data;
    const { height, ...rest } = style;
    const rowRef = useRef<HTMLDivElement | null>(null);
    const isEven = index % 2 === 0;
    const rowHeight = rowRef.current?.getBoundingClientRect().height;
    const currentHeight = getItemHeight(index);
    const resize = () => {
        setTimeout(() => {
            const currentItemHeight = getItemHeight(index);
            if (rowRef.current && currentItemHeight !== rowRef.current.getBoundingClientRect().height) {
                setHeight(index, rowRef.current.getBoundingClientRect().height);
            }
        }, 100);
    };

    React.useEffect(() => {
        if (rowRef.current && currentHeight !== rowRef.current.getBoundingClientRect().height) {
            setHeight(index, rowRef.current.getBoundingClientRect().height);
        }
    }, [index, windowWidth, rowHeight]);

    return (
        <VirtualizedParentResizeProvider resize={resize}>
            <Grid
                ref={rowRef}
                style={{
                    ...rest,
                    height: currentHeight ? undefined : height,
                }}
                container
                item
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                className={cx(foundedIndex == index ? foundedRowClass : undefined, isEven ? evenRowClass : undefined)}
            >
                {children[index]}
                {getItemButtons !== undefined && <div style={{ position: "absolute", right: "10px", top: "10px" }}>{getItemButtons(index)}</div>}
                {getItemDivider !== undefined && getItemDivider(index)}
            </Grid>
        </VirtualizedParentResizeProvider>
    );
};

type VirtualizedListProps = {
    children: ReactNode[];
    getButtonsBeforeList?: () => ReactNode[];
    searchItem?: (search: string) => number;
    searchLabel?: string;
} & (
    | {
          itemHasButtons: boolean;
          getItemButtons: (index: number) => ReactNode;
      }
    | {
          itemHasButtons?: never;
          getItemButtons?: never;
      }
) &
    (
        | {
              hideDivider: boolean;
              getItemDivider: (index: number) => ReactNode;
          }
        | {
              hideDivider?: never;
              getItemDivider?: never;
          }
    );

const defaultItemHeight = 100;

const VirtualizedList: React.FC<VirtualizedListProps> = ({
    children,
    itemHasButtons,
    getItemButtons,
    hideDivider,
    getItemDivider,
    getButtonsBeforeList,
    searchItem,
    searchLabel,
}) => {
    const [windowWidth, windowHeight] = useWindowSize();
    const [searchValue, setSearchValue] = useState<string>("");
    const [foundedIndex, setFoundedIndex] = useState<number>(-1);
    const listRef = useRef<VariableSizeList<VirtualizedRowData> | null>(null);
    const heightMap = useRef<Record<number, number>>({});
    const [heightTotal, setHeightTotal] = useState<number>(defaultItemHeight);
    const search = useDebouncedCallback(
        searchItem !== undefined
            ? (search) => {
                  const index = searchItem(search);
                  if (listRef.current && index !== undefined && index >= 0) {
                      listRef.current.scrollToItem(index, "center");
                      setFoundedIndex(index);
                  } else if (index !== foundedIndex) {
                      setFoundedIndex(index);
                  }
              }
            : (_) => -1,
        500
    );
    const setHeight = useCallback((index: number, height: number) => {
        heightMap.current = { ...heightMap.current, [index]: height };
        setHeightTotal(Object.values({ ...heightMap.current, [index]: height }).reduce((prev, next) => prev + next, defaultItemHeight));
        listRef.current?.resetAfterIndex(index);
    }, []);
    const getItemSize = (index: number) => (heightMap.current && heightMap.current[index] ? heightMap.current[index] : defaultItemHeight);
    const { classes, cx } = useStyles();
    useEffect(() => {
        const heightCount = Object.values(heightMap?.current || []).length;
        if (heightCount > children.length) {
            let heightDecrease = 0;
            for (let i = children.length; i < heightCount; i++) {
                heightDecrease += heightMap.current[i];
                delete heightMap.current[i];
            }

            setHeightTotal(heightTotal - heightDecrease);
        } else if (heightCount < children.length) {
            let heightTemp = Object.values(heightMap.current).reduce((prev, next) => prev + next, defaultItemHeight);
            for (let i = heightCount; i < children.length; i++) {
                heightTemp += getItemSize(i);
            }

            setHeightTotal(heightTemp);
        }
    }, [children.length]);

    return (
        <>
            {getButtonsBeforeList !== undefined && (
                <Grid
                    key="buttons"
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={3}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            {searchItem !== undefined && (
                                <TextField
                                    label={searchLabel ?? ""}
                                    value={searchValue}
                                    onChange={(newValue, event) => {
                                        event?.persist();
                                        setSearchValue(newValue);
                                        search(newValue);
                                    }}
                                    onBlur={() => {
                                        setSearchValue("");
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs={9}
                        container
                        direction="row-reverse"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        {getButtonsBeforeList()}
                    </Grid>
                </Grid>
            )}

            <Grid
                key="list"
                item
                style={{
                    flex: "1 1 auto",
                    height: `min(${searchItem !== undefined || getButtonsBeforeList !== undefined ? 85 : 95}vh, ${heightTotal}px)`,
                    width: "100%",
                }}
            >
                <AutoSizer disableWidth>
                    {({ height }: { height?: number | undefined }) => {
                        if (height === undefined) {
                            return <SpinningPreloader />;
                        }

                        return (
                            <VariableSizeList<VirtualizedRowData>
                                ref={listRef}
                                height={heightTotal < height ? heightTotal : height}
                                itemCount={children.length}
                                estimatedItemSize={height}
                                width="100%"
                                itemSize={getItemSize}
                                itemData={{
                                    children,
                                    setHeight,
                                    windowWidth,
                                    getItemHeight: getItemSize,
                                    getItemButtons: itemHasButtons ? getItemButtons : undefined,
                                    getItemDivider: hideDivider ? undefined : getItemDivider,
                                    evenRowClass: classes.evenRow,
                                    foundedRowClass: classes.founded,
                                    foundedIndex,
                                    cx,
                                }}
                                overscanCount={5}
                            >
                                {VirtualizedRow}
                            </VariableSizeList>
                        );
                    }}
                </AutoSizer>
            </Grid>
        </>
    );
};

export default VirtualizedList;
