import { GetRequest, PagedRequest, PostRequest, PutRequest, get, getPaged, post, put } from "utils/extensions";
import types from "./types";

const findVendorInvoices = (vendorId, pageIndex, pageSize) => async (dispatch) => {
    return dispatch(getPaged(new PagedRequest(pageIndex, pageSize, `api/vendors/invoices/${vendorId}`, types.getVendorInvoices.all)));
};

const getVendorList =
    ({ pageIndex, pageSize, ...payload }) =>
    async (dispatch) => {
        return await dispatch(
            getPaged(
                new PagedRequest(pageIndex, pageSize, `api/vendors`, types.getVendorsList.all, {
                    queryParams: { ...payload },
                })
            )
        );
    };

const getVendorExtList = () => async (dispatch) => {
    return await dispatch(get(new GetRequest("api/external-users/vendors/mine", types.getVendorsExtList.all)));
};

const initVendorForm = (vendorId) => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/vendors/${vendorId}`, types.getVendor.all)));
};

const initVendorFormForExternal = (vendorId) => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/external-users/vendors/${vendorId}`, types.getVendor.all)));
};

const updateVendor =
    (values, id, collectionUpdated = "") =>
    async (dispatch) => {
        return await dispatch(
            put(
                new PutRequest(`api/vendors/${id}/${collectionUpdated}`, types.updateVendor.all, {
                    body: values,
                })
            )
        );
    };

const addIsActiveChangeDecision = (changesAccepted, vendorId) => async (dispatch) => {
    return await dispatch(
        post(new PostRequest(`api/vendors/${vendorId}/${changesAccepted ? "accept" : "reject"}-change-of-active`, types.updateVendor.all))
    );
};

const updateVendorUsers = (vendorId, users) => async (dispatch) => {
    return await dispatch(
        put(
            new PutRequest(`api/vendors/${vendorId}/assign-users`, types.assignVendorUsers.all, {
                body: users,
            })
        )
    );
};

const getCreditLimits = (id) => async (dispatch) => {
    await dispatch(get(new GetRequest(`api/vendors/${id}/credit-limits`, types.getVendorCreditLimits.all)));
};

const hasPendingCreditLimitOperation = (id) => async (dispatch) => {
    await dispatch(get(new GetRequest(`api/vendors/${id}/credit-limits/has-pending-operation`, types.getVendorCreditLimitsHasPendingOperation.all)));
};

export const getContracts = (id) => async (dispatch) => {
    return await dispatch(get(new GetRequest(`api/vendors/${id}/contracts`, types.getVendorContracts.all)));
};

export const hasPendingDraftContract = (vendorId) => async (dispatch) => {
    await dispatch(get(new GetRequest(`api/vendors/${vendorId}/contracts/has-pending-draft`, types.getVendorContractHasPendingDraft.all)));
};

export const actions = {
    getVendorList,
    getVendorExtList,
    initVendorForm,
    initVendorFormForExternal,
    updateVendor,
    addIsActiveChangeDecision,
    updateVendorUsers,
    findVendorInvoices,
    getCreditLimits,
    hasPendingCreditLimitOperation,
};
