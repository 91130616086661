import { makeStyles } from "tss-react/mui";
import { FieldError, FieldPath, FieldValues, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/lab";
import { Theme } from "@mui/material";
import { common } from "translations";
import _ from "lodash";

type Props<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    name: TName;
};

const useStyles = makeStyles()((theme: Theme) => ({
    alert: {
        width: `calc(100% - ${theme.spacing(4)}px)`,
        marginBottom: theme.spacing(2),
    },
}));

const FormObjectValidation = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
}: Props<TFieldValues, TName>): JSX.Element => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const {
        formState: { errors },
    } = useFormContext<TFieldValues>();
    const fieldError = _.get(errors, name) as FieldError;
    const hasError = Boolean(fieldError) && "message" in fieldError;

    return hasError ? (
        <Alert
            className={classes.alert}
            severity="error"
            variant="standard"
        >
            <AlertTitle>{t(common.validationError).toString()}</AlertTitle>

            {fieldError?.message}
        </Alert>
    ) : (
        <></>
    );
};

export default FormObjectValidation;
