import { ChargeDescriptionDocument } from "Operations-NotesAndBenefits";
import { AsyncValidator } from "fluentvalidation-ts";
import { notEmptyString } from "utils-ts/validations";

class ChargeDescriptionDocumentValidator extends AsyncValidator<ChargeDescriptionDocument> {
    constructor() {
        super();

        this.ruleFor("value")
            .must(notEmptyString)
            .maxLength(100);
    }
}

export default ChargeDescriptionDocumentValidator;
