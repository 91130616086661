import { CarDocument } from "Operations-NotesAndBenefits";
import { AsyncValidator } from "fluentvalidation-ts";
import {
    notEmptyString,
    polishLicensePlateCaseInsensitiveValidator,
} from "utils-ts/validations";
import validationRequest from "api/requests/validationRequest";

class CarDocumentValidator extends AsyncValidator<CarDocument> {
    constructor() {
        super();

        this.ruleFor("brand")
            .must(notEmptyString)
            .maxLength(40);

        this.ruleFor("registrationNumber").must(
            polishLicensePlateCaseInsensitiveValidator
        );

        this.ruleFor(
            "registrationNumber"
        ).mustAsync({
            predicate: async (
                value,
                document
            ) => {
                if (!value) {
                    return true;
                }

                const nameTaken =
                    await validationRequest<boolean>(
                        {
                            app: "operations",
                            url: `api/cars/validate-register/${value}${
                                document.id
                                    ? "?carId=" +
                                      document.id
                                    : ""
                            }`,
                        }
                    );

                return !nameTaken;
            },
            message:
                "Samochód z podaną rejestracją jest już w systemie",
        });

        this.ruleFor("owner").must(
            notEmptyString
        );
    }
}

export default CarDocumentValidator;
