import { useFileQuery, useGetQuery } from "api/hooks";
import { VendorDocumentDocument } from "Operations-VendorDocument";

const queryKey = (vendorId: string, documentId?: string) => `api/vendors/${vendorId}/documents${documentId !== undefined ? `/${documentId}` : ""}`;

export const useGetDocumentFile = (params: { vendorId: string; documentId: string; fileName: string }) =>
    useFileQuery({
        app: "operations",
        url: queryKey(params.vendorId, params.documentId) + "/file",
        fileName: params.fileName,
    });

export const useGetVatStatements = (params: { vendorIds: Array<string>; shouldMakeRequest: boolean }) =>
    useGetQuery<Array<VendorDocumentDocument>, { vendorIds: Array<string> }>({
        app: "operations",
        url: queryKey("D000000", "vat-statements"),
        queryParams: { vendorIds: params.vendorIds },
        shouldMakeRequest: (currentParams) => (currentParams?.vendorIds || []).filter((v) => Boolean(v)).length > 0 && params.shouldMakeRequest,
    });
