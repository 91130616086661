import { makeStyles } from "tss-react/mui";
import { Ref, forwardRef } from "react";
import { alpha, Grid, Icon, Theme, ToggleButton as MuiToggleButton , Typography } from "@mui/material";
import { ToggleButtonGroupProps, ToggleButtonProps } from "control-types";
import { useTranslation } from "utils-ts/hooks";
import { Color } from "components-ts/controls/buttons/useButtonStyles";
import FormHelperText from "components-ts/controls/inputs/FormHelperText";
import React from "react";

const useButtonStyles = makeStyles<{ color: Color }>()((theme: Theme, { color }) => ({
    button: {
        "borderColor": theme.palette[color].main,
        "color": theme.palette[color].main,
        "&:hover": {
            background: alpha(theme.palette[color].main, 0.08),
        },
        "&.Mui-selected": {
            "background": theme.palette[color].dark,
            "color": theme.palette[color].contrastText,
            "&:hover": {
                background: theme.palette[color].dark,
                color: theme.palette[color].contrastText,
            },
        },
        "&.Mui-disabled": {
            color: theme.palette[color].contrastText,
        },
        "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
            borderRight: `1px ${theme.palette[color].contrastText} solid`,
        },
        "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
            borderLeft: `1px ${theme.palette[color].contrastText} solid`,
        },
    },
}));

const ToggleButton = <T extends string | number | boolean>(
    { label, item, onChange, color = "primary", error}: ToggleButtonProps<T>
): JSX.Element => {
    const { classes } = useButtonStyles({ color });
    const { t } = useTranslation();
    const [selected, setSelected] = React.useState(false);
    const buttonLabel = typeof item.name !== "string" ? t(item.name) : item.name;

    return (
        <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
        >
            {Boolean(label) && (
                <Grid
                    item
                    xs={4}
                >
                    <Typography style={{ width: "100%", overflowWrap: "break-word" }}>{label}</Typography>
                </Grid>
            )}

            <Grid
                item
                xs={Boolean(label) ? 7 : undefined}
            >

                        <MuiToggleButton
                            className={classes.button}
                            value={item.value === undefined ? "undefined" : item.value}
                            selected={selected}
                            onChange={(_, v) => {
                                setSelected(!selected);
                                onChange({...item, value: !selected});
                            }}
                        >
                            {item.startIcon && item.startIcon}
                            {buttonLabel}
                        </MuiToggleButton>
                <FormHelperText error={error} />
            </Grid>
        </Grid>
    );
};

export default forwardRef(ToggleButton) as <T extends string | number | boolean>(
    props: ToggleButtonProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof ToggleButton>;
