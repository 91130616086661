import { generatePath } from "react-router-dom";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery } from "api/hooks";
import { Campaign, CampaignListRequest, CampaignListResponse, CreateOrUpdateCampaign } from "Commerce-Shared";

const queryKey = "api/v1/shared/campaigns/";

const url = (id?: string) => generatePath(`${queryKey}:id?`, { id: id ? id : null });

export const useFindCampaigns = (initialParams: CampaignListRequest) => {
    return useGetPagedQuery<CampaignListResponse, CampaignListRequest>({
        app: "commerce",
        url: url(),
        queryParams: initialParams,
    });
};

export const useGetCampaign = (id?: string) => {
    return useGetQuery<Campaign>({
        app: "commerce",
        url: url(id),
        shouldMakeRequest: Boolean(id),
        refetchOnMount: true,
    });
};

export const useCreateOrUpdateCampaign = (id?: string) => {
    return useCreateOrUpdateMutation<CreateOrUpdateCampaign, Campaign>({
        app: "commerce",
        url: url(id),
        id: id,
    });
};
