import { Fetcher } from "api-types";
import { useFileQuery, useGetPagedQuery, useGetQuery, useUpdateMutation } from "api/hooks";
import {
    FindProductByEanModel,
    FindProductsModel,
    FoundProductResult,
    Product,
    ProductIntegration,
    UpdateProductAttributes,
    UpdateProductCategories,
    UpdateProductImages,
} from "Content-Product";

const queryKey = "api/v1/products";

export const useGetProductSeoReport = (productIds: string[], createdFrom: Date | undefined, submit: boolean) => {
    return useFileQuery<Fetcher.FileResponse, {}, { productIds: string[]; createdFrom: Date | undefined }>({
        app: "content",
        url: "api/v1/products/seo-report",
        fileName: "Raport SEO",
        shouldMakeRequest: submit,
        method: "POST",
        body: { productIds, createdFrom },
    });
};

export const useFindProducts = (initialParams: FindProductsModel, isEnabled: boolean = true) => {
    return useGetPagedQuery<FoundProductResult, FindProductsModel>({
        app: "content",
        url: queryKey,
        method: "POST",
        body: initialParams,
        shouldMakeRequest: isEnabled,
    });
};

export const useFindProductByEan = (ean?: string) => {
    return useGetQuery<FindProductByEanModel>({
        app: "content",
        url: `${queryKey}/ean/${ean}`,
        shouldMakeRequest: Boolean(ean),
    });
};

export const useGetProduct = (productId: string) => {
    return useGetQuery<Product>({
        app: "content",
        url: `${queryKey}/${productId}`,
        shouldMakeRequest: Boolean(productId),
    });
};

export const useUpdateProductAttributesFrom = (productId: string) => {
    return useUpdateMutation<{ attributesFrom: ProductIntegration }, {}>({
        app: "content",
        url: `${queryKey}/${productId}/update-attributes-from`,
    });
};

export const useUpdateForceIntegrationImages = (productId: string) => {
    return useUpdateMutation<{ isVariant: boolean; forceIntegrationImages: boolean }, {}>({
        app: "content",
        url: `${queryKey}/${productId}/images/integration-images`,
    });
};

export const useUpdateProductAttributes = (productId: string) => {
    return useUpdateMutation<UpdateProductAttributes, UpdateProductAttributes>({
        app: "content",
        url: `${queryKey}/${productId}/update-attributes`,
    });
};

export const useUpdateProductCategories = (productId: string) => {
    return useUpdateMutation<UpdateProductCategories, UpdateProductCategories>({
        app: "content",
        url: `${queryKey}/${productId}/update-categories`,
    });
};

export const useUpdateProductImages = (productId: string) => {
    return useUpdateMutation<UpdateProductImages, UpdateProductImages>({
        app: "content",
        url: `${queryKey}/${productId}/update-images`,
        contentType: "formData",
    });
};
