import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ExtendedValidator } from "utils-ts/validations/extendedValidator";

const FormRequiredFieldContext = createContext<Array<string> | undefined>(undefined);

export const useFormRequiredFieldContext = () => useContext(FormRequiredFieldContext);

export const FormRequiredFieldProvider = <T extends {}>({
    validator,
    defaultValues,
    children,
}: {
    validator: ExtendedValidator<T> | ExtendedValidator<T, "AsyncValidator">;
    defaultValues?: Partial<T>;
    children: React.ReactNode;
}) => {
    const [result, setResult] = useState<string[]>([]);
    useEffect(() => {
        let active = true;
        load();
        return () => {
            active = false;
        };

        async function load() {
            setResult([]); // this is optional
            const res = await validator.getRequiredFields({ ...defaultValues } as T);
            if (!active) {
                return;
            }
            setResult(res);
        }
    }, []);

    return <FormRequiredFieldContext.Provider value={result}>{children}</FormRequiredFieldContext.Provider>;
};
