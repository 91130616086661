const roles = {
    Administrator: "Administrator",
    HelpDesk: "HelpDesk",
    Analyst: "Analyst",
    AXUser: "AXUser",
    BusinessSupport: "BusinessSupport",
    BusinessSupportImporter: "BusinessSupportImporter",
    BusinessSupportManager: "BusinessSupportManager",
    Support: "Support",
    SupportManager: "SupportManager",
    Catman: "Catman",
    SalesAssistant: "SalesAssistant",
    SalesShopping: "SalesShopping",
    SalesManager: "SalesManager",
    Ecommerce: "Ecommerce",
    EcommerceManager: "EcommerceManager",
    Auditor: "Auditor",
    Accountant: "Accountant",
    AccountantManager: "AccountantManager",
    Logistician: "Logistician",
    LogisticianManager: "LogisticianManager",
    Marketing: "Marketing",
    MarketingManager: "MarketingManager",
    TradeMarketing: "TradeMarketing",
    TradeMarketingManager: "TradeMarketingManager",
    Management: "Management",
    Vendor: "Vendor",
    OfficeManager: "OfficeManager",
    ProjectManager: "ProjectManager",
    HumanResources: "HumanResources",
    HumanResourcesManager: "HumanResourcesManager",
    Archiver: "Archiver",
    MasterData: "MasterData",
    DebtCoordinator: "DebtCoordinator",
    AccountBalanceCoordinator: "AccountBalanceCoordinator",
    ExternalUser: "ExternalUser",
    ExternalVendor: "ExternalVendor",
    ExternalSg: "ExternalSg",
    ExternalPim: "ExternalPim",
    ExternalTurnover: "ExternalTurnover",
} as const;

export default roles;
