import { Validator, businessRegistryNumberValidator, emailValidator, notEmpty, notEmptyValidator, requiredVatinValidator } from "utils/validators";
import { tValidation, validation as translation } from "utils-ts/validations/translation";

const validation = {
    required: (val) => notEmptyValidator(val).validate(),
    requiredEmail: (val) => notEmptyValidator(val).validate() && emailValidator(val).validate(),
    email: (val) => (val ? emailValidator(val).validate() : undefined),
    businessRegistryNumber: (val) => notEmptyValidator(val).validate() && businessRegistryNumberValidator(val).validate(),
    vatin: (val) => requiredVatinValidator(val).validate(),
    shortName: (val) =>
        new Validator(val)
            .must(
                (val) => val.length <= 50,
                tValidation(translation.charCount, {
                    min: 0,
                    max: 50,
                })
            )
            .when(notEmpty(val))
            .validate(),
    name: (val) =>
        notEmptyValidator(val)
            .must(
                (val) => val.length <= 250,
                tValidation(translation.charCount, {
                    min: 1,
                    max: 250,
                })
            )
            .when(notEmpty(val))
            .validate(),
};

export default validation;
