import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

const HelpTooltip: React.FC<{ text: string }> = ({ text }) => {
    return (
        <Tooltip
            title={text}
            aria-label="add"
            arrow
        >
            <HelpOutlineIcon
                color="secondary"
                fontSize="small"
            />
        </Tooltip>
    );
};

export default HelpTooltip;
