import { ChargeKindDocument } from "Operations-NotesAndBenefits";
import React from "react";
import { useParams } from "react-router-dom";
import { api } from "api";
import { common } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { useForm, useValidator } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { Form, FormRow, FormSelect, FormSwitch, FormTextField } from "components-ts/forms";
import ChargeKindDocumentValidator from "./validator";

const ChargeKindForm: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { data, status } = api.operations.notesAndBenefits.useGetChargeKind(id);
    const validatorResolver = useValidator(new ChargeKindDocumentValidator());

    const form = useForm<ChargeKindDocument>({
        resolver: validatorResolver,
        values: data,
    });

    return (
        <Form
            form={form}
            headerText={{
                resource: "chargeKind",
                params: { id: data?.name ?? id },
            }}
            formPath={Paths.Dictionaries.ChargeKindForm}
            listPath={Paths.Dictionaries.ChargeKindList}
            initQueryStatus={status}
            submitMutation={api.operations.notesAndBenefits.useCreateOrUpdateChargeKind(id)}
            id={id}
        >
            <FormRow>
                <FormTextField
                    name="name"
                    label={t(common.name)}
                />
                <FormSelect
                    name="chargeDocument"
                    label={t(common.chargeDocument)}
                    items={[
                        { value: "Note", name: "Nota" },
                        { value: "Invoice", name: "Faktura" },
                    ]}
                    disableEmpty
                />
                <FormSelect
                    name="chargeType"
                    label={t(common.chargeType)}
                    items={[
                        { value: "Benefit", name: "Benefit" },
                        { value: "Damage", name: "Szkoda" },
                    ]}
                    disableEmpty
                />
                <FormSwitch
                    name="isActive"
                    label={t(common.isActive)}
                    width={200}
                    defaultValue={true}
                />
            </FormRow>
            <FormRow>
                <FormSwitch
                    name="isAdditionalPersonRequired"
                    label={t(common.isAdditionalPersonRequired)}
                    width={200}
                />
                <FormSwitch
                    name="isBenefitAmountRequired"
                    label={t(common.isBenefitAmountRequired)}
                    width={200}
                />
                <FormSwitch
                    name="isCostInvoiceRequired"
                    label={t(common.isCostInvoiceRequired)}
                    width={200}
                />
                <FormSwitch
                    name="isInvoiceAmountRequired"
                    label={t(common.isInvoiceAmountRequired)}
                    width={200}
                />
                <FormSwitch
                    name="isMerchandiseRequired"
                    label={t(common.isMerchandiseRequired)}
                    width={200}
                />
                <FormSwitch
                    name="isAdditionalDescriptionRequired"
                    label={t(common.isAdditionalDescriptionRequired)}
                    width={200}
                />
            </FormRow>
            <FormRow>
                <FormSwitch
                    name="isOrderNumberRequired"
                    label={t(common.isOrderNumberRequired)}
                    width={200}
                />
                <FormSwitch
                    name="isRegisterNumberRequired"
                    label={t(common.isRegisterNumberRequired)}
                    width={200}
                />
            </FormRow>
        </Form>
    );
};

export default ChargeKindForm;
