import { FindCorrectionRequestsForAuditor } from "Operations-CorrectionRequest";
import { Fetcher } from "api-types";
import { useFileQuery } from "api/hooks";

const queryKey = "api/correction-request";

export const useGetCorrectionRequestsReport = (params: FindCorrectionRequestsForAuditor, submit: boolean) => {
    return useFileQuery<Fetcher.FileResponse, {}, FindCorrectionRequestsForAuditor>({
        app: "operations",
        url: `${queryKey}/report`,
        fileName: "Raport ŻFK",
        shouldMakeRequest: submit,
        method: "GET",
        queryParams: params,
        downloadFile: true,
    });
};
