import { makeStyles } from "tss-react/mui";
import { DialogTitle, Dialog as MuiDialog, Theme } from "@mui/material";
import { DialogProps } from "dialogs";

const useStyles = makeStyles()((theme: Theme) => ({
    dialogTitle: {
        backgroundColor: "#A20652",
        color: theme.palette.getContrastText("#A20652"),
        fontSize: "1.5rem",
    },
    children: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
    root: {
        minWidth: 500,
    },
}));

const Dialog = ({ children, isOpen, onClose, dialogTitle, maxWidth = "md" }: DialogProps): JSX.Element => {
    const { classes } = useStyles();

    return (
        <MuiDialog
            open={isOpen}
            onClose={() => onClose()}
            maxWidth={maxWidth}
        >
            {dialogTitle ? (
                <DialogTitle
                    className={classes.dialogTitle}
                    id="simple-dialog-title"
                >
                    {dialogTitle}
                </DialogTitle>
            ) : undefined}

            <div className={classes.children}>{children}</div>
        </MuiDialog>
    );
};

export default Dialog;
