import { ChargeKindDocument } from "Operations-NotesAndBenefits";
import { api } from "api";
import { useColumns } from "utils-ts/hooks";
import { Paths } from "routing-ts/ManagerPaths";
import { QueryTable } from "components-ts/tables";
import { View } from "components-ts/view";

const ChargeKindList: React.FC = () => {
    const columns = useColumns<ChargeKindDocument>(() => [
        {
            property: "name",
            label: "name",
            filtrable: true,
        },
        {
            property: "chargeDocument",
            label: "chargeDocument",
            filtrable: true,
            filterAs: "select",
            filterItems: [
                { value: "Note", name: "Nota" },
                { value: "Invoice", name: "Faktura" },
            ],
            as: (value: unknown) => {
                return value == "Note" ? "Nota" : "Faktura";
            },
        },
        {
            property: "chargeType",
            label: "chargeType",
            filtrable: true,
            filterAs: "select",
            filterItems: [
                { value: "Benefit", name: "Benefit" },
                { value: "Damage", name: "Szkoda" },
            ],
            as: (value: unknown) => {
                return value == "Benefit" ? "Benefit" : "Szkoda";
            },
        },
        {
            property: "isActive",
            label: "isActive",
            as: "boolean",
        },
        {
            property: "createdAt",
            label: "createdAt",
            as: "date",
        },
        {
            property: "updatedAt",
            label: "updatedAt",
            as: "date",
        },
        {
            actionType: "edit",
            link: (item) =>
                Paths.GeneratePath(Paths.Dictionaries.ChargeKindForm, {
                    id: item.id,
                }),
        },
    ]);

    return (
        <View
            headerText={"chargeKinds"}
            isMainView
        >
            <QueryTable
                queryHook={api.operations.notesAndBenefits.useGetChargeKinds}
                columns={columns}
                formPath={Paths.Dictionaries.ChargeKindForm}
            />
        </View>
    );
};

export default ChargeKindList;
