import React from "react";
import { Procedure } from "views/administration/procedures";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { Role } from "../roles";

const DepartmentStructure = React.lazy(() =>
    import("views-ts/administration").then((module) => ({
        default: module.DepartmentStructure,
    }))
);
const RoleForm = React.lazy(() =>
    import("views-ts/administration").then((module) => ({
        default: module.RoleForm,
    }))
);
const RolesList = React.lazy(() =>
    import("views-ts/administration").then((module) => ({
        default: module.RolesList,
    }))
);
const ProcedureList = React.lazy(() =>
    import("views-ts/administration").then((module) => ({
        default: module.ProceduresList,
    }))
);
const UsersList = React.lazy(() =>
    import("views-ts/administration").then((module) => ({
        default: module.UsersList,
    }))
);
const UserForm = React.lazy(() =>
    import("views-ts/administration").then((module) => ({
        default: module.UserForm,
    }))
);

const roles: Role[] = ["HelpDesk", "HumanResources", "HumanResourcesManager", "Auditor"];
const auditRoles: Role[] = ["Auditor"];
const helpRoles: Role[] = ["HelpDesk"];
const procedureRoles: Role[] = ["HumanResources", "HumanResourcesManager", "Auditor"];

export const Administration: PrivateRoute[] = [
    new PrivateRoute("/administration", roles, undefined, "administration", undefined, [
        new PrivateRoute(Paths.Administration.UsersList, helpRoles, UsersList, "users", "account_box"),
        new PrivateRoute(Paths.Administration.UserForm, helpRoles, UserForm),

        new PrivateRoute(Paths.Administration.ProcedureList, procedureRoles, ProcedureList, "procedures", "library_books"),
        new PrivateRoute(Paths.Administration.ProcedureForm, procedureRoles, Procedure),

        new PrivateRoute(Paths.Administration.DepartmentStructure, auditRoles, DepartmentStructure, "structure", "account_tree"),

        new PrivateRoute(Paths.Administration.RolesList, helpRoles, RolesList, "roles", "privacy_tip"),
        new PrivateRoute(Paths.Administration.RoleForm, helpRoles, RoleForm),
    ]),
];
