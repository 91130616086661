export const isFile = (value: unknown): value is File => {
    return value !== undefined && value !== null && typeof value === "object" && (value as File) !== undefined && "size" in value && "name" in value;
};

export const getFileFromFileReaderResult = (result: string | ArrayBuffer | null | undefined, fileName: string) => {
    let fileContent: string;
    if (result === null) {
        return undefined;
    } else if (typeof result === "string") {
        fileContent = result;
    } else {
        const buf = new ArrayBuffer(64);
        const decoder = new TextDecoder();
        fileContent = decoder.decode(result);
    }

    const arr = fileContent.split(",");
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName);
};

export const getDataUrlFromFileReaderResult = (result: string | ArrayBuffer | null | undefined) => {
    let fileContent: string;
    if (result === null) {
        return undefined;
    } else if (typeof result === "string") {
        fileContent = result;
    } else {
        const buf = new ArrayBuffer(64);
        const decoder = new TextDecoder();
        fileContent = decoder.decode(result);
    }

    return {
        mediaType: fileContent.split(";")[0],
        base64: fileContent.split(",")[1], //to get BASE64, ex: "data:image/png;base64,daksljdasdas"
    };
};

/**
 * Download file
 * @param file dataUrl (data:image/png;base64...) or url (https://) of file
 * @param fileName name of file
 */
export const downloadFile = (file: string, fileName: string) => {
    if (file.startsWith("http")) {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                const clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                });
                link.dispatchEvent(clickEvent);
            })
            .catch(console.error);
    } else {
        const linkElement = document.createElement("a");
        linkElement.setAttribute("href", file);
        linkElement.setAttribute("download", fileName);
        const clickEvent = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: false,
        });
        linkElement.dispatchEvent(clickEvent);
    }
};
