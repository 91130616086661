import { CompanyStructure } from "views-ts/employeeProcedures";
import { ProcedureForm, ProceduresList } from "views-ts/procedures";
import { Paths } from "routing-ts/ManagerPaths";
import { PrivateRoute } from "routing-ts/PrivateRoute";
import { FriscoUsersRoles, Role } from "routing-ts/roles";

const procedureRoles: Role[] = ["FriscoLogged"];
const companyStructureRoles: Role[] = FriscoUsersRoles;

export const EmployeeProcedures: PrivateRoute[] = [
    new PrivateRoute(Paths.Procedures, procedureRoles, ProceduresList, "proceduresList", "library_books"),
    new PrivateRoute(Paths.Procedure, procedureRoles, ProcedureForm),
    new PrivateRoute(Paths.CompanyStructure, companyStructureRoles, CompanyStructure, "companyStructure", "account_tree"),
];
