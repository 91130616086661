import Layout from "components/Grid/Layout";
import SimpleTable from "components/Table/SimpleTable/SimpleTable";
import { withStyles } from "tss-react/mui";
import { stringStyle } from "utils/formating";
import { currency, grammage, numeric, text, time } from "utils/formating/formatTypes";
import timePrecisonFormats from "utils/formating/timePrecisonFormats";
import _ from "lodash";
import { orders } from "translations";
import PropTypes from "prop-types";
import { useTranslation } from "utils-ts/hooks";

const styles = (theme) => ({
    quantityDiff: {
        color: "red",
        fontWeight: "bold",
    },
    tableSpacing: {
        marginTop: theme.spacing(2),
    },
});

const ProductList = (props) => {
    const { lines, containsInvoice } = props;
    const classes = withStyles.getClasses(props);
    const { t } = useTranslation("orders");

    const base = [
        {
            key: "productId",
            value: t(orders.productId),
            type: text,
            options: { style: stringStyle.imporant },
        },
        { key: "ean", value: t(orders.ean), type: text },
        { key: "productName", value: t(orders.productName), type: text },
        { key: "campaigns", value: t(orders.campaigns), type: text },
        { key: "priceBeforeVoucher", value: t(orders.priceBeforeVoucher), type: currency },
        { key: "diffrence", value: t(orders.diffrence), type: currency },
        { key: "grossPrice", value: t(orders.grossPrice), type: currency },
        { key: "quantity", value: t(orders.quantity), type: numeric },
        { key: "grossTotal", value: t(orders.grossTotal), type: currency },
        {
            key: "validityPeriod",
            value: t(orders.validityPeriod),
            type: time,
            options: { timeFormat: timePrecisonFormats.days },
        },
    ];

    const optional = containsInvoice
        ? [
              { key: "grammage", value: t(orders.grammage), type: grammage },
              { key: "grossPriceInvoice", value: t(orders.grossPriceInvoice), type: currency },
              {
                  key: "totalInvoice",
                  value: t(orders.totalInvoice),
                  type: currency,
                  options: { style: stringStyle.imporant },
              },
              { key: "quantityInvoice", value: t(orders.quantityInvoice), type: numeric },
          ]
        : [];

    const stockColumns = [
        {
            key: "actualStock",
            value: t(orders.actualStock),
            type: numeric,
        },
        { key: "reservedStock", value: t(orders.reservedStock), type: numeric },
        { key: "stock", value: t(orders.stock), type: numeric },
        { key: "externalStock", value: t(orders.externalStock), type: numeric },
    ];

    const columns = [...base, ...optional, ...stockColumns];

    const getLineStyle = (line) => {
        if (!line.quantity) {
            return "";
        }
        const haveQuantityDiff = line.quantityInvoice !== line.quantity || !line.quantityInvoice;
        return containsInvoice && haveQuantityDiff ? classes.quantityDiff : "";
    };

    const evalGramage = (line) => {
        if (!line.ean) return "";

        return String(line.ean).length < 11 ? line.grammage : "";
    };

    const handleExport = (items) => {
        const el = document.createElement("textarea");
        //prettier-ignore
        el.value = items.map((item) => `${item.ean}\t${item.productName}\t${(item.grossPriceInvoice || item.grossPrice)}\t${item.quantity}\t`).join('\n');
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    };

    return _(lines)
        .map((x) => ({ ...x, className: getLineStyle(x), grammage: evalGramage(x) }))
        .groupBy((x) => x.merchant)
        .map((group, groupKey) => {
            return (
                <Layout
                    key={groupKey}
                    headerText={t(orders.productList, { merchant: groupKey })}
                    className={classes.tableSpacing}
                >
                    <SimpleTable
                        key={groupKey}
                        columns={columns}
                        stripped
                        selectable
                        data={group}
                        title={groupKey}
                        onSelectedExport={handleExport}
                    />
                </Layout>
            );
        })
        .value();
};

ProductList.propTypes = {
    lines: PropTypes.array,
    containsInvoice: PropTypes.bool,
};

export default withStyles(ProductList, styles);
