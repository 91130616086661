import { Button } from "components/Button";
import { ChangeReason } from "components/Controls/ChangeReason";
import { DocumentArchiveFileArray } from "components/Files";
import { FormField, FormFieldArray } from "components/Form";
import { integerNormalize } from "components/FormHelpers/ControlFormaters";
import { useUser } from "context/UserContext/UserContext";
import { FieldArray, Form, formValueSelector, reduxForm } from "redux-form";
import defaultShouldAsyncValidate from "redux-form/es/defaultShouldAsyncValidate";
import contractStatuses from "resource/contractStatuses";
import { documentArchiveCompanies, friscoCompanyId } from "resource/documentArchiveCompanies";
import {
    documentTypesSelector,
    getActiveBrandNames,
    getAnalyticGroups,
    getContactTypes,
    getDocumentCategories,
    getDocumentTypes,
} from "store/autocomplete";
import { actions } from "store/cache/products/actions";
import { displayMessage } from "store/messaging/actions";
import { contractSelector } from "store/vendors/contracts";
import {
    addFileToDraftContract,
    changeFileInDraftContract,
    createOrUpdate,
    getContractFile,
    removeFileFromDraftContract,
} from "store/vendors/contracts/action";
import { withPush } from "utils/hoc";
import withMatchParams from "utils/hoc/withMatchParams";
import { useChange, useCommonTranslation, useParams } from "utils/hooks";
import { notEmpty } from "utils/validators";
import { Fragment, createRef, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Alert, AlertTitle } from "@mui/lab";
import { Grid } from "@mui/material";
import moment from "moment";
import { Layout } from "components";
import _ from "lodash";
import { vendor } from "translations";
import { usePush, useTranslation } from "utils-ts/hooks";
import { IsVendorReadOnly } from "views/vendors/IsVendorReadOnly";
import RejectMessage from "../RejectMessage";
import { validators } from "../validators";
import {
    ContractDecision,
    FrontDiscounts,
    MarketingBudget,
    ProductDiscount,
    RetroConditionalDiscount,
    RetroUnconditionalDiscount,
} from "./components";

const paymentTermForProductFrom = [
    { value: "InvoiceDate", name: "Wystawienia faktury" },
    { value: "InvoiceDeliveryDate", name: "Dostarczenia faktury" },
    { value: "DeliveryDate", name: "Dostawy" },
];

const validateCreateOrUpdateActionError = ({ error, payload }, dispatch, vendorId, draft, push) => {
    if (!error && !draft) {
        push(`/vendors/${vendorId}`, undefined, { tabIndex: 2 });
    } else if (payload && payload.response && payload.status === 400) {
        const errorMessage = payload.response.errors[0].errorMessage;
        if (errorMessage.includes("Contract in decision already exists")) {
            dispatch(
                displayMessage({
                    message: "Dla danego kontrahenta istnieją już warunki handlowe wymagające decyzji.",
                    variant: "error",
                })
            );
        } else if (!draft) {
            dispatch(
                displayMessage({
                    message: "Wystąpił niespodziewany błąd.",
                    variant: "error",
                })
            );
        }
    }
};

const asyncValidate = _.debounce(async (values, dispatch, { push, typeOfChange, vendorId, contractId }) => {
    if (someRequierdDatesNotFilled(values) || validateDates(values)) {
        return Promise.resolve(undefined);
    }

    const response = await dispatch(createOrUpdate(values, vendorId, contractId, typeOfChange, true));
    validateCreateOrUpdateActionError(response, dispatch, vendorId, true, push);
}, 2000);

const someRequierdDatesNotFilled = (values, shouldCheckFiles = true) => {
    const {
        dateFrom,
        paymentTermsDateFrom,
        newFiles = [],
        frontDiscounts = [],
        retroUnconditionalDiscounts = [],
        productDiscounts = [],
        marketingBudgets = [],
    } = values;

    const newFilesDatesNotFilled = newFiles.some((o) => !notEmpty(o.documentDate));
    const frontDiscountsDatesNotFilled = frontDiscounts.some((o) => !notEmpty(o.dateFrom));
    const retroUnconditionalDiscountsDateNotFilled = retroUnconditionalDiscounts.some((o) => !notEmpty(o.dateFrom));
    const productDiscountsDatesNotFilled = productDiscounts.some((o) => !notEmpty(o.dateFrom));
    const marketingBudgetsDatesNotFilled = marketingBudgets.some((o) => !notEmpty(o.dateFrom));

    if (
        !notEmpty(dateFrom) ||
        !notEmpty(paymentTermsDateFrom) ||
        (shouldCheckFiles ? newFilesDatesNotFilled : false) ||
        frontDiscountsDatesNotFilled ||
        retroUnconditionalDiscountsDateNotFilled ||
        productDiscountsDatesNotFilled ||
        marketingBudgetsDatesNotFilled
    ) {
        return true;
    }

    return false;
};

const validateDates = (values) => {
    if (typeof values !== "object" || values === null) {
        return false;
    }

    return Object.entries(values).some(([key, value]) => {
        return Array.isArray(value)
            ? value.some((x) => validateDates(x))
            : key.toLowerCase().includes("date") &&
                  value &&
                  (!(moment(value).isAfter("2000-01-01") && moment(value).isValid()) || !moment(value).isValid());
    });
};

const ContractForm = ({ handleSubmit, formValues, contractId, allProducts, handleDecision, isNew, previousDocumentId }) => {
    const { vendorId, typeOfChange } = useParams();
    const { profile } = useUser();
    const change = useChange(formName);
    const { push } = usePush();
    const { t } = useTranslation("vendor");
    const { t: ct, common } = useCommonTranslation();
    const dispatch = useDispatch();
    const permittedDocumentTypes = useSelector(documentTypesSelector);
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const handleDownloadFile = async (fileId, fileName) => {
        dispatch(getContractFile(vendorId, contractId, fileId, fileName));
    };

    const handleAddFile = async (file) => {
        if (_.isEmpty(contractId)) {
            var response = await dispatch(createOrUpdate(formValues, vendorId, contractId, typeOfChange, true));
            contractId = response.payload;
        }
        var { payload } = await dispatch(addFileToDraftContract({ ...file, documentDate: moment() }, vendorId, contractId));
        file.fileId = payload;
        change(`newFiles[${formValues.newFiles.length}].fileId`, payload);
    };

    const handleChangeFile = async (file) => {
        await dispatch(changeFileInDraftContract(file, vendorId, contractId));
    };

    const handleBarcodePositionChange = async (fileId, barcodePosition) => {
        var file = formValues.newFiles.find((f) => f.fileId === fileId);
        file.barcodePosition = barcodePosition;
        var response = await dispatch(createOrUpdate(formValues, vendorId, contractId, typeOfChange, true));

        if (_.isEmpty(contractId)) {
            contractId = response.payload;
        }
    };

    const handleRemoveFile = async (file) => {
        await dispatch(removeFileFromDraftContract(vendorId, contractId, file.fileId));
        formValues.newFiles = formValues.newFiles.filter((f) => f.fileId === file.fileId);
    };

    const retroTypes = {
        retroConditionalDiscounts: {
            idName: "retroConditionalDiscountId",
            name: "retroConditionalDiscounts",
        },
        retroUnconditionalDiscounts: {
            idName: "retroUnconditionalDiscountId",
            name: "retroUnconditionalDiscounts",
        },
    };
    const retroOptions = ["added", "changed", "noDiff", "removed"];
    const getFocusedRetroField = (focusedRetroId) => {
        if (!Boolean(focusedRetroId)) {
            return undefined;
        }

        let retroOption = undefined;
        for (let value of Object.values(retroTypes)) {
            if (!!formValues[value.name]) {
                let index = undefined;
                if (Array.isArray(formValues[value.name])) {
                    index = formValues[value.name]?.findIndex((el) => el[value.idName] === focusedRetroId);
                    if (index !== -1) {
                        return `${value.name}[${index}]`;
                    }
                } else {
                    retroOption = retroOptions.find((option) => {
                        index = formValues[value.name][option]?.findIndex(
                            (el) => el[value.idName].current === focusedRetroId || el[value.idName].proposal === focusedRetroId
                        );
                        if (index !== -1) {
                            return true;
                        }
                        return false;
                    });
                    if (!!retroOption) {
                        return `${value.name}.${retroOption}[${index}]`;
                    }
                }
            }
        }

        return undefined;
    };

    const {
        dateTo,
        contractStatus = "",
        decision = { changesAccepted: true },
    } = useSelector((state) => formValueSelector(formName)(state, "dateTo", "contractStatus", "decision"));

    const isAnnex = typeOfChange === "annex" || formValues.typeOfChange === "Annex";
    const isChange = typeOfChange === "change" || formValues.typeOfChange === "Change";
    const isMails = typeOfChange === "mail" || formValues.typeOfChange === "Mail";
    const isDecisionRequired = contractStatuses.isDecisionRequired(contractStatus);
    const useDiff = isDecisionRequired || (contractStatuses.isStatusToShowDiff(contractStatus) && !_.isEmpty(previousDocumentId));

    const isReadOnly = IsVendorReadOnly() || (!isNew && !typeOfChange) || contractStatuses.isReadOnly(contractStatus);

    const unpermittedDocumentTypes = !!permittedDocumentTypes
        ? ["UMOWA", "POROZUMIENIE MAILOWE"].filter((x) => !permittedDocumentTypes.map((t) => t.name).includes(x))
        : ["UMOWA", "POROZUMIENIE MAILOWE"];

    const focusedRetroId = params.get("focusedRetroId");
    const focusedRetroField = getFocusedRetroField(focusedRetroId);
    const focusRef = createRef();

    useEffect(() => {
        dispatch(getAnalyticGroups({ withInactive: true }));
        dispatch(getActiveBrandNames());
        dispatch(getContactTypes());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getDocumentCategories());
        dispatch(getDocumentTypes());
    }, [dispatch, vendorId, contractId]);

    useEffect(() => {
        if (allProducts?.length) {
            dispatch(actions.getProducts(allProducts));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allProducts.length, dispatch]);

    useEffect(() => {
        if (focusedRetroId && focusRef.current) {
            focusRef.current.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedRetroId, focusRef]);

    return (
        <>
            <Form
                onSubmit={handleSubmit(async (values) => {
                    asyncValidate.cancel();
                    formValues.lastChangedBy = profile.email;
                    const response = await dispatch(createOrUpdate(formValues, vendorId, contractId, typeOfChange, false));
                    validateCreateOrUpdateActionError(response, dispatch, vendorId, false, push);
                })}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    {isNew && (
                        <Grid>
                            <Alert severity="warning">
                                {
                                    "Aby powstała i aktualizowała się na bieżąco wersja robocza WH muszą zostać uzupełnione wszystkie wymagane pola daty"
                                }
                            </Alert>
                        </Grid>
                    )}
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                    >
                        <RejectMessage
                            isRejected={decision && !decision.changesAccepted}
                            message={t(vendor.ContractRejectedMessage, {
                                comment: decision.comment,
                                decisionBy: decision.decisionBy,
                            })}
                        />
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                    >
                        {isDecisionRequired ? (
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                {t(vendor.waitingForConfirmgChanges)}
                            </Grid>
                        ) : (
                            <Fragment />
                        )}

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormField
                                name={"dateFrom"}
                                label={t(vendor.dateOfStartContract)}
                                type="date"
                                readOnly={isDecisionRequired || isReadOnly}
                                validate={validators.validateFrom}
                                useDiff={false}
                            />

                            {!isAnnex && !isMails && !isChange && dateTo && (
                                <FormField
                                    name={"dateTo"}
                                    label={t(vendor.dateOfEndContract)}
                                    type="date"
                                    readOnly={true}
                                    useDiff={false}
                                />
                            )}

                            {contractId && (
                                <FormField
                                    name={"lastChangedBy"}
                                    type="text"
                                    label={t(vendor.responsibleUser)}
                                    readonly={true}
                                    useDiff={false}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormField
                                useDiff={useDiff}
                                name={"consentToCompensation"}
                                label={t(vendor.consentToCompensation)}
                                type="boolean"
                                readOnly={isDecisionRequired || isReadOnly}
                            />

                            <FormField
                                useDiff={useDiff}
                                name={"consentToAutomaticCompensation"}
                                label={t(vendor.consentToAutomaticCompensation)}
                                type="boolean"
                                readOnly={isDecisionRequired || isReadOnly}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormField
                                useDiff={useDiff}
                                name={"paymentTermsForProducts"}
                                label={t(vendor.paymentTermsForProducts)}
                                type="number"
                                format={integerNormalize}
                                readOnly={isDecisionRequired || isReadOnly}
                                validate={validators.requiredGreaterThan0}
                            />

                            <FormField
                                useDiff={useDiff}
                                name={"paymentTermForProductFrom"}
                                label={t(vendor.paymentTermForProductFrom)}
                                type="select"
                                items={paymentTermForProductFrom}
                                readOnly={isDecisionRequired || isReadOnly}
                                validate={validators.required}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <FormField
                                useDiff={useDiff}
                                name={"paymentTermsForMarketingInvoice"}
                                label={t(vendor.paymentTermsForMarketingInvoice)}
                                type="number"
                                format={integerNormalize}
                                readOnly={isDecisionRequired || isReadOnly}
                                validate={validators.requiredGreaterThan0}
                            />

                            <FormField
                                useDiff={useDiff}
                                name={"paymentTermsDateFrom"}
                                label={t(vendor.paymentTermsDateFrom)}
                                type="date"
                                readOnly={isDecisionRequired || isReadOnly}
                                validate={validators.required}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                    >
                        <Grid
                            item
                            xs={6}
                            md={3}
                        >
                            <FormField
                                useDiff={useDiff}
                                name="companyId"
                                label={t(vendor.company)}
                                type={"select"}
                                items={documentArchiveCompanies}
                                validate={validators.required}
                                hidden={true}
                                readOnly={true}
                            />
                        </Grid>
                    </Grid>

                    {(contractId || previousDocumentId) && (
                        <FormFieldArray
                            header={t(vendor.contractFiles)}
                            name="contractFiles"
                            useDiff={useDiff}
                            hideDefaultHeader={useDiff}
                            component={DocumentArchiveFileArray}
                            readOnly={isDecisionRequired || isReadOnly}
                            handleDownloadFile={handleDownloadFile}
                            hiddenFields={["companyId", "vendor", "documentFileInput"]}
                        />
                    )}
                    {!isDecisionRequired && (isNew || isAnnex || isMails) && !isChange && !isReadOnly && (
                        <FieldArray
                            name="newFiles"
                            component={DocumentArchiveFileArray}
                            canUpdate
                            canUpdateWithFileId
                            useDropzone
                            readOnly={isDecisionRequired || isReadOnly || someRequierdDatesNotFilled(formValues, false)}
                            documentTypeFilter={(types) => types.filter((t) => t.name === "UMOWA" || t.name === "POROZUMIENIE MAILOWE")}
                            validate={
                                !isDecisionRequired && (isNew || isAnnex || isMails) && !isChange && !isReadOnly
                                    ? validators.whFilesArrayRequired
                                    : undefined
                            }
                            header={t(vendor.newContractFiles)}
                            hiddenFields={["companyId", "vendor"]}
                            onDropFile={handleAddFile}
                            onChangeFile={handleChangeFile}
                            onRemoveFile={handleRemoveFile}
                            handleDownloadFile={handleDownloadFile}
                            onBarcodePositionChanged={handleBarcodePositionChange}
                            itemTemplate={{
                                companyId: friscoCompanyId,
                                company: documentArchiveCompanies.find((c) => c.companyId === friscoCompanyId),
                                vendorId: vendorId,
                                vendor: { vendorId: vendorId },
                            }}
                        >
                            {unpermittedDocumentTypes.length > 0 && (
                                <Alert severity="warning">
                                    <AlertTitle>{t(vendor.attention)}</AlertTitle>
                                    {`${t(vendor.noAccessToDocumentTypes)} ${unpermittedDocumentTypes.reduce((prev, cur) => `${prev}, ${cur}`)}`}
                                </Alert>
                            )}
                        </FieldArray>
                    )}
                    <Layout
                        isEnlarged
                        headerText={t(vendor.frontDiscounts)}
                    >
                        <FormFieldArray
                            useDiff={useDiff}
                            name="frontDiscounts"
                            component={FrontDiscounts}
                            readOnly={isDecisionRequired || isReadOnly}
                        />
                    </Layout>
                    <Layout
                        isEnlarged
                        headerText={t(vendor.retroUnconditionalDiscounts)}
                    >
                        <FormFieldArray
                            useDiff={useDiff}
                            name="retroUnconditionalDiscounts"
                            component={RetroUnconditionalDiscount}
                            readOnly={isDecisionRequired || isReadOnly}
                            focusRetroField={focusedRetroField}
                            focusRef={focusRef}
                        />
                    </Layout>
                    <Layout
                        isEnlarged
                        headerText={t(vendor.retroConditionalDiscounts)}
                    >
                        <FormFieldArray
                            useDiff={useDiff}
                            name="retroConditionalDiscounts"
                            component={RetroConditionalDiscount}
                            readOnly={isDecisionRequired || isReadOnly}
                        />
                    </Layout>
                    <Layout
                        isEnlarged
                        headerText={t(vendor.productDiscounts)}
                    >
                        <FormFieldArray
                            useDiff={useDiff}
                            name="productDiscounts"
                            component={ProductDiscount}
                            readOnly={isDecisionRequired || isReadOnly}
                        />
                    </Layout>
                    <Layout
                        isEnlarged
                        headerText={t(vendor.marketingBudget)}
                    >
                        <FormFieldArray
                            useDiff={useDiff}
                            name="marketingBudgets"
                            component={MarketingBudget}
                            readOnly={isDecisionRequired || isReadOnly}
                        />
                    </Layout>
                </Grid>

                {(isChange || isDecisionRequired) && previousDocumentId && !contractStatuses.isStatusToShowDiff(contractStatus) && (
                    <Grid>
                        <ChangeReason
                            forceRender={isChange}
                            isDecisionRequired={isDecisionRequired}
                        />
                    </Grid>
                )}
                {!isDecisionRequired && (
                    <>
                        <Button
                            actionType="accept"
                            type="submit"
                            fullWidth
                            disabled={isReadOnly}
                        >
                            {ct(common.sendToReview)}
                        </Button>
                    </>
                )}
            </Form>
            <ContractDecision
                isDecisionRequired={isDecisionRequired}
                handleDecision={handleDecision}
            />
        </>
    );
};

const formName = "contracts-form";

const stateToProps = (state, ownProps) => {
    const { isSubbmitRequested, allProducts, id, ...form } = contractSelector(state);
    const contractId = ownProps?.match?.params?.contractId && !ownProps?.match?.params?.typeOfChange ? ownProps?.match?.params?.contractId : id;
    const isNew = !ownProps?.match?.params?.contractId || (contractStatuses.isDraft(form.contractStatus) && !ownProps?.match?.params?.typeOfChange);

    const previousDocumentId = form.proposal?.previousDocumentId ?? form.current?.previousDocumentId;
    const showDiff =
        contractStatuses.isDecisionRequired(form.contractStatus) ||
        (contractStatuses.isStatusToShowDiff(form.contractStatus) && !_.isEmpty(previousDocumentId));

    const initialValues = {
        contractStatus: form.contractStatus,
        isChange: form.isChange,
        lastChangedBy: form.proposal?.lastChangedBy ?? form.current?.lastChangedBy,
        decision: form.proposal?.decision ?? form.proposal.decision,
        previousDocumentId: previousDocumentId,
        ...(showDiff ? form : form.current),
        ...(contractStatuses.isDraft(form.contractStatus) || (form.proposal && contractStatuses.isRejected(form.contractStatus))
            ? form.proposal
            : {}),
        ...(showDiff
            ? {}
            : {
                  consentToCompensation: contractStatuses.isDraft(form.contractStatus)
                      ? _.isBoolean(form?.current?.consentToCompensation)
                          ? form.current?.consentToCompensation
                          : true
                      : _.isBoolean(form?.proposal?.consentToCompensation)
                      ? form?.proposal?.consentToCompensation
                      : true,
                  consentToAutomaticCompensation: contractStatuses.isDraft(form.contractStatus)
                      ? _.isBoolean(form?.current?.consentToAutomaticCompensation)
                          ? form.current?.consentToAutomaticCompensation
                          : true
                      : _.isBoolean(form?.proposal?.consentToAutomaticCompensation)
                      ? form?.proposal?.consentToAutomaticCompensation
                      : true,
                  newFiles: (form.proposal?.contractFiles || form.current?.contractFiles || [])
                      .filter((f) => f.isNew)
                      .map((f) => {
                          const { fileContent, extension, fileName, ...rest } = f;
                          return {
                              archiveFile: {
                                  fileContent,
                                  extension,
                                  fileName,
                              },
                              documentFileInput: {
                                  name: fileName,
                                  extension: extension,
                              },
                              companyId: friscoCompanyId,
                              vendorId: form.vendorId,
                              vendor: {
                                  name: form.vendorName,
                                  vendorId: form.vendorId,
                              },
                              ...rest,
                          };
                      }),
                  contractFiles: (form.proposal?.contractFiles || form.current?.contractFiles || []).filter((f) => !f.isNew),
              }),
        companyId: friscoCompanyId,
    };

    for (const key in initialValues) {
        if (initialValues[key]?.toString()?.includes("01T00:00:00+") && initialValues[key]?.toString().startsWith("0001")) {
            initialValues[key] = undefined;
        } else if (typeof initialValues[key] === "object" && initialValues[key] !== null) {
            for (const key2 in initialValues[key]) {
                if (initialValues[key][key2]?.toString()?.includes("01T00:00:00+") && initialValues[key][key2]?.toString().startsWith("0001")) {
                    initialValues[key][key2] = undefined;
                }
            }
        }
    }

    return {
        isNew,
        isSubbmitRequested,
        allProducts,
        contractId,
        vendorId: ownProps?.match?.params?.vendorId,
        typeOfChange: ownProps?.match?.params?.typeOfChange,
        initialValues,
        previousDocumentId,
        formValues: state.form[formName]?.values || {},
    };
};

export default withPush(
    withMatchParams(
        connect(stateToProps)(
            reduxForm({
                form: formName,
                enableReinitialize: true,
                validate: validators.validateForm,
                asyncValidate: (values, dispatch, props) => {
                    asyncValidate(values, dispatch, props);
                    return Promise.resolve(undefined);
                },
                shouldAsyncValidate: (params) => {
                    return defaultShouldAsyncValidate({
                        ...params,
                        syncValidationPasses: true,
                    });
                },
            })(ContractForm)
        )
    )
);
