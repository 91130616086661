import { promotionTypes } from "components/Controls/promotionTypes";
import lodash from "lodash";

export const isSubmitDisabled = ({ invalid, pristine, submitting }) => {
    return invalid || pristine || submitting;
};

export const mapRequirements = (requirements, isCartPredicate = false, sendCartProducts = true) => {
    if (!requirements) return null;

    const { user = {}, purchasedProducts, cartProducts, ...rest } = requirements;
    const { haveSegmentsConstraint: _, orderRequirements: __, havePersonalTargetContraint: ___, ...restUser } = user;

    const mappedObject = {
        ...rest,
        user: lodash.isEmpty(restUser) ? undefined : restUser,
        payment: rest.payment ? rest.payment.map((p) => ({ paymentMethod: p })) : undefined,
        packaging: rest.packaging ? rest.packaging.map((p) => ({ packagingMethod: p })) : undefined,
        purchasedProducts: isCartPredicate ? purchasedProducts : undefined,
        cartProducts: isCartPredicate && sendCartProducts ? cartProducts : undefined,
    };

    const isAllPropertiesUndefined = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
                return false;
            }
        }
        return true;
    };

    return isAllPropertiesUndefined(mappedObject) ? null : mappedObject;
};

export const mapVoucherDefinition = (definition) => {
    const { pools = [], voucherPromotions = [] } = definition;
    const mappedDefinition = {
        ...definition,
        pools,

        voucherPromotions: voucherPromotions.map((p) => {
            const { definition, ...rest } = p;
            const { promotionType } = definition;
            let pools = definition.pools;
            if (promotionType === promotionTypes.Progressive) {
                const { effects } = definition;
                const [effect] = effects;
                const { cartModifier } = effect;
                const { progressiveModifiers } = cartModifier;
                const progressiveQuantities = progressiveModifiers.map((x) => x.progressiveQuantity);
                pools = pools.map((p) => ({
                    ...p,
                    progressiveQuantities,
                }));
            }
            return {
                ...rest,
                definition: {
                    ...definition,
                    ...(definition.validForDays ? { validForDays: Number(definition.validForDays) } : {}),
                    pools,
                },
            };
        }),
        requirements: mapRequirements(definition.requirements, true),
    };

    return mappedDefinition;
};
