import { FieldPath, FieldValues } from "react-hook-form";
import { FormControlProps } from "./types";
import { TextFieldProps } from "control-types";
import { TextField } from "components-ts/controls";
import { default as Controller } from "./Controller";

type FormTextFieldProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
    FormControlProps<string, TFieldValues, TName>,
    "defaultValue" | "emptyValue"
> & {
    adornment?: {
        position: "start" | "end";
        value: React.ReactNode;
    };
    multiline?: boolean;
    wrapper?: (props: TextFieldProps) => React.ReactElement<TextFieldProps>;
    showPreview?: boolean;
    type?: "text" | "password";
};

const FormTextField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
    name,
    label,
    width,
    readOnly,
    adornment,
    multiline = false,
    wrapper,
    useGridItem,
    showPreview,
    type,
    warning,
    required,
    manualUnregister,
}: FormTextFieldProps<TFieldValues, TName>): JSX.Element => {
    return (
        <Controller<string, TFieldValues, TName>
            name={name}
            render={({ field: { ref, onChange, disabled: _, required: formRequired, ...fieldProps }, fieldState: { error } }) => {
                if (wrapper) {
                    return wrapper({
                        ...fieldProps,
                        onChange: (newValue) => {
                            onChange(newValue);
                        },
                        label,
                        error: {
                            hasError: Boolean(error),
                            message: error?.message,
                        },
                        adornment,
                        multiline,
                        readOnly,
                        required: formRequired || required,
                    });
                } else {
                    return (
                        <TextField
                            ref={ref}
                            {...fieldProps}
                            onChange={(newValue) => onChange(newValue)}
                            label={label}
                            error={{
                                hasError: Boolean(error),
                                message: error?.message,
                            }}
                            adornment={adornment}
                            multiline={multiline}
                            readOnly={readOnly}
                            showPreview={showPreview}
                            type={type}
                            warning={warning}
                            required={formRequired || required}
                        />
                    );
                }
            }}
            defaultValue={""}
            transform={(val) => (val ? val : "")}
            width={width}
            useGridItem={useGridItem}
            manualUnregister={manualUnregister}
        />
    );
};

export default FormTextField;
