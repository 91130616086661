import { generatePath } from "react-router-dom";
import { User, UserListRequest, UserListResponse } from "Identity";
import { useCreateOrUpdateMutation, useGetPagedQuery, useGetQuery, useUpdateMutation } from "api/hooks";

const url = (id?: string) => generatePath(`api/users/:id?`, { id: id ? id : null });

export const useGetUsers = (initialParams: UserListRequest) => {
    return useGetPagedQuery<UserListResponse, UserListRequest>({
        app: "identity",
        url: url(),
        queryParams: {
            pageSize: initialParams.pageSize,
            pageIndex: initialParams.pageIndex,
            isDeactivated: false,
        },
    });
};

export const useGetUser = (id?: string) => {
    return useGetQuery<User>({
        app: "identity",
        url: url(id),
        shouldMakeRequest: Boolean(id),
    });
};

export const useUpdateUserEmail = (id: string) => {
    return useUpdateMutation<{ email: string }, {}>({
        app: "identity",
        url: url(id) + "/email",
    });
};

export const useUpdateUserPassword = (id: string) => {
    return useUpdateMutation<{ password: string }, {}>({
        app: "identity",
        url: url(id) + "/password",
    });
};

export const useUpdateUserFullName = (id: string) => {
    return useUpdateMutation<
        {
            fullName: {
                firstName: string;
                lastName: string;
            };
        },
        {}
    >({
        app: "identity",
        url: url(id) + "/full-name",
    });
};

export const useUpdateUserRoles = (id: string) => {
    return useUpdateMutation<{ removeRoles: string[]; addRoles: string[] }, {}>({
        app: "identity",
        url: url(id) + "/roles",
    });
};

export const useUpdateUserDeactivationStatus = (id: string) => {
    return useUpdateMutation<{ isDeactivated: boolean }, {}>({
        app: "identity",
        url: url(id) + "/is-deactivated",
    });
};

export const useCreateUser = () => {
    return useCreateOrUpdateMutation<User, User>({
        app: "identity",
        url: url(),
    });
};
