import { makeStyles } from "tss-react/mui";
import { Dialog, DialogActions, DialogContent, DialogTitle, Theme } from "@mui/material";
import { AcceptRejectDialogProps } from "dialogs";
import { common } from "translations";
import { isTranslationObject } from "translations/Translation";
import { useTranslation } from "utils-ts/hooks";
import { Button } from "components-ts/controls";

const useStyles = makeStyles()((theme: Theme) => ({
    dialogTitle: {
        backgroundColor: "#A20652",
        color: theme.palette.getContrastText("#A20652"),
        fontSize: "1.5rem",
    },
    content: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        minWidth: "500px",
    },
    accept: {
        "backgroundColor": theme.palette.success.main,
        "color": theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.success.dark,
            color: theme.palette.success.contrastText,
        },
    },
    reject: {
        "backgroundColor": theme.palette.error.main,
        "color": theme.palette.success.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.success.contrastText,
        },
    },
}));

const AcceptRejectDialog = ({
    dialogTitle,
    isOpen,
    onAccept,
    onReject,
    children,
    acceptText,
    rejectText,
    isSubmit = true,
    disableAccept = false,
    hideAccept = false,
}: AcceptRejectDialogProps) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            onClose={onReject}
        >
            <DialogTitle className={classes.dialogTitle}>
                {dialogTitle === undefined ? t(common.acceptRejectDialogTitle) : isTranslationObject(dialogTitle) ? t(dialogTitle) : dialogTitle}
            </DialogTitle>

            <DialogContent className={classes.content}>{children}</DialogContent>

            <DialogActions>
                {!hideAccept && (
                    <Button
                        disabled={disableAccept}
                        color="primary"
                        type={isSubmit ? "submit" : undefined}
                        onClick={onAccept}
                        className={classes.accept}
                        label={acceptText ? acceptText : { resource: common.yes }}
                    />
                )}

                <Button
                    onClick={onReject}
                    color="error"
                    label={rejectText ? rejectText : { resource: common.no }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AcceptRejectDialog;
