import { CustomerContactDocument } from "Commerce-Support";
import { useParams } from "utils/hooks";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { IconButton, Tooltip } from "@mui/material";
import { api } from "api";
import { common } from "translations";
import { useForm, useTranslation, useValidator } from "utils-ts/hooks";
import { resetOptions } from "utils-ts/hooks/useForm";
import { Form, FormList, FormRow, FormSelect, FormTextField, FormTreePicker } from "components-ts/forms";
import { View } from "components-ts/view";
import { CustomerContactTypes } from "./CustomerContactTypes";
import { WorkCodesTree } from "./WorkCodesTree";
import CustomerContactValidator from "./validator";

const localStorageName = "CustomerContactForm";

const FormSaver: React.FC<{ closing: boolean }> = ({ closing }) => {
    const { getValues } = useFormContext();
    const values = useWatch({
        defaultValue: {}, // default value before the render
    });
    const newLocalData = {
        ...values,
        ...getValues(),
    };

    if (!closing && Object.values(newLocalData).filter(Boolean).length > 0) {
        localStorage.setItem(localStorageName, JSON.stringify(newLocalData));
    }

    return <></>;
};

const CustomerContactForm: React.FC<{ minimizeFunction: () => void; height: number }> = ({ minimizeFunction, height }) => {
    const { t } = useTranslation(["common"]);
    const calculatedHeight: number = height - 50;

    const { customerId: customerIdFromUrl } = useParams();
    const [customerId, setCustomerId] = useState<string | undefined>("");
    const [closing, setClosing] = useState<boolean>(false);

    const { data: userData, status } = api.commerce.users.useGetUserInternal(customerId);
    const defaultValue = {
        subjects: [],
        contactType: "",
        comment: "",
        email: "",
        phoneNumber: "",
        userId: "",
    };

    const validatorResolver = useValidator(new CustomerContactValidator());
    const form = useForm<CustomerContactDocument>({
        resolver: validatorResolver,
    });

    useEffect(() => {
        const data = localStorage.getItem(localStorageName) ? JSON.parse(localStorage.getItem(localStorageName) ?? "") : defaultValue;
        form.reset({ ...data }, resetOptions);
    }, []);

    useEffect(() => {
        if (customerIdFromUrl != undefined) {
            setCustomerId(customerIdFromUrl);
        } else {
            setCustomerId(form.getValues("userId"));
        }
    }, [customerIdFromUrl]);

    useEffect(() => {
        if (status === "success" && customerId) {
            form.setValue("userId", customerId);
            form.setValue("email", userData?.email || "");
            form.setValue("phoneNumber", userData?.phoneNumber || "");
        }
    }, [status, customerId]);

    const resetForm = () => {
        localStorage.removeItem(localStorageName);
        if (customerIdFromUrl) {
            form.reset(
                { ...defaultValue, email: form.getValues("email"), phoneNumber: form.getValues("phoneNumber"), userId: customerIdFromUrl },
                resetOptions
            );
        } else {
            form.reset(defaultValue, resetOptions);
        }
    };

    const customHeaderButton = React.useMemo(() => {
        return (
            <>
                {!!customerId && (
                    <Tooltip
                        title={t(common.linkOff)}
                        placement="right-start"
                        arrow
                    >
                        <IconButton
                            onClick={() => {
                                setCustomerId(undefined);
                                form.setValue("userId", "");
                                form.setValue("email", "");
                                form.setValue("phoneNumber", "");
                            }}
                        >
                            <LinkOffIcon sx={{ color: "white" }} />
                        </IconButton>
                    </Tooltip>
                )}
                <div style={{ right: 30, position: "fixed" }}>
                    <Tooltip
                        title={t(common.clearForm)}
                        placement="right-start"
                        arrow
                    >
                        <IconButton onClick={resetForm}>
                            <DeleteForeverIcon sx={{ color: "white" }} />
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        onClick={() => {
                            setClosing(true);
                            minimizeFunction();
                        }}
                    >
                        <MinimizeIcon sx={{ color: "white" }} />
                    </IconButton>
                </div>
            </>
        );
    }, [customerId]);

    return (
        <View
            headerText={{ resource: "common.customerContact", params: { id: customerId } }}
            useNavigation={false}
            style={{
                marginBottom: 10,
                height: "100%",
            }}
            customHeader={customHeaderButton}
        >
            <div style={{ maxHeight: calculatedHeight, minHeight: calculatedHeight, overflowY: "auto" }}>
                <Form
                    form={form}
                    hideSubmit={false}
                    submitMutation={api.commerce.contact.useCreateCustomerContact()}
                    useView={false}
                    onSuccessSubmit={resetForm}
                    initQueryStatus="pending" //workaround to not setting submittedData after submit, because when id is empty and status pending, isNew=true, so not reseting form with submittedData
                >
                    <FormSaver closing={closing} />

                    <FormRow>
                        <FormSelect
                            name="contactType"
                            label={t(common.customerContactType)}
                            defaultValue=""
                            items={CustomerContactTypes.map((option) => {
                                return {
                                    value: option,
                                    name: option,
                                };
                            })}
                            disableEmpty={true}
                            width={"auto"}
                        />
                        <FormTextField
                            name="email"
                            label={t(`common:${common.email}`)}
                            readOnly={!!customerId}
                        />
                        <FormTextField
                            name="phoneNumber"
                            label={t(`common:${common.phoneNumber}`)}
                            readOnly={!!customerId}
                            width={"auto"}
                        />
                    </FormRow>
                    <FormList
                        name="subjects"
                        defaultValue={{}}
                        hideDivider
                    >
                        {() => {
                            return (
                                <FormTreePicker
                                    name="workCodes"
                                    label={t(`common:${common.subject}`)}
                                    defaultValue={[]}
                                    items={WorkCodesTree}
                                    disableEmpty
                                    width="fullWidth"
                                />
                            );
                        }}
                    </FormList>

                    <FormTextField
                        name="comment"
                        label={t(`common:${common.comment}`)}
                        multiline={true}
                        width="fullWidth"
                    />
                </Form>
            </div>
        </View>
    );
};

export default CustomerContactForm;
