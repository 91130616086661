import { Button, IconButton } from "components/Button";
import SingleFileField from "components/Files/SingleFileField";
import { FormField } from "components/Form";
import TextWithLabel from "components/Text/TextWithLabel";
import { reduxForm } from "redux-form";
import {
    getTurnoverVendorSummaryFile as getTurnoverVendorSummaryFileForExternal,
    updateVendorSummaryTotal as updateVendorSummaryTotalForExternal,
} from "store/vendorUsers";
import { getTurnoverVendorSummaryFile, updateVendorSummaryTotal } from "store/vendors/turnovers";
import { formatTypes } from "utils/formating";
import { useChange, useFormValueSelector, useParams } from "utils/hooks";
import { notEmptyValidator } from "utils/validators";
import { connect, useDispatch } from "react-redux";
import { Delete as DeleteIcon, GetApp as DownloadIcon } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { common, vendor } from "translations";
import { useTranslation } from "utils-ts/hooks";
import { Layout } from "components";
import _ from "lodash";
import { compose } from "redux";

const notEmpty = (value) => notEmptyValidator(value).validate();

const Summary = ({ summary, handleSubmit, form, pristine, canEdit, isExternalUser = false, isPeriodOpen }) => {
    const { t } = useTranslation(["vendor", "common"]);
    const dispatch = useDispatch();
    const change = useChange(form);
    const { vendorId, id } = useParams();
    const file = useFormValueSelector(form, "vendorSummaryTotalFile", {});
    const onFileChange = (file) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            change("vendorSummaryTotalFile", {
                fileContent: e.target.result.split(",")[1],
                fileName: file.name,
                name: file.name,
            });
        };

        !!file && reader.readAsDataURL(file);
    };

    const handleDownloadFile = async () => {
        if (isExternalUser) {
            dispatch(getTurnoverVendorSummaryFileForExternal(vendorId, id, file.fileId, file.fileName));
        } else {
            dispatch(getTurnoverVendorSummaryFile(vendorId, id, file.fileId, file.fileName));
        }
    };

    return (
        <Layout headerText={t(vendor.turnoverSummary)}>
            <form
                onSubmit={handleSubmit((values) => {
                    if (isExternalUser) {
                        dispatch(
                            updateVendorSummaryTotalForExternal(vendorId, id, {
                                ...values,
                                vendorSummaryTotal: values.vendorSummaryTotal.split(" ").join(""),
                            })
                        );
                    } else {
                        dispatch(
                            updateVendorSummaryTotal(vendorId, id, {
                                ...values,
                                vendorSummaryTotal: values.vendorSummaryTotal.split(" ").join(""),
                            })
                        );
                    }
                })}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <TextWithLabel
                        translatedLabel={t(vendor.transactionSummaryTotal)}
                        value={summary?.transactionSummaryTotal}
                        format={Number(summary?.transactionSummaryTotal) ? formatTypes.currency : formatTypes.text}
                    />
                    <TextWithLabel
                        translatedLabel={t(vendor.invoiceSummaryTotal)}
                        value={summary?.invoiceSummaryTotal}
                        format={Number(summary?.invoiceSummaryTotal) ? formatTypes.currency : formatTypes.text}
                    />
                    <FormField
                        name="vendorSummaryTotal"
                        label={t(vendor.vendorSummaryTotal)}
                        type="text"
                        adornment={{
                            position: "end",
                            value: " zł",
                        }}
                        validate={notEmpty}
                        readOnly={!canEdit(summary?.vendorSummaryTotal) || !isPeriodOpen}
                        mask
                        thousandSeparator=" "
                        decimalSeparator="."
                        allowedDecimalSeparators={[","]}
                        decimalScale={2}
                        fixedDecimalScale={summary?.vendorSummaryTotal !== "Brak danych"}
                    />
                    <FormField
                        name="vendorSummaryTotalFile"
                        type="file"
                        width="auto"
                        accept="application/pdf"
                        validate={notEmpty}
                        disabled={!canEdit(summary?.vendorSummaryTotal) || !isPeriodOpen}
                        component={SingleFileField}
                        handleOnDrop={onFileChange}
                        file={file}
                    />
                    <IconButton
                        size="small"
                        edge="end"
                        onClick={handleDownloadFile}
                        disabled={!file.fileId}
                        icon={<DownloadIcon />}
                    />
                    <IconButton
                        size="small"
                        edge="end"
                        onClick={() => {
                            change("vendorSummaryTotalFile", null);
                        }}
                        disabled={_.isEmpty(file) || !canEdit(summary?.vendorSummaryTotal)}
                        icon={<DeleteIcon />}
                    />
                    <Grid item>
                        <Button
                            type="submit"
                            actionType="accept"
                            variant="contained"
                            fullWidth={false}
                            disabled={pristine}
                        >
                            {t(`common:${common.save}`)}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Layout>
    );
};

export default compose(
    connect((_, props) => {
        return {
            form: "summary-form",
            summary: props.summary,
            initialValues: {
                ...props.summary,
            },
        };
    }),
    reduxForm({
        form: "summary-form",
        enableReinitialize: true,
    })
)((props) => {
    return <Summary {...props} />;
});
