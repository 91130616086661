import segments from "resource/segments.json";
import { useWatch } from "react-hook-form";
import { orderBy } from "lodash";
import { common } from "translations";
import { Item } from "control-types";
import { useTranslation } from "utils-ts/hooks";
import { FormPrefixProvider, usePrefixContext } from "components-ts/forms/contexts";
import { FormRow } from "components-ts/forms/layout";
import FormAutocomplete from "../FormAutocomplete";

const SegmentPredicate: React.FC<{
    name: string;
    readOnly?: boolean;
    manualUnregister?: boolean;
}> = ({ name, readOnly, manualUnregister }) => {
    const { t } = useTranslation();
    const prefix = usePrefixContext();
    const fieldName = prefix ? `${prefix}.${name}` : name;
    const [included = [], excluded = []] = useWatch({
        name: [`${fieldName}.included`, `${fieldName}.excluded`],
        exact: false,
    });
    const notSelectedSegments = orderBy(
        segments.filter((s) => (included || []).concat(excluded || []).every((o: string) => o !== s.value)),
        (s) => s.name
    ).map((s) => ({ value: s.value, name: s.name }) as Item);
    const includedItems = (included || [])
        .map((i: string) => {
            const name = segments.find((s) => s.value === i);
            if (name) {
                return { value: i, name: name.name } as Item;
            } else {
                return { value: i, name: i } as Item;
            }
        })
        .concat(notSelectedSegments);
    const excludedItems = (excluded || [])
        .map((e: string) => {
            const name = segments.find((s) => s.value === e);
            if (name) {
                return { value: e, name: name.name } as Item;
            } else {
                return { value: e, name: e } as Item;
            }
        })
        .concat(notSelectedSegments);

    return (
        <FormPrefixProvider name={name}>
            <FormRow>
                <FormAutocomplete
                    name="included"
                    label={t(common.includedSegments)}
                    readOnly={readOnly}
                    items={includedItems}
                    disableCloseOnSelect
                    freeSolo
                    multiple
                    manualUnregister={manualUnregister}
                />

                <FormAutocomplete
                    name="excluded"
                    label={t(common.excludedSegments)}
                    readOnly={readOnly}
                    items={excludedItems}
                    disableCloseOnSelect
                    freeSolo
                    multiple
                    manualUnregister={manualUnregister}
                />
            </FormRow>
        </FormPrefixProvider>
    );
};

export default SegmentPredicate;
