import { Button } from "components/Button";
import { promotionTypes } from "components/Controls/promotionTypes";
import Layout from "components/Grid/Layout";
import { makeStyles } from "tss-react/mui";
import { useCommonTranslation } from "utils/hooks";
import { Fragment } from "react";
import { Grid, Paper } from "@mui/material";
import _ from "lodash";
import ArrayValidationError from "./ArrayValidationError";
import PromotionPoolPredicateFields from "./PromotionPoolPredicateFields";

const useStyles = makeStyles()((theme) => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
    },
    button: {
        marginLeft: "auto",
    },
}));

export const getPromotionPoolCount = (promotionType) => {
    let maxCount = 100;
    let minCount = undefined;
    switch (promotionType) {
        case promotionTypes.Gratis:
        case promotionTypes.Sampling:
            maxCount = 1;
            break;
        case promotionTypes.Pack:
        case promotionTypes.PackGratis:
            minCount = 2;
            break;
        case promotionTypes.Pool:
        case promotionTypes.PoolGratis:
            minCount = 2;
            maxCount = 2;
            break;
        case promotionTypes.Pair:
        case promotionTypes.Progressive:
        case promotionTypes.Membership:
            minCount = 1;
            maxCount = 1;
            break;
        case promotionTypes.ChosenProduct:
            minCount = 0;
            maxCount = 0;
            break;
        case promotionTypes.Discount:
        case promotionTypes.PackGratis:
            minCount = 1;
            break;
        default:
            break;
    }

    return { maxCount, minCount };
};

const ProductPools = ({ effects, requirements, formName, promotionType, fields, pools, meta, showExportPoolProducts }) => {
    const { classes } = useStyles();
    const { t, common } = useCommonTranslation();
    if (promotionType === promotionTypes.Delivery || promotionType === promotionTypes.GratisPicker) {
        return <Fragment />;
    }

    const { maxCount, minCount } = getPromotionPoolCount(promotionType);

    const renderAdd = (!pools && (minCount || maxCount)) || (pools && pools.length !== undefined && pools.length < maxCount);

    const renderRemove =
        (pools && pools.length > minCount) || !minCount || !_.isEmpty(requirements?.cartProducts) || !_.isEmpty(requirements?.purchasedProducts);

    return (
        <Layout
            isEnlarged
            headerText={t(common.pool)}
        >
            <ArrayValidationError {...meta} />
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
            >
                {fields.map((field, index) => {
                    return (
                        <Grid
                            item
                            key={field}
                        >
                            <Paper
                                elevation={2}
                                className={classes.paper}
                            >
                                <PromotionPoolPredicateFields
                                    prefix={field}
                                    renderRequiremtnts={
                                        !(
                                            promotionType === promotionTypes.Progressive ||
                                            (!promotionType && effects?.some((e) => e.effectType === "progressive"))
                                        )
                                    }
                                    formName={formName}
                                    renderLayout={false}
                                    renderRemove={renderRemove}
                                    onRemove={() => fields.remove(index)}
                                    promotionType={promotionType}
                                    showExportPoolProducts={showExportPoolProducts}
                                />
                            </Paper>
                        </Grid>
                    );
                })}
                <Grid
                    item
                    className={classes.button}
                >
                    {renderAdd ? (
                        <Button
                            actionType="primary"
                            onClick={() => fields.push()}
                        >
                            {t(common.add)}
                        </Button>
                    ) : (
                        <Fragment />
                    )}
                </Grid>
            </Grid>
        </Layout>
    );
};

export default ProductPools;
