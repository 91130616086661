import { FormField } from "components/Form";
import { renderFieldOrText } from "components/FormHelpers/Controls";
import { brandNamesSelector } from "store/autocomplete";
import { orderBy } from "utils/extensions/arrayExtensions";
import { useCommonTranslation } from "utils/hooks";
import { useSelector } from "react-redux";
import { deburr } from "lodash";

const ContentBrandsAutocomplete = ({ validate, name = "brands", ...props }) => {
    const { t, common } = useCommonTranslation();
    const productsBrands = useSelector(brandNamesSelector);
    return (
        <FormField
            stretch
            component={renderFieldOrText}
            name={name}
            label={t(common.brands)}
            type={"autocomplete"}
            items={orderBy(productsBrands).filter((value, index, array) => array.indexOf(value) === index)}
            multiple
            disableCloseOnSelect
            validate={validate}
            filterOptions={(options, params) => {
                var { inputValue, getOptionLabel } = params;
                const input = deburr(inputValue?.toLowerCase());
                return options.filter((o) => deburr(getOptionLabel(o).toLowerCase()).includes(input));
            }}
            {...props}
        />
    );
};

export default ContentBrandsAutocomplete;
